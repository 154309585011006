<template>
  <div class="loader-wrapper">
    <div class="loader">
      <div class="loader-1"></div>
      <div class="loader-2"></div>
      <div class="loader-3"></div>
      <div class="loader-4"></div>
      <div class="loader-5"></div>
      <div class="loader-6"></div>
      <div class="loader-7"></div>
      <div class="loader-8"></div>
      <div class="loader-9"></div>
      <div class="loader-10"></div>
    </div>
    <div class="loader-text">Loading system</div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingAnimation",
});
</script>

<style scoped>
.loader-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.loader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: bottom;
  width: 152px;
  height: 32px;
}
.loader > div {
  position: relative;
  bottom: 0;
  display: block;
  width: 8px;
  height: 8px;
  margin-top: auto;
  margin-bottom: 0px;
  border-radius: 4px;
  background-color: var(--color-pop-03);
  animation: loader 1.5s infinite ease-in-out;
}
.loader .loader-1 {
  animation-delay: -1.5s;
}
.loader .loader-2 {
  animation-delay: -1.4s;
}
.loader .loader-3 {
  animation-delay: -1.3s;
}
.loader .loader-4 {
  animation-delay: -1.2s;
}
.loader .loader-5 {
  animation-delay: -1.1s;
}
.loader .loader-6 {
  animation-delay: -1s;
}
.loader .loader-7 {
  animation-delay: -0.9s;
}
.loader .loader-8 {
  animation-delay: -0.8s;
}
.loader .loader-9 {
  animation-delay: -0.7s;
}
.loader .loader-10 {
  animation-delay: -0.6s;
}
@keyframes loader {
  50% {
    height: 100%;
    background: #0cebff;
  }
}
.loader-text {
  margin-top: 24px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
  color: var(--color-pop-03);
  text-transform: uppercase;
}
</style>
