import customFetch from "@/helpers/customFetch";
import { ref } from "vue";
import { environment } from "@/../environments/environment";
import signalRSocketHandler from "@/helpers/signalRSocketHandler";
import useToaster, { ToastType } from "scanreach-frontend-components/src/compositions/useToaster";

const apiAddress = environment.apiAddress;
const isMuteInProgress = ref(false);
const isInitalLoaded = ref(false);

export default function () {
  const { pushToast } = useToaster();
  if (!isInitalLoaded.value) {
    isInitalLoaded.value = true;
    subscribeToAlarmNodeDisableSignalREvents();
  }

  async function muteAlarm(): Promise<void> {
    isMuteInProgress.value = true;
    try {
      const resp = await customFetch(`${apiAddress}/AlarmSilence`, {
        method: "PUT",
      });

      if (!resp.ok) {
        console.error("Failed to mute the alarm. HTTP Error occurred.");
        throw new Error(await resp.text());
      }
    } finally {
      // Re-enable the mute button within 30 seconds if we do not get response from backend
      setTimeout(() => {
        isMuteInProgress.value = false;
      }, 30_000);
    }
  }

  function subscribeToAlarmNodeDisableSignalREvents() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on("ReceiveAlarmNodeDisableEvent", (rsp: DigitalOutputResponseEvent) => {
      // Avoid posting multiple successful toastMessages if we have multiple alarmNodes configured
      // And only push toast if this client was responsible for the command
      if (rsp.success && isMuteInProgress.value) {
        pushToast({
          title: `Command to mute alarmNode was ${rsp.success ? "successful" : "unsuccessful"}`,
          duration: 5_000,
          type: rsp.success ? ToastType.SUCCESS : ToastType.ERROR,
        });
      } else if (!rsp.success) {
        // But always show if we have an error for any of the alarmNodes
        pushToast({
          title: `Command to mute alarmNode with mac ${rsp.nodeMac.substring(
            rsp.nodeMac.length - 5,
          )} was unsuccessful`,
          duration: 5_000,
          type: ToastType.ERROR,
        });
      }

      isMuteInProgress.value = false;
    });
  }

  return {
    isMuteInProgress,
    muteAlarm,
  };
}

export type DigitalOutputResponseEvent = {
  nodeMac: string;
  success: boolean;
};
