<template>
  <li class="table-item" @click="$emit('addNewPersonClicked')">
    <div class="table-item-data">
      <slot />
    </div>
  </li>
</template>

<script>
export default {
  computed: {},

  methods: {},
};
</script>

<style scoped>
li {
  margin-top: 2px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background-color: var(--color-base-ui);
  transition: all 0.2s ease-in-out;
}
li:hover {
  background-color: var(--color-ui-05);
}

.table-item-data {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 72px;
  padding: 20px 16px 20px 0;
  min-width: 0px;
}

.table-item-data div {
  text-overflow: ellipsis;
  overflow: hidden;
}

.table-item-data div span {
  cursor: text;
}

.table-item {
  cursor: pointer;
}

.checkbox {
  flex: 0 0 40px;
  min-width: 0px;
}
.name > * {
  display: inline;
}

.checkbox {
  display: flex;
  align-items: center;
}

.mac {
  color: #0cebff;
}

.battery,
.temperature,
.signal {
  text-align: center;
}
li.selected {
  background-color: var(--color-brand-04);
}

li.table-item-disembark {
  background-color: var(--color-ui-02);
}
li.table-item-disembark > div {
  color: var(--color-brand-01);
}
li.table-item-dropoff > div {
  color: var(--color-brand-01);
}

@media (max-width: 470px) {
  .table-item-data {
    height: 56px;
  }
}
</style>
