import { render, staticRenderFns } from "./PersonTableItem.vue?vue&type=template&id=00086e63&scoped=true&"
import script from "./PersonTableItem.vue?vue&type=script&lang=ts&"
export * from "./PersonTableItem.vue?vue&type=script&lang=ts&"
import style0 from "./PersonTableItem.vue?vue&type=style&index=0&id=00086e63&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00086e63",
  null
  
)

export default component.exports