import { Ref, ref } from "vue";
import { SRadioOption } from "@/components/shared/SRadio.interface";

export enum NodeRelationVisualisations {
  Parents = "Parents",
  Children = "Children",
  Neighbour = "Neighbour",
}

const selectedRelationship = ref(NodeRelationVisualisations.Parents);

const relationshipOptions: Ref<SRadioOption[]> = ref([
  {
    value: NodeRelationVisualisations.Parents,
    name: "parents",
    isEnabled: true,
  },
  {
    value: NodeRelationVisualisations.Children,
    name: "children",
    isEnabled: true,
  },
  {
    value: NodeRelationVisualisations.Neighbour,
    name: "neighbors",
    isEnabled: true,
  },
]);

export default function () {
  return { selectedRelationship, relationshipOptions };
}
