import { ref } from "vue";
import { PersonActionsQuery } from "../compositions/useGangway";
import { Duration } from "date-fns";
import { cloneDeep } from "lodash";

const localStorageTemplatesKey = "GANGWAYLOG:TEMPLATES";
const templates = ref<GangwayLogQueryTemplate[] | null>(null);
const initialized = ref(false);

/**
 * Functions for storing and managing templates stored in localstorage
 * We could create a generic template manager and reuse across ConnectPOB and Connect
 */
export default function () {
  if (!initialized.value) {
    initialized.value = true;
    getTemplates();
  }

  /**
   * Adds a new template or updates an existing one with same id
   */
  async function addTemplate(template: GangwayLogQueryTemplate) {
    if (templates.value !== null) {
      const existingTemplateIdx = templates.value.findIndex((t) => t.id === template.id);
      if (existingTemplateIdx >= 0) {
        templates.value[existingTemplateIdx] = template;
      } else {
        templates.value.push(template);
      }
    }
    saveTemplatesToLocalStorage();
  }

  async function deleteTemplate(id: string) {
    if (templates.value !== null) {
      const idx = templates.value.findIndex((t) => t.id === id);
      if (idx >= 0) {
        templates.value.splice(idx, 1);
      }
    }
    saveTemplatesToLocalStorage();
  }

  async function cloneTemplate(id: string) {
    if (templates.value !== null) {
      const templateToClone = templates.value.find((t) => t.id === id);
      if (templateToClone) {
        const newTemplate = {
          ...cloneDeep(templateToClone),
          id: `"template" + ${Date.now()} + ${Math.random()}`,
          name: `${templateToClone.name} - copy`,
        };
        templates.value.push(newTemplate);
        saveTemplatesToLocalStorage();
      }
    }
  }

  async function clearTemplates() {
    templates.value = [];
    localStorage.removeItem(localStorageTemplatesKey);
  }

  async function getTemplates() {
    try {
      const localStorageData = localStorage.getItem(localStorageTemplatesKey);
      const templatesJsonParsed: GangwayLogQueryTemplate[] = localStorageData
        ? JSON.parse(localStorageData)
        : [];
      templates.value = templatesJsonParsed;
      return Promise.resolve();
    } catch (error) {
      console.error("Error when parsing templates in localstorage, removing all.", error);
      clearTemplates();
    }
  }

  function saveTemplatesToLocalStorage() {
    localStorage.setItem(localStorageTemplatesKey, JSON.stringify(templates.value));
  }

  return { templates, addTemplate, deleteTemplate, cloneTemplate, getTemplates, clearTemplates };
}

export type GangwayLogQueryTemplate = {
  id: string;
  name: string;
  title: string;
  query: PersonActionsQuery;
  timeShortcut: Duration | null;
  description: string;
  exportFormat: "xlsx" | "csv";
};
