import customFetch from "@/helpers/customFetch";
import { computed, ComputedRef, ref } from "vue";
import { environment } from "@/../environments/environment";
import queryToParams from "scanreach-frontend-components/src/utils/queryToParams";
import signalRSocketHandler from "@/helpers/signalRSocketHandler";
import { ApiPerson, ApiPersonAction, DashboardPerson, PersonActionSource } from "@/types/personTypes";
import {
  Gangway,
  GangwayAction,
  GangwayEventState,
  GangwayLocation,
  GangwaylocationWrapper as GangwayLocationWrapper,
  GangwayWriteModel,
  PersonActionWithPerson,
  SpatialDataObject,
  VesselLocation,
} from "@/types/gangwayTypes";
import {
  headersAndRowsToCsv,
  headersAndRowsToSpreadsheet,
} from "scanreach-frontend-components/src/utils/csvAndSpreadsheetUtils";
import { PersonActionName } from "@/typedef";
import { get } from "lodash";

const genericApiAddress = environment.genericApiAddress;
const apiAddress = environment.apiAddress;

/**
 * List of unapproved gangwayActions
 */
const gangwayActionsFromApi = ref<GangwayAction[] | null>(null);
let gangwayActions: ComputedRef<GangwayAction[] | null | undefined>;
/**
 * List of last x personActions, either approved gangwayActions or manual actions performed.
 */
const last50PersonActions = ref<PersonActionWithPerson[] | null>(null);
const gangwayLocations = ref<GangwayLocationWrapper | null>(null);
const gangways = ref<Gangway[] | null>(null);
const vesselLocation = ref<VesselLocation | null>(null);
const isGangwayLocationAutoMode = ref<boolean>(false);
const spatialDataObjectWithinRange = ref<SpatialDataObject | null>(null);
const isLoading = ref(false);
const isConfigBeingSaved = ref(false);

const initialLoaded = ref(false);

export default function (store: any) {
  if (!initialLoaded.value) {
    initialLoaded.value = true;
    // Ensures that gangwayActions are connected to their correct person from vuex
    gangwayActions = computed(() =>
      gangwayActionsFromApi.value?.map((a) => ({
        ...a,
        person: store.getters.getPersonById(a.personId) as DashboardPerson | undefined | null,
      })),
    );

    if (process.env.NODE_ENV != "test") {
      fetchDataFromApiAndSubscribeToSignalR();
    }
  }

  function fetchDataFromApiAndSubscribeToSignalR() {
    fetchGangways();
    fetchGangwayActions();
    fetchPersonActions();
    fetchGangwayLocations();
    fetchCurrentVesselLocation();
    fetchVesselLocationMode();
    getCachedSpatialDataObjectWithinRange();

    subscribeToReconnectAndBroadSignalREvents();
  }

  /**
   * Approve or reject automatic gangwayActions
   * @param actions List of gangwayActions
   * @param approve Whether to approve or reject actions
   * @param location Id of the desired dropoff location. Only used when approving
   */
  async function approveGangwayActions(actions: GangwayAction[], approve: boolean, location?: string) {
    const actionsToSend = {
      location: location,
      events: actions.map((item) => ({
        id: item.id,
        approve: approve,
      })),
    };
    const resp = await customFetch(`${apiAddress}/gangwayEvents/handle`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(actionsToSend),
    });

    if (!resp.ok) {
      throw new Error(await resp.text());
    }
  }

  /**
   * Fetch automatic gangway actions from api.
   * You can discard the returned value and instead listen for changes on gangwayActions
   */
  async function fetchGangwayActions(): Promise<GangwayAction[]> {
    isLoading.value = true;
    try {
      const queryParamString = queryToParams({
        state: GangwayEventState.Unhandled,
      });
      const resp = await customFetch(`${apiAddress}/gangwayEvents${queryParamString}`, {
        method: "GET",
      });

      if (resp.ok) {
        const actionsFromApi = (await resp.json()) as GangwayAction[];
        gangwayActionsFromApi.value = actionsFromApi;
        return actionsFromApi;
      } else {
        throw new Error(await resp.text());
      }
    } finally {
      // Exception is thrown since we do not have any catch here.
      isLoading.value = false;
    }
  }

  /**
   * Fetch approved personActions from api.
   * You can discard the returned value and instead listen for changes on personActions
   */
  async function fetchPersonActions(setLoading = true): Promise<PersonActionWithPerson[]> {
    isLoading.value = setLoading ? true : isLoading.value;
    try {
      const queryParamString = queryToParams({
        limit: 50,
      });
      const resp = await customFetch(`${apiAddress}/personActions${queryParamString}`, {
        method: "GET",
      });

      if (resp.ok) {
        const personActionsFromApi = (await resp.json()) as PersonActionWithPerson[];
        last50PersonActions.value = personActionsFromApi;
        return personActionsFromApi;
      } else {
        throw new Error(await resp.text());
      }
    } finally {
      // Exception is thrown since we do not have any catch here.
      isLoading.value = false;
    }
  }

  /**
   * Fetch gangwayLocations from api.
   * You can discard the returned value and instead listen for changes on gangwayLocations
   */
  async function fetchGangwayLocations(): Promise<GangwayLocationWrapper> {
    isLoading.value = true;
    try {
      const resp = await customFetch(`${apiAddress}/GangwayLocation`, {
        method: "GET",
      });

      if (resp.ok) {
        const gangwayLocationsFromApi = (await resp.json()) as GangwayLocationWrapper;
        gangwayLocations.value = gangwayLocationsFromApi;
        return gangwayLocationsFromApi;
      } else {
        throw new Error(await resp.text());
      }
    } finally {
      // Exception is thrown since we do not have any catch here.
      isLoading.value = false;
    }
  }

  /**
   * Removes specified gangway location
   * @param gangwayLocationId
   */
  async function deleteGangwayLocation(gangwayLocationId: string): Promise<void> {
    const resp = await customFetch(`${apiAddress}/GangwayLocation/${gangwayLocationId}`, {
      method: "DELETE",
    });

    if (resp.ok) {
      return;
    } else {
      throw new Error(await resp.text());
    }
  }

  async function fetchGangways(): Promise<Gangway[]> {
    isLoading.value = true;
    try {
      const resp = await customFetch(`${apiAddress}/Gangway`, {
        method: "GET",
      });

      if (resp.ok) {
        const data = (await resp.json()) as Gangway[];
        gangways.value = data;
        return gangways.value;
      } else {
        throw new Error(await resp.text());
      }
    } finally {
      // Exception is thrown since we do not have any catch here.
      isLoading.value = false;
    }
  }

  async function fetchCurrentVesselLocation(): Promise<VesselLocation | null> {
    isLoading.value = true;
    try {
      const resp = await customFetch(`${apiAddress}/VesselLocation`, {
        method: "GET",
      });

      if (resp.ok) {
        if (resp.status === 204) {
          vesselLocation.value = null;
          return null;
        } else {
          const data = (await resp.json()) as VesselLocation;
          vesselLocation.value = data;
          return data;
        }
      } else {
        throw new Error(await resp.text());
      }
    } finally {
      // Exception is thrown since we do not have any catch here.
      isLoading.value = false;
    }
  }

  async function fetchVesselLocationMode(): Promise<boolean> {
    const resp = await customFetch(`${apiAddress}/VesselLocation/configuration`);
    if (resp.ok) {
      isGangwayLocationAutoMode.value = (
        (await resp.json()) as { isVesselLocationSetAutomatically: boolean }
      ).isVesselLocationSetAutomatically;
      return isGangwayLocationAutoMode.value;
    } else {
      throw new Error(await resp.text());
    }
  }

  /**
   * Defines if vesselLocation should be set automatically via GPS location input or manually
   */
  async function setVesselLocationMode(isGangwayLocationAutoMode: boolean) {
    await customFetch(`${apiAddress}/VesselLocation/configuration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ isVesselLocationSetAutomatically: isGangwayLocationAutoMode }),
    });
  }

  /**
   * Set the current global vesselLocation, used when gangway is in auto mode and to suggest location when approving gangwayEvents
   * @param location name of the location
   * @param potentiallySaveLocation if true, backend will try to add this location to list of custom gangwayLocations
   * @returns The new vesselLocation
   */
  async function setCurrentVesselLocation(
    location: string,
    potentiallySaveLocation: boolean = false,
  ): Promise<VesselLocation> {
    const resp = await customFetch(`${apiAddress}/VesselLocation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        label: location,
        potentiallySaveLocationToListOfGangwayLocations: potentiallySaveLocation,
      }),
    });

    if (resp.ok) {
      const data = (await resp.json()) as VesselLocation;
      vesselLocation.value = data;
      return data;
    } else {
      throw new Error(await resp.text());
    }
  }

  async function getCachedSpatialDataObjectWithinRange() {
    const resp = await customFetch(`${apiAddress}/VesselLocation/cached/spatialObject`);
    if (resp.ok) {
      if (resp.status === 204) {
        spatialDataObjectWithinRange.value = null;
      } else {
        spatialDataObjectWithinRange.value = (await resp.json()) as SpatialDataObject;
      }
    } else {
      throw new Error(await resp.text());
    }
  }

  /**
   * If id is undefined -> POST
   * If id is specified -> PUT
   * @param gangway
   */
  async function addOrUpdateGangway(gangway: GangwayWriteModel): Promise<Gangway | void> {
    let apiUrl = `${apiAddress}/Gangway`;
    let method: "POST" | "PUT" = "POST";
    if (gangway.id) {
      apiUrl += `/${gangway.id}`;
      method = "PUT";
    }
    isConfigBeingSaved.value = true;
    try {
      const resp = await customFetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(gangway),
      });

      if (resp.ok) {
        if (resp.status !== 204) {
          return (await resp.json()) as Gangway;
        }
      } else {
        throw new Error(await resp.text());
      }
    } finally {
      isConfigBeingSaved.value = false;
    }
  }

  /**
   *
   * @param gangwayId
   */
  async function removeGangway(gangwayId: string): Promise<void> {
    const apiUrl = `${apiAddress}/Gangway/${gangwayId}`;
    const method = "DELETE";

    const resp = await customFetch(apiUrl, {
      method: method,
    });

    if (resp.ok) {
      return;
    } else {
      throw new Error(await resp.text());
    }
  }

  function subscribeToGangwayConfigSignalRMessages() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on("ReceiveGangwayConfigurationChangeEvent", (gangway: Gangway) => {
      // Do not do anything if gangways has not been loaded from api yet,
      // the GET action will just replace the array either way.
      if (gangways.value) {
        if (gangway.deletedUtcDateTime) {
          // Remove config
          const existingIdx = gangways.value.findIndex((g) => g.id === gangway.id);
          if (existingIdx > -1) {
            gangways.value?.splice(existingIdx, 1);
          }
        } else {
          const existingGangway = gangways.value.find((g) => g.id === gangway.id);
          if (existingGangway) {
            // Exists, update it
            existingGangway.label = gangway.label;
            existingGangway.active = gangway.active;
            existingGangway.approvalMode = gangway.approvalMode;
            existingGangway.onboardingNodes = gangway.onboardingNodes;
            existingGangway.dropoffNodes = gangway.dropoffNodes;
            existingGangway.x = gangway.x;
            existingGangway.y = gangway.y;
            existingGangway.rssiLimit = gangway.rssiLimit;
            existingGangway.gangwayConfigurationStatus = gangway.gangwayConfigurationStatus;
          } else {
            // New config, add it
            gangways.value.push(gangway);
          }
        }
      }
    });
  }

  function subscribeToGangwayActionSignalRMessages() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on("ReceiveGangwayEvent", (gangwayAction: GangwayAction) => {
      // Do not do anything if gangways has not been loaded from api yet,
      // the GET action will just replace the array either way.
      if (gangwayActionsFromApi.value) {
        if (gangwayAction.deletedUtcDateTime || gangwayAction.state !== GangwayEventState.Unhandled) {
          // Remove action
          const existingIdx = gangwayActionsFromApi.value.findIndex((g) => g.id === gangwayAction.id);
          if (existingIdx > -1) {
            gangwayActionsFromApi.value?.splice(existingIdx, 1);
          }
        } else {
          const existingGangwayAction = gangwayActionsFromApi.value.find((g) => g.id === gangwayAction.id);
          if (existingGangwayAction) {
            // Exists, update it
            existingGangwayAction.state = gangwayAction.state;
          } else {
            // New action, add it
            gangwayActionsFromApi.value.push(gangwayAction);
          }
        }
      }
    });
  }

  function subscribeToGangwayLocationSignalRMessages() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on("ReceiveGangwayLocationChangeEvent", (gangwayLocation: GangwayLocation) => {
      // Do not do anything if gangwayLocations has not been loaded from api yet,
      // the GET gangwayLocation will just replace the array either way.
      // SpatialDataObjects will not be updated here, as they will be synced from cloud and will trigger a fetchDataFromApi
      if (gangwayLocations.value) {
        if (gangwayLocation.deletedUtcDateTime) {
          // Remove location
          const existingIdx = gangwayLocations.value.gangwayLocations.findIndex(
            (g) => g.id === gangwayLocation.id,
          );
          if (existingIdx > -1) {
            gangwayLocations.value?.gangwayLocations.splice(existingIdx, 1);
          }
        } else {
          const existing = gangwayLocations.value.gangwayLocations.find((g) => g.id === gangwayLocation.id);
          if (existing) {
            // Exists, update it
            existing.label = gangwayLocation.label;
          } else {
            // New location, add it
            gangwayLocations.value.gangwayLocations.push(gangwayLocation);
          }
        }
      }
    });
  }

  function subscribeToGangwayConfigurationSignalRMessages() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on(
      "ReceiveVesselLocationConfigurationChangeEvent",
      (configuration: { isVesselLocationSetAutomatically: boolean }) => {
        isGangwayLocationAutoMode.value = configuration.isVesselLocationSetAutomatically;
      },
    );
  }

  // here we update only gangway actions
  // persons state is updated in desktopStore.js
  function subscribeToPersonConfigurationSignalRMessages() {
    signalRSocketHandler.on("ReceivePersonConfigurationChangeEvent", (person: ApiPerson) => {
      potentiallyUpdatePersonActionFromSignalR(person.lastPersonAction);
    });
  }

  function subscribeToPersonActionSignalRMessages() {
    signalRSocketHandler.on("ReceivePersonActionChangeEvent", (action: ApiPersonAction) => {
      potentiallyUpdatePersonActionFromSignalR(action);
    });
  }

  function subscribeToVesselLocationSignalRMessages() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on("ReceiveVesselLocationChangeEvent", (location: VesselLocation) => {
      vesselLocation.value = location;
    });
  }

  function subscribeToSpatialDataObjectWithinRangeSignalRMessages() {
    signalRSocketHandler.connect();
    signalRSocketHandler.on(
      "ReceivePossibleSpatialObjectChangeEvent",
      (spatialDataObject: SpatialDataObject | null) => {
        spatialDataObjectWithinRange.value = spatialDataObject;
      },
    );
  }

  function subscribeToReconnectAndBroadSignalREvents() {
    signalRSocketHandler.subscribe("reconnect", () => {
      fetchDataFromApiAndSubscribeToSignalR();
    });
    signalRSocketHandler.subscribe("statuschange", (status) => {
      if (status.connected && !gangwayActionsFromApi.value && !isLoading.value) {
        fetchDataFromApiAndSubscribeToSignalR();
      }
    });
    signalRSocketHandler.on("ReceiveConfigurationChangeEvent", () => {
      // Fetch all data again as there have been a CloudSync event
      fetchDataFromApiAndSubscribeToSignalR();
    });

    subscribeToGangwayConfigSignalRMessages();
    subscribeToGangwayActionSignalRMessages();
    subscribeToPersonActionSignalRMessages();
    subscribeToPersonConfigurationSignalRMessages();
    subscribeToGangwayLocationSignalRMessages();
    subscribeToVesselLocationSignalRMessages();
    subscribeToGangwayConfigurationSignalRMessages();
    subscribeToSpatialDataObjectWithinRangeSignalRMessages();
  }

  function potentiallyUpdatePersonActionFromSignalR(action: ApiPersonAction | undefined | null) {
    // Do not do anything if gangways has not been loaded from api yet,
    // the GET action will just replace the array either way.

    if (!action) return;
    if (!last50PersonActions.value) return;

    const paId = action.id;
    const paDateTime = action.dateTimeUtc;
    const existing = last50PersonActions.value.find((p) => p.id === paId);

    // Action is already in the list
    if (existing) {
      // update PersonAction
      existing.location = action.location;
      return;
    }

    // PersonAction is not among last 50 actions, should add it
    const lastPaInList = last50PersonActions.value[last50PersonActions.value.length - 1];
    const person = store.getters.getPersonById(action.personId) as DashboardPerson | undefined | null;
    if (!person) {
      console.error("Can not update person action, person not found in store.");
      return;
    }

    if (!lastPaInList || paDateTime > lastPaInList.dateTimeUtc) {
      // PersonAction is newer than the the latest action
      const personActionToAddToList = {
        ...action,
        person: {
          id: person.id,
          fullName: person.fullName,
        },
      };

      for (let i = 0; i < last50PersonActions.value.length; i++) {
        // Insert personAction at correct location in last50PersonActions, as long as it is sorted on dateTimeUtc
        // Remove last personAction in list if more than 50 elements
        const element = last50PersonActions.value[i];
        if (paDateTime > element.dateTimeUtc) {
          last50PersonActions.value = [
            ...last50PersonActions.value.slice(0, i),
            personActionToAddToList,
            ...last50PersonActions.value.slice(
              i,
              last50PersonActions.value.length - (last50PersonActions.value.length <= 49 ? 0 : 1),
            ),
          ];
          break;
        } else if (i == last50PersonActions.value.length) {
          last50PersonActions.value.push(personActionToAddToList);
        }
      }
    }
  }

  return {
    isLoading,
    isConfigBeingSaved,
    approveGangwayActions,
    fetchGangwayActions,
    fetchPersonActions,
    fetchGangways,
    gangwayActions,
    addOrUpdateGangway,
    removeGangway,
    last50PersonActions,
    gangwayLocations,
    gangways,
    vesselLocation,
    setCurrentVesselLocation,
    deleteGangwayLocation,
    setVesselLocationMode,
    fetchVesselLocationMode,
    isGangwayLocationAutoMode,
    spatialDataObjectWithinRange,
  };
}

export async function fetchSiteName(): Promise<string> {
  const resp = await customFetch(`${genericApiAddress}/Sites`, {
    method: "GET",
  });

  if (resp.ok) {
    const sites = (await resp.json()) as ApiSite[];
    return sites.length > 0 ? sites[0].name : "";
  } else {
    throw new Error(await resp.text());
  }
}

export async function exportBoardingActions(
  personActionsQuery: PersonActionsQuery,
  format: "xlsx" | "csv",
  fileName = "Person Actions",
) {
  const queryParamString = queryToParams(
    {
      siteIds: personActionsQuery.commaSeparatedSiteIds,
      from: personActionsQuery.from,
      to: personActionsQuery.to,
      actionTypes: personActionsQuery.filters.boardingActions.length
        ? personActionsQuery.filters.boardingActions
        : undefined,
      actionSources: personActionsQuery.filters.actionSources.length
        ? personActionsQuery.filters.actionSources
        : undefined,
      locations: personActionsQuery.filters.locations?.length
        ? personActionsQuery.filters.locations
        : undefined,
      gangwayNames: personActionsQuery.filters.gangwayStations.length
        ? personActionsQuery.filters.gangwayStations
        : undefined,
    },
    false,
  );
  const resp = await customFetch(`${genericApiAddress}/person/GetBoardingActions${queryParamString}`, {
    method: "GET",
  });

  if (resp.ok) {
    const personActionsFromApi = (await resp.json()) as BoardingActionFullReadModel[];
    const headersAndRows = getHeadersAndRowsFromBoardingActions(
      personActionsFromApi,
      personActionsQuery.columns,
    );
    if (format === "xlsx") {
      headersAndRowsToSpreadsheet(headersAndRows.headers, headersAndRows.rows, fileName);
    } else {
      headersAndRowsToCsv(headersAndRows.headers, headersAndRows.rows, fileName);
    }
  } else {
    throw new Error(await resp.text());
  }
}

/**
 * Converts a list of boarding actions to a list of headers and rows
 */
export function getHeadersAndRowsFromBoardingActions(
  personActions: BoardingActionFullReadModel[],
  columns: GangwayExportProperty[],
): {
  headers: string[];
  rows: (string | number | null | undefined)[][];
} {
  return {
    headers: columns.map((c) => c.displayName),
    rows: personActions.map((personAction) => columns.map((c) => get(personAction, c.path))),
  };
}

export function getMatchingSpatialLocationOrGangwayLocation(
  vesselLocation: string | null,
  gangwayLocations: GangwayLocationWrapper | null,
): GangwayLocation | (SpatialDataObject & { combinedLabel: string }) | null {
  if (!vesselLocation || !gangwayLocations) {
    return null;
  }
  for (const location of gangwayLocations.gangwayLocations) {
    if (location.label === vesselLocation) {
      return location;
    }
  }
  const [groupName, locationName] = vesselLocation.split(" - ");

  for (const spatialLocation of gangwayLocations.spatialLocations) {
    if (spatialLocation.label === groupName) {
      for (const spatialDataObject of spatialLocation.spatialDataObjects) {
        if (spatialDataObject.label === locationName) {
          return { ...spatialDataObject, combinedLabel: groupName + " - " + locationName };
        }
      }
    }
  }
  return null;
}

export const GangwayExportKnownProperties: (GangwayExportProperty & { isDefault?: boolean })[] = [
  { displayName: "Date time UTC", path: "dateTimeUtc", isDefault: true },
  { displayName: "Boarding action", path: "action", isDefault: true },
  { displayName: "Location", path: "location", isDefault: true },
  { displayName: "Person Name", path: "person.fullName", isDefault: true },
  { displayName: "Person role", path: "person.role.name", isDefault: true },
  { displayName: "Date of Birth", path: "person.dateOfBirth" },
  { displayName: "Cabin Number", path: "person.cabinNr" },
  { displayName: "Bunk Number", path: "person.bunkNr" },
  { displayName: "Wearable mac", path: "person.wearable.mac" },
  { displayName: "Source", path: "actionSource" },
  { displayName: "Gangway station", path: "gangwayEvent.gangway.label", isDefault: true },
  // { displayName: "Boarding Action Id", path: "id", },
  // { displayName: "External person reference", path: "person.externalPersonReference.ExternalPersonId", },
];
export function getDefaultGangwayExportProperties(): GangwayExportProperty[] {
  return GangwayExportKnownProperties.filter((p) => p.isDefault);
}

export type GangwayExportProperty = {
  displayName: string;
  path: string;
};

export type GangwayLogFilters = {
  boardingActions: PersonActionName[];
  actionSources: PersonActionSource[];
  /**
   * Name of each gangway station to filter for
   */
  gangwayStations: string[];
  /**
   * Name of each location to filter for
   */
  locations?: string[];
};

export type PersonActionsQuery = {
  commaSeparatedSiteIds?: string;
  from: Date;
  to: Date;
  columns: GangwayExportProperty[];
  filters: GangwayLogFilters;
};

export interface BoardingActionFullReadModel extends ApiPersonAction {
  person: ApiPerson;
  gangwayEvent?: GangwayAction | null;
}

export interface ApiSite {
  id: string;
  customerId: string;
  name: string;
}
