import { DashboardPerson } from "@/types/personTypes";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { formatDate } from "scanreach-frontend-components/src/utils/timeUtils";
import { DashboardWearable } from "@/typedef";
import { ApiNode } from "scanreach-frontend-components/src/types/ApiNode.type";

function exportNodeListAsPDF(node: ApiNode, peopleWithWearable: PeopleWithWearable[]) {
  const docTitle = `Personnel at node '${node.label}' - ${formatDate(new Date())}`;

  const data = peopleWithWearable.map((person) => {
    return {
      /*id: item.id,*/
      mac: person.wearable?.mac,
      label: person.fullName,
      role: person.role?.name,
      battery: person.wearable?.sensorData?.batteryPercentage + "%",
      temperature: person.wearable?.sensorData?.temperature + "ºC",
      signalstrength: person.wearable?.sensorData?.rssi + "dB",
    };
  });
  const headers = ["MAC", "Full Name", "Role", "Battery", "Temperature", "Signal strength"];

  const doc = new jsPDF("landscape");

  doc.setFontSize(18);
  doc.text(docTitle, 10, 10);

  autoTable(doc, {
    head: [headers],
    body: data.map((item: any) => {
      return Object.keys(item).map((key) => {
        return item[key];
      });
    }),
  });

  doc.save(`${docTitle}.pdf`);
}

export { exportNodeListAsPDF };

interface PeopleWithWearable extends DashboardPerson {
  wearable: DashboardWearable;
}
