<template functional>
  <svg
    :width="props.size"
    :height="props.size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="16"
      y1="2.25"
      x2="16"
      y2="4.25"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <path
      d="M27.32,24.25H4.68a.09.09,0,0,1-.08-.14c1.7-2.46,3.07-8.74,3.93-13.59A7.58,7.58,0,0,1,16,4.25h0a7.58,7.58,0,0,1,7.47,6.27c.86,4.85,2.23,11.13,3.93,13.59A.09.09,0,0,1,27.32,24.25Z"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <path d="M12.14,26.75a4,4,0,0,0,7.72,0Z" :fill="props.iconColor" />
  </svg>
</template>

<script>
export default {
  props: {
    size: String,
    iconColor: String,
  },
};
</script>

<style></style>
