import { PersonActionName } from "./../typedef";
import { environment } from "../../environments/environment";
import customFetch from "@/helpers/customFetch";
import { CustomFetchOptions, Guid } from "@/typedef";
import { ApiPerson, ApiPersonRole, ExternalSyncResultReadModel } from "@/types/personTypes";

const apiAddress = environment.apiAddress;

/**
 * Get all people
 * @returns {Promise<ApiPerson[]>}
 */
export function fetchPersons({ showWarning = false }: CustomFetchOptions = {}): Promise<ApiPerson[]> {
  return customFetch(
    `${apiAddress}/persons`,
    {
      method: "GET",
      credentials: "include",
    },
    showWarning,
  ).then((response) => {
    return response.json();
  });
}

/**
 * Get person by id
 * @returns {Promise<ApiPerson>}
 */
export function fetchPerson(personId: Guid): Promise<ApiPerson[]> {
  return customFetch(`${apiAddress}/persons/${personId}`, {
    method: "GET",
    credentials: "include",
  }).then((response) => {
    return response.json();
  });
}

/**
 * Put person by id
 * @returns {Promise<void>}
 */
export function putPerson(person: ApiPerson): Promise<void> {
  return new Promise((resolve, reject) => {
    const body: {
      fullName?: string | null;
      dateOfBirth?: Date | null;
      cabinNr?: string | null;
      bunkNr?: string | null;
      roleId?: string | null;
      tagId?: string | null;
    } = {};

    if (!person.externalPersonReference?.isExternalSystemOwnerOfData) {
      body.fullName = person.fullName;
      body.dateOfBirth = person.dateOfBirth;
      body.cabinNr = person.cabinNr;
      body.bunkNr = person.bunkNr;
      body.roleId = person.role?.id;
    }
    if (!person.externalPersonReference?.isExternalSystemResponsibleForConnectingWearable) {
      body.tagId = person.wearable?.id;
    }

    return customFetch(`${apiAddress}/persons/${person.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          resolve();
        } else {
          reject("Error statuscode: " + res.status);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Add a new person
 * @returns {Promise<ApiPerson>}
 */
export function postPerson(person: ApiPerson): Promise<ApiPerson> {
  return new Promise((resolve, reject) => {
    const body = {
      fullName: person.fullName,
      dateOfBirth: person.dateOfBirth,
      cabinNr: person.cabinNr,
      bunkNr: person.bunkNr,
      roleId: person.role?.id,
      tagId: person.wearable?.id,
    };
    return customFetch(`${apiAddress}/persons`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          reject("Error statuscode: " + res.status);
        }
      })
      .then((newPerson: ApiPerson) => {
        resolve(newPerson);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Delete person by id
 * @returns {Promise<void>}
 */
export function deletePerson(personId: Guid): Promise<void> {
  return new Promise((resolve, reject) => {
    return customFetch(`${apiAddress}/persons/${personId}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          resolve();
        } else {
          res.text().then((errorMsg) => {
            reject(res.status + " " + errorMsg);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Fetch all person roles
 * @return {Promise<ApiPersonRole[]>} A promise with roles as an array of strings
 */
export function fetchPersonRoles({ showWarning = false }: CustomFetchOptions = {}): Promise<ApiPersonRole[]> {
  return new Promise((resolve, reject) => {
    try {
      customFetch(
        apiAddress + "/personroles",
        {
          method: "GET",
          credentials: "include",
        },
        showWarning,
      )
        .then((res) => res.json())
        .then((roles: ApiPersonRole[]) => {
          resolve(roles);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * POSTs a new person action
 *
 * @param {} personId Guid id
 * @param {} personAction What state to put the wearable in
 * @param {} location Where this person went off to or on from
 * @returns {Promise<ApiPerson>} Returns a fetch response. Remember to catch errors.
 */
export function performPersonAction(
  personId: Guid,
  personAction: PersonActionName,
  location?: string | null,
): Promise<ApiPerson> {
  return new Promise((resolve, reject) => {
    const body: {
      action: PersonActionName;
      location?: string | null;
    } = {
      action: personAction,
      location,
    };
    customFetch(`${apiAddress}/persons/${personId}/actions`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((person: ApiPerson) => {
            resolve(person);
          });
        } else {
          resp.text().then((errorMsg) => {
            reject("Error: " + resp.status + " " + errorMsg);
          });
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
}

/**
 * Update location of a existing personAction
 *
 * @param {} personActionId Guid id
 * @param {} [location] Location to change to
 * @returns {} Returns a fetch response. Remember to catch errors.
 */
export async function updatePersonActionLocation(
  personActionId: Guid,
  location?: string | null,
): Promise<void> {
  const body: {
    location?: string | null;
  } = {
    location,
  };
  const resp = await customFetch(`${apiAddress}/personActions/${personActionId}`, {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (resp.ok) {
    return;
  } else {
    resp.text().then((errorMsg) => {
      throw new Error(resp.status + " " + errorMsg);
    });
  }
}

/**
 * Get sync status from external system
 * @returns {Promise<ExternalSyncResultReadModel>}
 */
export async function fetchExternalSystemSyncStatus(): Promise<ExternalSyncResultReadModel> {
  const response = await customFetch(`${apiAddress}/persons/sync/status`, {
    method: "GET",
    credentials: "include",
  });
  return await response.json();
}
