var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"toaster",class:{
    'toaster-show': _vm.isShowing,
    'toaster-success': _vm.type === 'success',
    'toaster-warning': _vm.type === 'warning',
    'toaster-info': _vm.type === 'info',
    'toaster-error': _vm.type === 'error',
  }},[_c(_vm.selectedIcon,{tag:"component",attrs:{"size":"24","iconColor":_vm.iconColor}}),_c('div',{staticClass:"message"},[_vm._v(_vm._s(_vm.message))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }