import { existsSession } from "@/CustomVueSession";
const ERROR_MESSAGE_NOT_LOGGED_IN = "User is not logged in";
/**
 * Wrapper of standard fetch method to automatically logout if cookie has expired.
 * @param url URL to fetch
 * @param settings Settings
 */
const customFetch = (
  url: RequestInfo,
  settings: RequestInit = {},
  showWarning = true,
  checkLogInBeforeSend = false,
): Promise<Response> => {
  if (checkLogInBeforeSend && !existsSession()) {
    throw new Error(ERROR_MESSAGE_NOT_LOGGED_IN);
  }
  return fetch(url, { ...settings, credentials: "include" })
    .then((res) => {
      if (res.status == 401) {
        // This user's role is not authorized to fetch this url
        // Show not authorized message
        if (showWarning) {
          console.error(`Cookie has expired ${url}`);
          (window as any).destroySessionAndGoToLogin();
        }
        throw new Error("Unauthorized");
      } else if (res.status == 403) {
        // Cookie has expired
        // Logout
        console.error("Unauthorized to view content");
        (window as any).triggerShowNotAuthorizedToaster();
        throw new Error("403");
      }

      return res;
    })
    .catch((err: Error) => {
      if (err.message == "Failed to fetch") {
        err.message = "Unable to connect to API.";
      }
      throw err;
    });
};

export default customFetch;
