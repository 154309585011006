let STORAGE = window.localStorage;
const VueSession = {
  key: "vue-session-key",
  flash_key: "vue-session-flash-key",
  setAll: function (all) {
    STORAGE.setItem(VueSession.key, JSON.stringify(all));
  },
};

VueSession.install = function (Vue, options) {
  if (options && "persist" in options && options.persist) STORAGE = window.localStorage;
  else STORAGE = window.sessionStorage;
  Vue.prototype.$session = {
    flash: {
      parent: function () {
        return Vue.prototype.$session;
      },
      get: function (key) {
        const all = this.parent().getAll();
        const all_flash = all[VueSession.flash_key] || {};

        const flash_value = all_flash[key];

        this.remove(key);

        return flash_value;
      },
      set: function (key, value) {
        const all = this.parent().getAll();
        const all_flash = all[VueSession.flash_key] || {};

        all_flash[key] = value;
        all[VueSession.flash_key] = all_flash;

        VueSession.setAll(all);
      },
      remove: function (key) {
        const all = this.parent().getAll();
        const all_flash = all[VueSession.flash_key] || {};

        delete all_flash[key];

        all[VueSession.flash_key] = all_flash;
        VueSession.setAll(all);
      },
    },
    getAll: getAllSessions,
    set: setSession,
    get: getSession,
    start: startSession,
    renew: function (sessionId) {
      const all = this.getAll();
      all["session-id"] = "sess:" + sessionId;
      VueSession.setAll(all);
    },
    exists: existsSession,
    has: function (key) {
      const all = this.getAll();
      return key in all;
    },
    remove: function (key) {
      const all = this.getAll();
      delete all[key];

      VueSession.setAll(all);
    },
    clear: function () {
      const all = this.getAll();

      VueSession.setAll({ "session-id": all["session-id"] });
    },
    destroy: destroySession,
    id: function () {
      return this.get("session-id");
    },
  };
};

export default VueSession;

export function startSession() {
  const all = getAllSessions();
  all["session-id"] = "sess:" + Date.now();

  VueSession.setAll(all);
}

export function getAllSessions() {
  const all = JSON.parse(STORAGE.getItem(VueSession.key));
  return all || {};
}

/**
 *
 * @param {string} key
 * @returns
 */
export function getSession(key) {
  const all = getAllSessions();
  return all[key];
}

export function setSession(key, value) {
  if (key == "session-id") return false;
  let all = getAllSessions();

  if (!("session-id" in all)) {
    startSession();
    all = getAllSessions();
  }

  all[key] = value;

  VueSession.setAll(all);
}

export function destroySession() {
  VueSession.setAll({});
}

export function existsSession() {
  const all = getAllSessions();
  return "session-id" in all;
}
