// Copied from ConnectFleet.Frontend codebase

export type SensorConfiguration = {
  id: string;
  /**
   * Null when not configured
   */
  tagName: string | null;
  /**
   * Mac of sensor, for example bleMac for RuuviTag or node mesh mac for fujiFlowMeter
   * Will be null when a virtual sensor
   */
  sensorId: string | null;
  description: string | null;
  x: number | null;
  y: number | null;
  measurementTypesToShowInMap: string[] | null;
  sensorTypeDefinition: SensorTypeDefinition;
  sensorTypeDefinitionId: string;
  modbusNodeConfiguration: ModbusNodeConfig | null;
  nodeConfigurationState: NodeConfigurationState;
  /**
   * Soft deleted if defined
   */
  deletedUtcDateTime: Date | null;
};

export enum NodeConfigurationState {
  /**
   * For example bluetooth sensors will have not applicable, since they are not connected to a node
   */
  NOT_APPLICABLE = "notApplicable",
  PENDING = "pending",
  OK = "ok",
  ERROR = "error",
}

export type SensorConfigurationWithLastSensorData = SensorConfiguration & {
  lastSensorData?: SensorData[] | null;
  lastReported?: Date | string | null;
};

/**
 * Composite enumState of sensorConfiguration based on both NodeConfigurationState and time since last reported
 */
export enum SensorConfigurationState {
  PENDING = "pending",
  OK = "ok",
  ERROR = "error",
}

export type ModbusNodeConfig = {
  id: string;
  nodeId: string;
  currentConfigurationId: string | null;
  desiredConfigurationId: string | null;
  currentConfiguration: ModbusDeviceTypeModel | null;
  desiredConfiguration: ModbusDeviceTypeModel | null;
  updatedTime: Date | null;
  nodeConfigurationState: NodeConfigurationState;
};

export type ModbusDeviceTypeModel = {
  modbusType: {
    serialCommunicationParameters: SerialCommunicationParameters;
    timeoutMilliseconds: number;
    readOperations: ReadOperation[];
  };
};
export type ReadOperation = {
  sensorTypeId: number;
  revision: number;
  modbusReadParameters: ModbusReadParameters;
  // slices: ModbusDataSlice[]; // Not really interesting
};
export interface ModbusReadParameters {
  /**
   * Between 1 and 247
   */
  serverId: number;
  /**
   * How often to poll the sensor in seconds
   */
  pollIntervalSeconds: number;
  functionCode: number;
  readRegisterStart: number;
  readRegisterCount: number;
}

export type SerialCommunicationParameters = {
  parity: string;
  baudrate: string;
  /**
   * 0 for Modbus
   * 1 for NMEA
   */
  serialCommunicationProtocol: "Nmea" | "Modbus";
};

export type NmeaSubscription = {
  /**
   * A descriptive name for the subscription
   */
  name: string;

  /**
   * 5 Characters defining what type of NMEA message to subscribe to
   * Ex: 'VDVHW,,T,,M,0.20,N,0.36,K' -> VDVHW
   */
  nmeaInstrumentId: string;

  /**
   * Maximum how often should the NMEA message be sent
   * 0 => As often as we receive data from the NMEA sensor.
   * Keep this above 1 to avoid flooding the mesh network.
   */
  intervalSec: number;
};

export type SensorTypeDefinition = {
  id: string;
  name: string;
  revision: number;
  sensorTypeId: number | null;
  description: string | null;
  lastUpdatedUtcDateTime: Date;
  // serialCommands: SerialCommand[];
  // measurementDefinitions: MeasurementDefinition[][];
  modbusTypeConfiguration: ModbusTypeConfiguration | null;
  nmeaTypeConfiguration: NmeaTypeConfiguration | null;
};

export type NmeaWriteModel = {
  serialCommunicationParameters: SerialCommunicationParameters;
  nmeaSubscriptions: NmeaSubscription[];
};

export interface NmeaTypeConfiguration extends NmeaWriteModel {
  configurationId: ComProtocolConfigurationHeader;
}

export type MeasurementDefinition = {
  description: string | null;
  /**
   * Number of decimals to round to visually. Null means use default.
   */
  decimals: number | null;
  /**
   * Ex: m³/h, m³, °C
   */
  unit: string;
  measurementType: string;
  /**
   * If a virtual sensor, then this is the relation between the actual sensors and virtual sensor function
   * TODO: Maybe rename compositeSensor to virtualSensor in backend
   */
  // compositeSensorMapping: VirtualSensorMappingDefinition | null;
};

export type ModbusTypeConfiguration = {
  configurationId: ComProtocolConfigurationHeader;
  /**
   * Defined as possible undefined to make it quick to use in tests. Is always defined in backend
   */
  serverId?: number;
  /**
   * Defined as possible undefined to make it quick to use in tests. Is always defined in backend
   */
  serialCommunicationParameters?: SerialCommunicationParameters;
  /**
   * Defined as possible undefined to make it quick to use in tests. Is always defined in backend
   */
  modbusReadParameters?: ModbusReadParameters;
  /**
   * Defined as possible undefined to make it quick to use in tests. Is always defined in backend
   */
  slices?: ModbusDataSlice[];
};

export type ComProtocolConfigurationHeader = {
  name: string;
  id: number;
  revision: number;
};

export type ModbusDataSlice = {
  offset: number;
  length: number;
};

export type SensorData = {
  /**
   * Will be null onprem
   */
  siteName?: string | null;
  /**
   * Will be null onprem
   */
  siteID?: string;
  tagName: string;
  unit: string | null;
  measurementType: string;
  /**
   * Number of decimals to use when presenting data
   */
  decimals: number | null;
  /**
   * Description for this measurementDefintion. Etc Temperature, Total, Rate, Humidity
   */
  measurementDefinitionDescription: string | null;
  measurements: Measurement[];
  /**
   * Only implemented in cloud
   */
  sensorTypeName?: string | null;
};

export type Measurement = {
  timestamp: Date | string; // It is a date transferred as a string -> Could be parsed to date if needed
  value: number;
};
