import { highestSeverityInIssues } from "@/helpers/utils";
import { Issue, Severity } from "scanreach-frontend-components/src/types/Issue.type";

export function issuesToSeverityBackgroundColor(issues: Issue[]) {
  return severityToBackgroundColor(highestSeverityInIssues(issues));
}
export function severityToBackgroundColor(severity: Severity | null): string | undefined {
  switch (severity) {
    case Severity.DISTRESS_ALARM:
      return "var(--color-status-alarm)";
    case Severity.SEVERE_SYSTEM_ALARM:
      return "var(--color-status-severe)";
    case Severity.SYSTEM_ALARM:
      return "var(--color-status-warning)";
    case Severity.SYSTEM_ALERT:
      return "var(--color-status-alert)";
    default:
      // Should never happen since we have all severities covered
      return undefined;
  }
}
