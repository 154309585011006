var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"table-header",style:({
    display: _vm.grid ? 'grid' : 'flex',
    gridTemplateColumns: _vm.grid ? _vm.grid : undefined,
    paddingLeft: _vm.paddingLeft ? _vm.paddingLeft : '16px',
  })},_vm._l((_vm.headers),function(header,index){return _c('div',{key:index,staticClass:"header",style:(header.style),on:{"click":(event) => {
        if (event.target.tagName.toLowerCase() !== 'svg') {
          header.property !== '' ? _vm.$emit('sortBy', header.property) : '';
        }
      }}},[_c('div',{staticClass:"header-content"},[(header.icon)?_c('div',[_c(header.icon,{tag:"component",attrs:{"size":"16","iconColor":"var(--color-pop-03)","small":"","checked":_vm.allSelected,"partlyChecked":_vm.partlySelected},on:{"input":(val) => _vm.$emit('toggleCheckboxes', val)}})],1):_vm._e(),(header.title)?_c('div',{staticClass:"header-text"},[_vm._v(" "+_vm._s(header.title)+" ")]):_vm._e(),(_vm.sortProperty !== '' && _vm.sortProperty === header.property)?_c('span',{staticClass:"search-arrow"},[_c('SortArrowIcon',{attrs:{"iconColor":"var(--color-pop-03)","rotate":_vm.sortDir === _vm.SortDir.DESC ? '180' : '0'}})],1):_vm._e(),(header.tooltip)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          defaultTrigger: _vm.isMobile || _vm.isTablet ? 'click' : 'hover',
          content: header.tooltip,
          placement: 'bottom-center',
        }),expression:"{\n          defaultTrigger: isMobile || isTablet ? 'click' : 'hover',\n          content: header.tooltip,\n          placement: 'bottom-center',\n        }"}],staticClass:"header-info"},[_c('QuestionMarkIcon',{attrs:{"size":"14","iconColor":"var(--color-pop-03)"}})],1):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }