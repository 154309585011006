import { environment } from "../../environments/environment";
import { User, Guid } from "@/typedef";
import customFetch from "@/helpers/customFetch";

const apiAddress = environment.apiAddress;

/**
 * Get all users
 * @returns {Promise<User[]>}
 */
export function getUsers(): Promise<User[]> {
  return new Promise((resolve, reject) => {
    customFetch(apiAddress + "/identity", {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const bodyText = await res.text();
          throw new Error(bodyText);
        }
      })
      .then((users: User[]) => {
        resolve(users);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Get user by id
 * @returns {Promise<User>}
 */
export function getUserById(userId: Guid): Promise<User> {
  return new Promise((resolve, reject) => {
    customFetch(apiAddress + "/identity/" + userId, {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const bodyText = await res.text();
          throw new Error(bodyText);
        }
      })
      .then((user: User) => {
        resolve(user);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Update user by id
 * @param {User} user
 * @returns {Promise<User>}
 */
export function updateUserById(user: User): Promise<User> {
  return new Promise((resolve, reject) => {
    customFetch(apiAddress + "/identity/" + user.id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(user),
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const bodyText = await res.text();
          throw new Error(bodyText);
        }
      })
      .then((user: User) => {
        resolve(user);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Reset password on user by id
 * @param {Guid} userId
 * @returns {Promise<string>}
 */
export function resetUserPassword(userId: string): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    customFetch(apiAddress + "/identity/" + userId + "/resetPassword", {
      method: "POST",
      credentials: "include",
    })
      .then(async (res) => {
        if (res.ok) {
          return res.text();
        } else {
          const bodyText = await res.text();
          throw new Error(bodyText);
        }
      })
      .then((password: undefined | string) => {
        resolve(password);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Add a new user
 * @param {User} user
 * @returns {Promise<User>}
 */
export function addUser(user: User): Promise<User> {
  return new Promise((resolve, reject) => {
    customFetch(apiAddress + "/identity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(user),
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const bodyText = await res.text();
          throw new Error(bodyText);
        }
      })
      .then((user: User) => {
        resolve(user);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Delete an user
 * @param {Guid} userId
 */
export function deleteUser(userId: Guid): Promise<void> {
  return new Promise((resolve, reject) => {
    customFetch(apiAddress + `/identity/${userId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(async (res) => {
        if (res.ok) {
          resolve();
        } else {
          const bodyText = await res.text();
          throw new Error(bodyText);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Get all user roles
 * @returns {Promise<string[]>}
 */
export function getUserRoles(): Promise<string[]> {
  return new Promise((resolve, reject) => {
    customFetch(apiAddress + "/identity/userRoles", {
      method: "GET",
      credentials: "include",
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const bodyText = await res.text();
          throw new Error(bodyText);
        }
      })
      .then((userRoles: string[]) => {
        resolve(userRoles);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Full list of possible IdentityCore Error messages
 * https://github.com/dotnet/aspnetcore/blob/bfec2c14be1e65f7dd361a43950d4c848ad0cd35/src/Identity/Extensions.Core/src/IdentityErrorDescriber.cs
 */
export enum UserServiceAPIError {
  DuplicateUserName = "DuplicateUserName",
  DuplicateEmail = "DuplicateEmail",
  PasswordTooShort = "PasswordTooShort",
  // ^^^^ I think only the three abow is applicable
  UserNotInRole = "UserNotInRole",
  InvalidRoleName = "InvalidRoleName",
  InvalidUserName = "InvalidUserName",
  InvalidEmail = "InvalidEmail",
  DuplicateRoleName = "DuplicateRoleName",
  PasswordMismatch = "PasswordMismatch",
  ConcurrencyFailure = "ConcurrencyFailure",
  InvalidToken = "InvalidToken",
  UserAlreadyInRole = "UserAlreadyInRole",
  RecoveryCodeRedemptionFailed = "RecoveryCodeRedemptionFailed",
  LoginAlreadyAssociated = "LoginAlreadyAssociated",
  UserAlreadyHasPassword = "UserAlreadyHasPassword",
  UserLockoutNotEnabled = "UserLockoutNotEnabled",
  PasswordRequiresUniqueChars = "PasswordRequiresUniqueChars",
  PasswordRequiresNonAlphanumeric = "PasswordRequiresNonAlphanumeric",
  PasswordRequiresDigit = "PasswordRequiresDigit",
  PasswordRequiresLower = "PasswordRequiresLower",
  PasswordRequiresUpper = "PasswordRequiresUpper",
}
