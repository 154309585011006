import { render, staticRenderFns } from "./SupportModal.vue?vue&type=template&id=3ddf9eca&scoped=true&"
import script from "./SupportModal.vue?vue&type=script&lang=ts&"
export * from "./SupportModal.vue?vue&type=script&lang=ts&"
import style0 from "./SupportModal.vue?vue&type=style&index=0&id=3ddf9eca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ddf9eca",
  null
  
)

export default component.exports