import { environment } from "../../environments/environment";
import customFetch from "@/helpers/customFetch";
import { CustomFetchOptions, Guid } from "@/typedef";
import { IssueType, Issue } from "scanreach-frontend-components/src/types/Issue.type";
import queryToParams from "scanreach-frontend-components/src/utils/queryToParams";

const apiAddress = environment.apiAddress;

interface IssueFetchOptions extends CustomFetchOptions {
  query?: IssueQuery;
}
interface IssueQuery {
  Id?: Guid;
  IssueType?: IssueType;
  Acknowledged?: boolean;
  Resolved?: boolean;
  ActivatedAfterUtc?: string;
  Page?: number;
  Limit?: number;
}

/**
 * Get all issues
 */
export function fetchIssues({ showWarning = false, query = undefined }: IssueFetchOptions = {}): Promise<
  Issue[]
> {
  if (!query) {
    query = {
      Resolved: false,
      Limit: 1000,
    };
  }
  return customFetch(
    `${apiAddress}/issues${queryToParams(query as Record<string, string>)}`,
    {
      method: "GET",
      credentials: "include",
    },
    showWarning,
  ).then((response) => {
    return response.json();
  });
}

/**
 * Resolve issue by id
 */
export function resolveIssueById(issueId: string): Promise<void> {
  return new Promise((resolve, reject) => {
    return customFetch(`${apiAddress}/issues/${issueId}/resolve`, {
      method: "PUT",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          resolve();
        } else {
          reject("Error statuscode: " + res.status);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Resolve issue by id
 */
export function acknowledgeIssueById(issueId: string): Promise<void> {
  return new Promise((resolve, reject) => {
    return customFetch(`${apiAddress}/issues/${issueId}/acknowledge`, {
      method: "PUT",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          resolve();
        } else {
          reject("Error statuscode: " + res.status);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
