import { ApiEvent, Guid, PersonActionName } from "@/typedef";
import { ApiPersonRole, DashboardPerson } from "@/types/personTypes";
import * as personService from "@/helpers/personService";
import Vue from "vue";
import { isAlertOnActiveHardware } from "@/helpers/hardwareHelpers";

type People = { [id: string]: DashboardPerson };
type PersonModuleState = {
  people: People;
  personRoles: ApiPersonRole[];
  wearableMacToPersonId: Map<string, string>;
  ready: boolean;
};
const initialState: PersonModuleState = {
  people: {},
  wearableMacToPersonId: new Map(), // Used for faster wearable to person lookup
  personRoles: [],
  ready: false,
};

export const personModule = {
  state: () => initialState,
  mutations: {
    SET_PERSON_ROLES(state: PersonModuleState, personRoles: ApiPersonRole[]) {
      state.personRoles = personRoles;
    },
    SET_WEARABLE_MAC_TO_PERSON_ID(state: PersonModuleState, wearableMacToPersonId: Map<string, string>) {
      state.wearableMacToPersonId = wearableMacToPersonId;
    },
    SET_PEOPLE(state: PersonModuleState, people: People) {
      state.people = people;

      if (!state.ready) {
        state.ready = true;
      }
    },
    UPDATE_PERSON(state: PersonModuleState, person: DashboardPerson) {
      const original: DashboardPerson | undefined = state.people[person.id];

      person.wearableMac = person.wearable?.mac;

      if (original?.wearableMac != person.wearableMac) {
        if (original?.wearableMac) {
          state.wearableMacToPersonId.delete(original.wearableMac);
        }
        if (person.wearableMac) {
          state.wearableMacToPersonId.set(person.wearableMac, person.id);
        }
      }
      Vue.set(state.people, person.id, {
        ...person,
        unresolvedIssueIds: Object.prototype.hasOwnProperty.call(person, "unresolvedIssueIds")
          ? person.unresolvedIssueIds
          : original?.unresolvedIssueIds,
      });
    },
    DELETE_PERSON(state: PersonModuleState, personId: Guid) {
      const original = state.people[personId];

      if (original?.wearableMac) {
        state.wearableMacToPersonId.delete(original.wearableMac);
      }

      Vue.delete(state.people, personId);
    },
  },
  actions: {
    /**
     * Update a person
     * @param {*} param0
     * @param {{person: DashboardPerson, persistToBackend?: boolean}} param1
     */
    async updatePerson(
      { commit, getters }: { commit: any; getters: any },
      { person, persistToBackend = true }: { person: DashboardPerson; persistToBackend?: boolean },
    ) {
      if (person) {
        try {
          if (persistToBackend) {
            await personService.putPerson(person);
          }

          commit("UPDATE_PERSON", person);
          // FIXME: This do not work, events do not exist anymore
          getters.getUnresolvedEventsOnPerson(person.id).forEach((event: ApiEvent) => {
            Vue.set(event, "isConnectedNodeOrPersonActive", isAlertOnActiveHardware(event, person));
            commit("UPDATE_EVENT", event);
          });
          return { ok: true };
        } catch (err) {
          console.error("error saving person: ", err);
          return { ok: false, error: err };
        }
      }
    },
    /**
     * Delete a person
     */
    async deletePerson(
      { commit }: { commit: any },
      { personId, persistToBackend = true }: { personId: Guid; persistToBackend?: boolean },
    ) {
      try {
        if (persistToBackend) {
          await personService.deletePerson(personId);
        }

        commit("DELETE_PERSON", personId);
        return { ok: true };
      } catch (err) {
        console.error("error deleting person: ", err);
        return { ok: false, error: err };
      }
    },
    async performPeopleBoardingAction(
      { commit }: { commit: any },
      {
        people,
        actionType,
        location,
      }: { people: DashboardPerson[]; actionType: PersonActionName; location?: string | null },
    ) {
      await Promise.all(
        people.map(async (person) => {
          try {
            const updatedPerson = await personService.performPersonAction(person.id, actionType, location);
            await commit("UPDATE_PERSON", updatedPerson);
          } catch (error) {
            console.error(error);
            throw error;
          }
        }),
      );
    },
  },
  getters: {
    getPersonById: (state: PersonModuleState) => (id: string) => {
      return state.people?.[id];
    },
    getPersonByWearableMac: (state: PersonModuleState) => (mac: string) => {
      const personId = state.wearableMacToPersonId?.get(mac);
      if (personId) {
        return state.people?.[personId];
      } else {
        return null;
      }
    },
    people(state: PersonModuleState): DashboardPerson[] {
      if (state.people) {
        return Object.values(state.people);
      } else return [];
    },
    // FIXME: Remove this function, events do not exist anymore!
    getUnresolvedEventsOnPerson:
      (state: PersonModuleState, getters: any, rootState: any, rootGetters: any) => (personId: string) => {
        const wearablemac = getters.getPersonById(personId)?.wearableMac;
        return rootGetters.getUnresolvedEventsOnWearable(wearablemac);
      },
  },
};

export default personModule;
