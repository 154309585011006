<template functional>
  <svg
    :width="props.size"
    :height="props.size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.47,26.53l-3,2.25a.71.71,0,0,1-.83,0L8.18,23.32A4.14,4.14,0,0,1,6.5,20V14.09l9.5-2,9.5,2V20"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <polyline
      points="23.5 14.09 23.5 7.08 16 5.08 8.5 7.08 8.5 14.09"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-miterlimit="10"
    />
    <line
      x1="16"
      y1="3.09"
      x2="16"
      y2="5.09"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <line
      x1="24.5"
      y1="23.25"
      x2="24.5"
      y2="28.75"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <line
      x1="28.5"
      y1="23.25"
      x2="28.5"
      y2="28.75"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: String,
    iconColor: String,
  },
};
</script>

<style></style>
