import { environment } from "../../environments/environment";
import { LoginModel, ChangePasswordModel, User } from "@/typedef";
import customFetch from "@/helpers/customFetch";

const apiAddress = environment.apiAddress;

/**
 * Login
 * @param {LoginModel} user
 * @return {Promise<User>}
 */
export function login(user: LoginModel) {
  return new Promise<User>((resolve, reject) => {
    fetch(apiAddress + "/auth/login", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const bodyText = await res.text();
          throw new Error(bodyText);
        }
      })
      .then((user: User) => {
        resolve(user);
      })
      .catch((err: Error) => {
        reject(err);
      });
  });
}

/**
 * Logout
 * @return {Promise<>}
 */
export function logout() {
  return fetch(apiAddress + "/auth/logout", {
    method: "POST",
    credentials: "include",
  });
}

/**
 * Logout
 * @return {Promise<>}
 */
export function isLoggedIn() {
  return fetch(apiAddress + "/auth/isLoggedIn", {
    credentials: "include",
  }).then((res) => {
    if (res.status == 401) {
      // Not authorized
      throw new Error("401");
    } else if (res.status == 403) {
      // Cookie expired
      throw new Error("403");
    }
  });
}

/**
 * Change password
 * @param {ChangePasswordModel} oldNewPassword
 * @return {Promise<>}
 */
export function changePassword(oldNewPassword: ChangePasswordModel) {
  return new Promise((resolve, reject) => {
    customFetch(apiAddress + "/auth/changePassword", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(oldNewPassword),
    })
      .then(async (res) => {
        if (res.ok) {
          resolve(res.text());
        } else {
          const bodyText = await res.text();
          throw new Error(bodyText);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// TODO: Remove when done
(window as any).login = login;
(window as any).logout = logout;
(window as any).changePassword = changePassword;
