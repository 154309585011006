<template>
  <div class="map-container" ref="mapContainer">
    <LoadingAnimation v-show="loading" />
    <LMap
      v-if="mapSettings"
      ref="map"
      :min-zoom="mapSettings.minZoom"
      :max-zoom="mapSettings.maxZoom"
      :zoom="mapSettings.zoom"
      :center="mapSettings.center"
      :crs="mapSettings.crs"
      :options="mapSettings.mapOptions"
      :style="mapStyle"
      @ready="handleReady"
    >
      <LTileLayer :url="mapSettings.url" :bounds="mapSettings.bounds" :tileSize="TILE_SIZE" :tms="true" />
      <slot></slot>
      <LControlZoom :position="zoomControlPosition"></LControlZoom>
    </LMap>
  </div>
</template>

<script>
import { CRS } from "leaflet";
import { LMap, LTileLayer, LControlZoom } from "vue2-leaflet";
import { getMaxBounds, getImageBounds } from "../../helpers/desktopMapBoundsCalculators";
import { environment } from "../../../environments/environment";
import { mapState, mapGetters } from "vuex";
import LoadingAnimation from "./LoadingAnimation.vue";
import { isThisNodeUnlocatedButActive, hasThisNodeValidMarkerPos } from "../../compositions/useDesktopMap";
import { RouteName } from "../../router/routerDesktop";
import useSreenSize from "../../compositions/useScreenSize";

export default {
  name: "DesktopMap",
  components: {
    LMap,
    LTileLayer,
    LControlZoom,
    LoadingAnimation,
  },

  props: {
    mustering: Boolean,
    currentModule: String,
    selectedNode: Object,
    height: String,
    zoomControlPosition: {
      type: String,
      default: "bottomleft",
      validator(value) {
        return ["topleft", "topright", "bottomright", "bottomleft"].includes(value);
      },
    },
  },

  data() {
    const { isTablet } = useSreenSize();
    return {
      bounds: [
        [0, 0],
        [0, 0],
      ],
      TILE_SIZE: 512,
      isTablet,
    };
  },

  computed: {
    ...mapState(["siteConfig"]),
    ...mapGetters(["appTheme"]),
    nodes() {
      return this.siteConfig?.nodes;
    },
    loading() {
      return !(this.siteConfig && this.siteConfig.view && this.siteConfig.baseLayers && this.mapSettings);
    },

    imageDim() {
      if (this.siteConfig && this.siteConfig.view) {
        return this.siteConfig.view;
      } else {
        return null;
      }
    },

    GAUrl() {
      if (this.siteConfig && this.siteConfig.baseLayers) {
        const layer = this.siteConfig.baseLayers.find((el) => el.name === this.appTheme);
        if (layer) {
          return `${environment.apiAddress}/siteconfig/layer/tile/${layer.imageReference}/{z}/{y}/{x}.png`;
        } else return "";
      } else return "";
    },

    mapSettings() {
      if (this.imageDim) {
        return {
          mapOptions: {
            attributionControl: false,
            touchZoom: true,
            zoomSnap: 0.5,
            zoomControl: false,
          },
          crs: CRS.Simple,
          zoom: 2,
          bounds: this.bounds,
          minZoom: -3,
          maxZoom: this.getMaxZoom(this.imageDim.width, this.imageDim.height, this.TILE_SIZE),
          center: [this.imageDim.width / 2, this.imageDim.height / 2],
          url: this.GAUrl,
        };
      }

      return null;
    },

    mapStyle() {
      return {
        height: this.height ?? "100%",
        width: "100%",
        opacity: this.loading ? 0 : 1,
      };
    },
  },

  methods: {
    /**
     * Calculate what max zoomlevel the image tiler creates
     */
    getMaxZoom(width, height, tileSize = 512) {
      const maxWidthHeight = Math.max(width, height);
      if (maxWidthHeight == 0) {
        return 3; // Just return something
      }
      return Math.ceil(Math.log2(maxWidthHeight / tileSize) / Math.log2(2));
    },

    handleNodeClick(node, additionalParam, zoomToNode = true) {
      if (node) {
        if (!this.selectedNode || this.selectedNode.mac !== node.mac) {
          let url =
            this.$route.name === RouteName.NodeDebugging
              ? `/${this.currentModule}/debug/${node.mac}`
              : `/${this.currentModule}/node/${node.mac}`;
          if (additionalParam) {
            url = `${url}/${additionalParam}`;
          }
          if (
            zoomToNode &&
            (!node.unlocated || (isThisNodeUnlocatedButActive(node) && hasThisNodeValidMarkerPos(node)))
          ) {
            this.zoomToNode(node);
          }
          this.$router.push(url).catch(() => {
            // There was an error pushing route. most probably due to NavigationDuplicated error
            // More info: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
          });
          /* If we are selecting a wearable card to be expanded (safety view -> node details) */
        } else if (additionalParam) {
          additionalParam = additionalParam === "collapseWearableId" ? "" : additionalParam;
          const url = `/${this.currentModule}/node/${node.mac}/${additionalParam}`;
          if (url !== this.$route.path) {
            this.$router.push(url).catch(() => {
              // There was an error pushing route. most probably due to NavigationDuplicated error
              // More info: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
            });
          }
        } else if (this.selectedNode.mac === node.mac && !additionalParam) {
          const url = `/${this.currentModule}`;
          if (url !== this.$route.path) {
            this.$router.push(url).catch(() => {
              // There was an error pushing route. most probably due to NavigationDuplicated error
              // More info: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
            });
          }
        }
      } else {
        const url = `/${this.currentModule}`;
        this.$router.push(url).catch(() => {
          // There was an error pushing route. most probably due to NavigationDuplicated error
          // More info: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
        });
        // Zoom out to fit bounds
        this.fitToBounds();
      }
    },

    zoomToNode(node, zoomLevel) {
      if (!zoomLevel) {
        zoomLevel = this.$refs.map.mapObject.getMaxZoom();
      }

      // Allows SystemStatusDetails to say "I want to zoom to this node, but I don't have the markerPos yet"
      let markerPos = node.markerPos;
      if (!markerPos && node.x !== null && node.y !== null) {
        markerPos = this.$refs.map.mapObject.unproject(
          [node.x, node.y],
          this.$refs.map.mapObject.getMaxZoom(),
        );
      }

      this.$refs.map.mapObject.setView(markerPos, zoomLevel);
    },

    fitToBounds() {
      this.$refs.map?.mapObject.flyToBounds(this.bounds, {
        animate: true,
        duration: 0.2,
      });
    },

    handleReady() {
      if (this.siteConfig && this.siteConfig.nodes && this.mapSettings && this.$refs.map) {
        this.bounds = getMaxBounds(getImageBounds(this.$refs.map.mapObject, this.imageDim), this.isTablet);

        this.$refs.map.mapObject.setMaxBounds(this.bounds);
        this.fitToBounds();

        this.$emit("setMapObject", this.$refs.map.mapObject);

        /**
         * Change padding of renderer to always render things even though it is out of screen.
         * Performance impact should be very low as we have only up to 100 nodes with up to 1000 polyLines.
         * Some timeout is needed as the renderer is not completely ready even though map is ready
         */
        setTimeout(() => {
          if (this.$refs.map.mapObject && this.$refs.map.mapObject._renderer) {
            this.$refs.map.mapObject._renderer.options.padding = 100;
          }
        }, 100);
      }
    },
  },

  watch: {
    selectedNode() {
      if (!this.selectedNode) {
        this.fitToBounds();
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    this.resetFilters();
    next();
  },
};
</script>

<style scoped>
.map-container {
  height: 100%;
}

.list-wrapper {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 340px;
  z-index: 400;
}
</style>
