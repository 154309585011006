import { ApiEvent, PersonActionName } from "@/typedef";
import { DashboardPerson } from "@/types/personTypes";
import { ApiNode, NodeOperationMode } from "scanreach-frontend-components/src/types/ApiNode.type";
import { Issue } from "scanreach-frontend-components/src/types/Issue.type";
import { isNodePlaced } from "scanreach-frontend-components/src/utils/hardwareHelpers";

// Some of the functions was moved to the frontend-components library at 2024-10-17

/**
 * Check if a person is active or not
 * @param person
 */
export function isPersonActive(person?: DashboardPerson | null) {
  return person?.lastPersonAction?.action == PersonActionName.embark;
}

/**
 * @Deprecated
 * Check if a alert is connected to active hardware
 * @param alert
 */
export function isAlertOnActiveHardware(alert: ApiEvent, person?: DashboardPerson): boolean | null {
  if (alert.tag) {
    return isPersonActive(person);
  }
  if (alert.node) {
    return isNodePlaced(alert.node);
  }
  return null;
}

/**
 * Check if a issue is connected to active hardware
 */
export function isIssueOnActivePersonOrNode(
  issue: Issue,
  person?: DashboardPerson,
  node?: ApiNode,
): boolean | null {
  if (issue.wearable) {
    if (person) {
      return isPersonActive(person);
    } else {
      return false;
    }
  }
  if (node) {
    return isNodePlaced(node) || node?.operationMode == NodeOperationMode.GANGWAY; // issues on gangway nodes should show although never placed in map
  }
  if (issue.personId) {
    return true; // Currently all issues connected to only person should show
  }
  return null; // return null when issue is not connected to node or person
}
