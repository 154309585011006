import { debounce } from "lodash";
import { reactive, ref, toRefs } from "vue";

const TABLET_WIDTH_THRESHOLD = 900;
const MOBILE_WIDTH_THRESHOLD = 470;

const screenSizeState = reactive({
  width: window.innerWidth,
  height: window.innerHeight,
  isTablet: window.innerWidth < TABLET_WIDTH_THRESHOLD,
  isMobile: window.innerWidth < MOBILE_WIDTH_THRESHOLD,
});
const isInitialized = ref(false);
const resizeObserver = ref<ResizeObserver | null>(null);

export default function useScreenSize() {
  if (!isInitialized.value) {
    isInitialized.value = true;
    resizeObserver.value = new ResizeObserver(
      debounce(() => {
        screenSizeState.width = window.innerWidth;
        screenSizeState.height = window.innerHeight;
        screenSizeState.isTablet = window.innerWidth < TABLET_WIDTH_THRESHOLD;
        screenSizeState.isMobile = window.innerWidth < MOBILE_WIDTH_THRESHOLD;
      }, 300),
    );

    resizeObserver.value.observe(document.body);
  }

  return { ...toRefs(screenSizeState), TABLET_WIDTH_THRESHOLD, MOBILE_WIDTH_THRESHOLD };
}
