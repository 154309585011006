// Responsible for fetching and listening for snapshot data
// Snapshots contain number of mustered, not mustered people etc.

import { environment } from "../../environments/environment";
import { SignalRSocketHandler } from "@/helpers/signalRSocketHandler";
import { Snapshot } from "@/typedef";
import { ref } from "vue";
import { fetchSnapshot } from "@/helpers/apiService";
import { useStore } from "../store/desktopStore";

let signalRClient: SignalRSocketHandler;

const snapshot = ref<Snapshot>({
  nodes: {},
  peopleCount: {
    total: 0,
    missing: 0,
    mustered: 0,
    registered: 0,
    musteredByRole: {},
    notMusteredByRole: {},
    musteredPeopleIds: [],
    notMusteredPeopleIds: [],
    registeredPeopleIds: [],
  },
});
const isInitialized = ref(false);

export default function () {
  if (!isInitialized.value) {
    isInitialized.value = true;
    signalRClient = new SignalRSocketHandler(environment.snapshotSignalRSocket);
    (() => fetchSnapshotFromApi())();
    signalRClient.subscribe("statuschange", (status) => {
      if (status.connected) {
        initializeSignalRConnection();
      }
    });
  }
  return {
    snapshot,
  };
}

function initializeSignalRConnection() {
  signalRClient.on("ReceiveSnapshot", (msg) => updateSnapshot(msg));
}

const store = useStore();
function updateSnapshot(snapshotFromApi: Snapshot) {
  snapshot.value.nodes = snapshotFromApi.nodes;
  snapshot.value.peopleCount = snapshotFromApi.peopleCount;
  store.dispatch("setNodesPeopleCount", snapshotFromApi.nodes, { root: true }); // Populates peopleCount on each node, might be more efficient to fetch on demand when needed instead
}

async function fetchSnapshotFromApi() {
  try {
    const snapshotFromApi = await fetchSnapshot();
    updateSnapshot(snapshotFromApi);
  } catch (error) {
    console.error("Failed to fetch snapshot from api", error);
  }
}
