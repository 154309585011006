<template>
  <div v-if="globalError" class="global-error-bar">
    <SystemDisconnectedIcon size="16" iconColor="var(--color-ui-01)" />
    <p>{{ globalErrorMessage }}</p>
    <p class="right-info">Last updated: {{ timeSinceDateWithAgoSuffix }}</p>
  </div>
</template>

<script land="ts">
import SystemDisconnectedIcon from "scanreach-frontend-components/src/components/icons/SystemDisconnectedIcon.vue";
import useReactiveTimeSince from "scanreach-frontend-components/src/compositions/useReactiveTimeSince";
import { computed, defineComponent } from "vue";
import { useStore } from "../../store/desktopStore";

export default defineComponent({
  name: "GlobalError",

  components: {
    SystemDisconnectedIcon,
  },
  setup() {
    const store = useStore();
    const globalError = computed(() => store.state.globalError);
    const globalErrorMessage = computed(() => store.state.globalErrorMessage);
    const globalErrorTime = computed(() => (globalError.value ? new Date() : undefined));

    const { timeSinceDateWithAgoSuffix } = useReactiveTimeSince(globalErrorTime);

    return {
      SystemDisconnectedIcon,
      globalError,
      globalErrorMessage,
      timeSinceDateWithAgoSuffix,
    };
  },
});
</script>

<style scoped>
.global-error-bar {
  background-color: var(--color-status-warning);
  width: 100%;
  height: 32px;
  padding: 0 16px 0 16px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 16px;

  color: var(--color-ui-01);
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.5px;
}

.right-info {
  margin-left: auto;
}
</style>
