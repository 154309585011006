var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.node.markerPos)?_c('l-marker',{attrs:{"lat-lng":_vm.node.markerPos},on:{"click":function($event){return _vm.$emit('nodeClick', _vm.node)}}},[_c('l-icon',{attrs:{"icon-anchor":[_vm.iconSize.width / 2, _vm.iconSize.height / 2],"icon-size":[_vm.iconSize.width, _vm.iconSize.height]}},[_c('div',{class:{
        active: _vm.isSelectedNode,
        'musterstation-wrapper': _vm.displayNodeAsMusterstation,
        'map-node-wrapper': !_vm.displayNodeAsMusterstation,
      },style:({
        ..._vm.styleObj,
        'background-color': _vm.isSelectedNode
          ? undefined
          : _vm.issuesToSeverityBackgroundColor(_vm.unresolvedNodeRelatedIssues),
      })},[(_vm.displayNodeAsMusterstation)?_c('muster-icon',{attrs:{"size":"20","iconColor":"var(--color-ui-01)"}}):_vm._e(),((_vm.displayNodeAsMusterstation && !_vm.isNodeMissing) || _vm.count !== 0)?_c('p',[_vm._v(_vm._s(_vm.count))]):_vm._e(),(_vm.wearableIssues && _vm.wearableIssues.length > 0)?_c('div',{staticClass:"issue",style:({ right: _vm.issueRight, 'background-color': _vm.issuesToSeverityBackgroundColor(_vm.wearableIssues) })},[_c('span',[_vm._v("!")])]):_vm._e(),(_vm.distressAlarms.length)?_c('div',{staticClass:"distress",class:{ 'pulse-distress': _vm.isDistressUnacknowledged },style:({ right: _vm.distressRight })},[_c('person-icon',{attrs:{"size":"10","iconColor":"var(--color-base-white)"}}),_c('p',[_vm._v(_vm._s(_vm.distressAlarms.length))])],1):_vm._e(),_c('div',{staticClass:"rescue",class:{ 'rescue-visible': _vm.showRescue && _vm.rescuepersonnelCount > 0 },style:({ right: _vm.distressRight })},[_c('safety-icon',{attrs:{"size":"10","iconColor":"var(--color-ui-01)"}})],1)],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }