<template>
  <router-view></router-view>
</template>

<script>
import loggedInMixin from "@/mixins/loggedIn";

export default {
  mixins: [loggedInMixin],
};
</script>

<style>
input,
textarea,
select,
option,
button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.logomark {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-width: 64px;
  height: 100%;
  border-right: 1px solid var(--color-ui-01);
  border-bottom: 1px solid var(--color-ui-01);
  background-color: transparent;
  cursor: pointer;
}

.map-container {
  margin: auto;
}

/* Leaflet Overrides */
.leaflet-container {
  background: #121c20 !important;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5;
  transition-property: opacity;
  transition-timing-function: ease-in;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
/* Track */
::-webkit-scrollbar-track {
  background-color: var(--color-ui-06);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-pop-03);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-pop-02);
}

/* Styling the leaflet zoom control */
.leaflet-bar a {
  background-color: var(--color-ui-05) !important;
  border-bottom: 1px solid var(--color-ui-06) !important;
  color: var(--color-brand-01) !important;
  font-weight: 100;
}

.leaflet-bar a:hover {
  background-color: var(--color-brand-05) !important;
  color: var(--color-pop-01) !important;
}

/* FAQ STYLING */
.faq-container h1 {
  font-size: 24px;
  font-weight: bold;
}
.faq-container {
  font-weight: 400;
  font-style: normal;
  line-height: 27px;
}

hr.section-separator {
  border-color: var(--color-ui-06);
  border-style: solid;
  border-width: 1px 0 1px 0;
  margin: 24px 0 24px 0;
}
</style>
