import { computed, onBeforeUnmount, ref } from "vue";

/**
 * Composable that starts a stopwatch and returns a 'formattedTimeElapsed' string. Time elapsed is updated every 0.5 seconds.
 * @param initialMillisecondsElapsed Milliseconds the stopwatch will start at
 * @param timeLimit Threshold in milliseconds within which time elapsed is to be considered as expected
 * @returns
 */
export default function (initialMillisecondsElapsed = 0, timeLimit: number | null = null) {
  const millisecondsElapsedSinceLastObserved = ref(initialMillisecondsElapsed);

  const interval = setInterval(() => {
    millisecondsElapsedSinceLastObserved.value += 500;
  }, 500);

  onBeforeUnmount(() => {
    clearInterval(interval);
  });

  const formattedTimeElapsed = computed(() => {
    const totalseconds = millisecondsElapsedSinceLastObserved.value / 1000;

    const hours = Math.floor(totalseconds / (60 * 60));
    const minutes = Math.floor((totalseconds % (60 * 60)) / 60);
    const seconds = Math.round(totalseconds % 60) + "s";
    const hm = (hours > 0 ? hours + "h " : "") + (hours + minutes > 0 ? minutes + "m " : "");
    if (hours > 0 || minutes > 10) {
      return hm;
    } else {
      return hm + seconds;
    }
  });

  function reset(initialMillisecondsElapsed = 0) {
    millisecondsElapsedSinceLastObserved.value = initialMillisecondsElapsed;
  }

  const isTimeElapsedTooMuch = computed(() => {
    if (timeLimit != null) {
      return millisecondsElapsedSinceLastObserved.value > timeLimit;
    }
    return false;
  });

  return { formattedTimeElapsed, isTimeElapsedTooMuch, reset };
}
