<template>
  <div class="status-bar">
    <div class="color-bar" :style="{ 'background-color': color }">
      <div class="color-edge" />
    </div>
    <div class="separator-bar" />
  </div>
</template>
<script>
export default {
  name: "ListItemStatusBar",
  props: {
    color: {
      type: String,
      default: "var(--color-brand-06)",
    },
  },
};
</script>
<style scoped>
div.status-bar {
  display: flex;
  flex-flow: row nowrap;
  width: 5px;
  min-width: 5px;
  max-width: 5px;
  height: 100%;
}
div.status-bar div.color-bar {
  display: flex;
  flex-flow: row-reverse nowrap;
  flex: 0 0 3px;
  height: 100%;
}
div.status-bar div.color-bar div.color-edge {
  flex: 0 0 1px;
  height: 100%;
  background: var(--gradient-skeuomorph-reversed);
  mix-blend-mode: multiply;
  opacity: 0.3;
}
div.status-bar div.separator-bar {
  flex: 0 0 2px;
  height: 100%;
  background: var(--gradient-skeuomorph);
  mix-blend-mode: overlay;
  opacity: 0.8;
}
</style>
