<template functional>
  <svg
    :width="props.size"
    :height="props.size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.79,24.4a2.93,2.93,0,0,1-.14-.9V8.5a3,3,0,0,1,3-3h13a3,3,0,0,1,2.48,1.32"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <path
      d="M25.65,12.25V23.5a3,3,0,0,1-3,3H11.71"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <path
      d="M20.09,1.5H12.21A2,2,0,0,0,10.27,3L9.65,5.5h13L22,3A2,2,0,0,0,20.09,1.5Z"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <path
      d="M9.94,27.67,10.27,29a2,2,0,0,0,1.94,1.51h7.88A2,2,0,0,0,22,29l.62-2.49h-3.8"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <line
      x1="3.5"
      y1="28.46"
      x2="28.5"
      y2="3.46"
      fill="#9b9b9b"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: String,
    iconColor: String,
  },
};
</script>

<style></style>
