<template>
  <s-modal width="390px" @close="$emit('hideModal')" :icon="PersonIcon">
    <template #heading>Reset password</template>
    <template #content>
      <div v-if="!newPasswordResponse">
        <p>Are you sure you want to reset the password for the user: {{ user.userName }}?</p>
      </div>
      <div v-else>
        <h4>The new password is:</h4>
        <code>{{ newPasswordResponse }}</code>
      </div>
    </template>
    <template #buttons>
      <NormalButton tertiary icon formnovalidate @click.native="$emit('hideModal')" type="button">
        <cancelIcon size="16" iconColor="var(--color-brand-01" />
        Cancel
      </NormalButton>
      <NormalButton icon @click.native="resetPassword()">
        <saveIcon size="16" iconColor="var(--color-pop-01)" />
        Confirm
      </NormalButton>
    </template>
  </s-modal>
</template>

<script>
import CancelIcon from "scanreach-frontend-components/src/components/icons/CancelIcon.vue";
import SModal from "scanreach-frontend-components/src/components/SModal.vue";
import NormalButton from "scanreach-frontend-components/src/components/NormalButton.vue";
import SaveIcon from "scanreach-frontend-components/src/components/icons/SaveIcon.vue";
import { resetUserPassword } from "@/helpers/userService";
import PersonIcon from "scanreach-frontend-components/src/components/icons/PersonIcon.vue";

export default {
  name: "ResetPasswordModal",
  components: {
    CancelIcon,
    SModal,
    NormalButton,
    SaveIcon,
  },
  data() {
    return {
      newPasswordResponse: null,
      PersonIcon,
    };
  },
  props: {
    user: Object,
  },
  methods: {
    async resetPassword() {
      try {
        this.newPasswordResponse = await resetUserPassword(this.user.id);
        this.$toaster.show({ message: "Password reset on user" });
        setTimeout(() => {
          this.$emit("hideModal");
        }, 7000);
      } catch (error) {
        this.$toaster.show({ message: "Error: " + error, type: "error" });
      }
    },
  },
};
</script>

<style scoped></style>
