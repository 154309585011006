import Vue from "vue";
import "./noSleep.ts";

import "@/assets/css/fonts.css";
import "@/assets/css/ScanReach-colors.css";

import DesktopApp from "./views/desktop/DesktopApp.vue";
import router from "./router/routerDesktop";
import store from "./store/desktopStore";
import "./registerServiceWorker";
import VTooltip from "v-tooltip";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import PortalVue from "portal-vue";
import Toaster from "./plugins/toaster";
import VueSession from "./CustomVueSession.js";

import "@/helpers/applicationInsightsInstaller";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css"; // Some of the styling are overridden in file below to fit ConnectPOB styling
import "@/assets/css/VSelect-scanreach.css";
import CaretDownIcon from "@/components/icons/CaretDown.vue";
import CloseIcon from "@/components/icons/Close.vue";
vSelect.props.components.default = () => ({
  Deselect: {
    render: (createElement) => createElement(CloseIcon),
  },
  OpenIndicator: {
    render: (createElement) => createElement(CaretDownIcon),
  },
});

Vue.component(vSelect);

Vue.use(PortalVue);
Vue.use(Toaster);
Vue.use(VTooltip);
Vue.use(VueSession, { persist: true });
import "@/assets/css/tooltip.css"; // Toolip and popover styling

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(DesktopApp),
}).$mount("#app");
