import ToasterTest from "./Toaster.vue";

const Toaster = {
  // The install method is all that needs to exist on the plugin object.
  // It takes the global Vue object as well as user-defined options.

  install(Vue) {
    const root = new Vue({
      name: "Toaster",
      data() {
        return {
          isShowing: false,
          type: "success",
          icon: "save",
          message: "",
          duration: 3000,
        };
      },

      methods: {
        show({ message = "", type = "success", icon = "info", duration = 3000 }) {
          this.message = message;
          this.type = type;
          this.icon = icon;
          this.duration = duration;

          this.isShowing = true;
          setTimeout(() => {
            this.reset();
          }, duration);
        },

        reset() {
          this.isShowing = false;
        },
      },

      render: function (createElement) {
        return createElement(ToasterTest, {
          props: {
            message: this.message,
            isShowing: this.isShowing,
            type: this.type,
            icon: this.icon,
          },
        });
      },
    });

    root.$mount(document.body.appendChild(document.createElement("div")));

    Vue.prototype.$toaster = root;
    this.instance = root;
  },
};

export default Toaster;
