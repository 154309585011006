<template functional>
  <svg
    :width="props.size"
    :height="props.size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.2956 15.2363C28.1898 10.3585 24.4319 5.89062 19.5156 5.89062C16.6747 5.89062 14.1324 7.39772 12.7315 9.73051C10.8207 9.11305 8.85589 9.37619 7.23718 10.4905C5.61934 11.6042 4.65313 13.3675 4.57491 15.2962C2.41315 16.3146 0.999985 18.4671 0.999985 20.832C0.999985 21.2371 1.03971 21.6423 1.11799 22.0364C1.68747 24.9034 4.22522 26.9844 7.15233 26.9844H24.7891C28.2137 26.9844 31 24.2244 31 20.832C31 18.4113 29.5313 16.2262 27.2956 15.2363ZM24.7891 25.2266H7.15233C4.74999 25.2266 2.7578 23.2792 2.7578 20.832C2.7578 19.001 3.96114 17.3445 5.75217 16.7103L6.3762 16.4892L6.33606 15.8284C6.24167 14.2757 6.95118 12.8214 8.23397 11.9384C9.59048 11.0045 11.2546 10.94 12.7779 11.6307L13.5696 11.9897L13.9373 11.202C14.9448 9.04332 17.1345 7.64844 19.5156 7.64844C22.908 7.64844 25.668 10.4084 25.668 13.8008C25.668 14.8043 25.4563 15.4118 25.2222 16.2163C25.8776 16.5565 25.9836 16.6195 26.2043 16.6951C28.0213 17.3175 29.2422 18.98 29.2422 20.832C29.2422 23.2552 27.2445 25.2266 24.7891 25.2266Z"
      :fill="props.iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: String,
    iconColor: String,
  },
};
</script>

<style></style>
