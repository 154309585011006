import { render, staticRenderFns } from "./GlobalError.vue?vue&type=template&id=3abf615b&scoped=true&"
import script from "./GlobalError.vue?vue&type=script&land=ts&lang=js&"
export * from "./GlobalError.vue?vue&type=script&land=ts&lang=js&"
import style0 from "./GlobalError.vue?vue&type=style&index=0&id=3abf615b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3abf615b",
  null
  
)

export default component.exports