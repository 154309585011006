import { ApiNode } from "scanreach-frontend-components/src/types/ApiNode.type";
import { Guid } from "scanreach-frontend-components/src/types/Issue.type";
import { ApiPerson, ApiPersonAction } from "./personTypes";

type GangwaySharedProps = {
  label: string;
  rssiLimit: number | null;
  x: number | null;
  y: number | null;
  active: boolean;
  approvalMode: boolean;
};

export type Gangway = GangwaySharedProps & {
  id: Guid;
  onboardingNodes: ApiNode[];
  dropoffNodes: ApiNode[];
  deletedUtcDateTime?: Date | null;
  gangwayConfigurationStatus: GangwayConfigurationStatus;
};

export enum GangwayConfigurationStatus {
  PENDING = "pending",
  /**
   * Currently not in use anywhere
   */
  ERROR = "error",
  OK = "ok",
}

export type GangwayWriteModel = GangwaySharedProps & {
  id: Guid | null;
  onboardingNodeMacs: string[];
  dropoffNodeMacs: string[];
};

export type GangwayAction = {
  id: Guid;
  personId: Guid | null;
  location?: string | null;
  direction: GangwayDirection;
  state: GangwayEventState;
  dateTimeUtc: Date;
  deletedUtcDateTime?: Date | null;
  gangway?: Gangway | null;
};

export type GangwayActionWithPerson = GangwayAction & {
  person: ApiPerson | null;
};

export enum GangwayDirection {
  OnBoard = "onBoard",
  OffBoard = "offBoard",
}

export enum GangwayEventState {
  Unhandled = "unhandled",
  Approved = "approved",
  Rejected = "rejected",
}

export type PersonActionWithPerson = ApiPersonAction & {
  person: ApiPerson | null;
};

export type GangwaylocationWrapper = {
  /** These are the available custom vessel locations */
  gangwayLocations: GangwayLocation[];
  /** These locations contains geolocation and is not editable onprem */
  spatialLocations: SpatialDataObjectGroup[];
};

export type GangwayLocation = {
  id: Guid;
  label: string;
  deletedUtcDateTime?: Date | null;
};

export type GangwayLocationWithNewProp = GangwayLocation & { new?: boolean; combinedLabel?: string };

export type VesselLocation = {
  id: Guid; // Not the same as Gangwaylocation
  label: string; // Example: "Arcadis Ost - A64"
  dateTimeUtc: Date;
};

export type SpatialDataObjectGroup = {
  id: Guid;
  label: string;
  /**
   * GeoJson string with Polygon
   */
  polygon: null | string;
  spatialDataObjects: SpatialDataObject[];
  /**
   * Will always be true for now as the backend will only return groups that are enabled
   */
  isGroupEnabled: boolean;
};

export type SpatialDataObject = {
  id: Guid;
  /**
   * The id of the SpatialDataObjectGroup this object belongs to
   */
  groupId: Guid;
  label: string;
  /**
   * GeoJson string with Point
   */
  location: string;
  /**
   * Used to determine what the type is and how we should render it
   */
  type: string | null;
};
