// Copied from ScanReach.Cloud.ConnectFleet.Frontend and renamed sensor to node
import { isEqual } from "lodash";
import { computed, ref } from "vue";

/**
 * Identifies what node should be marked as dragable
 */
const idToPlace = ref<string | null>(null);
/**
 * Current location of the marker
 */
const newLocation = ref<XYCoords | null>(null);
/**
 * Used to compare if marker has been moved or not
 */
const originalLocation = ref<XYCoords | null>(null);

const hasLocationChanged = computed(() => {
  return !isEqual(newLocation.value, originalLocation.value);
});

/**
 * Used to transfer information about what node to move around
 * and where the new location is between multiple components,
 * instead of having lots of emits and prop updates.
 *
 * Supports one placing marker at the time
 */
export default function usePlaceNode() {
  function setNewXYLocation(coords: XYCoords) {
    newLocation.value = coords;
  }

  function startPlacing(nodeId: string | null, originalCoords: XYCoords | null) {
    newLocation.value = originalCoords;
    originalLocation.value = originalCoords;
    idToPlace.value = nodeId;
  }

  /**
   *
   * @returns NewLocation. Null if placing was never initialized, or if stopping placement with wrong nodeConfId.
   */
  function stopPlacing(nodeConfId?: string) {
    let coordsToReturn: XYCoords | null = null;
    if (nodeConfId !== undefined && nodeConfId === idToPlace.value) {
      // We are stopping placement of correct node, so we can return the newLocation.
      // nodeConfId should not mismatch, but is potentially possible
      // as we have multiple componenets using this composition.
      coordsToReturn = newLocation.value;
    }

    originalLocation.value = null;
    newLocation.value = null;
    idToPlace.value = null;
    return coordsToReturn;
  }

  return {
    idToPlace,
    startPlacing,
    stopPlacing,
    setNewXYLocation,
    newLocation,
    originalLocation,
    hasLocationChanged,
  };
}

export type XYCoords = {
  y: number | null;
  x: number | null;
};
