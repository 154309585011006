// API endpoints for mustering related actions. You are probably looking for `useMustering.ts` which is a hook that wraps these functions.
import { environment } from "../../environments/environment";
import customFetch from "@/helpers/customFetch";

const apiAddress = environment.apiAddress;

export function startMustering(comment: string | null, isTraining = false) {
  return customFetch(`${apiAddress}/Mustering/start`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      comment,
      isTraining,
    }),
  });
}

export function stopMustering(comment: string | null) {
  return customFetch(`${apiAddress}/Mustering/stop`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      comment,
    }),
  });
}

export function setMusteringMode(mode: MusteringMode) {
  return customFetch(`${apiAddress}/Mustering/configuration`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      mode,
    }),
  });
}

export async function fetchMusteringConfiguration() {
  const res = await customFetch(`${apiAddress}/Mustering/configuration`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-type": "application/json",
    },
  });
  if (res.ok) {
    return (await res.json()) as MusteringConfiguration;
  }
  throw new Error("Error: StatusCode: " + res.status + ". StatusText: " + res.statusText);
}

/**
 *
 * @param personId
 * @param release
 * @returns {boolean} a boolean value indicating whether the action has been successfull
 * @example
 * manuallyRegisterPerson("personId") // manually register
 * manuallyRegisterPerson("personId", false) // manually register
 * manuallyRegisterPerson("personId", true) // release
 */
export async function manuallyRegisterPerson(personId: string, release = false) {
  // TODO: Move registering endpoint to mustering controller
  const res = await customFetch(`${apiAddress}/persons/registered/${personId}/${release ? "release" : ""}`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-type": "application/json",
    },
  });

  return res.ok;
}

export interface MusteringConfiguration {
  mode: MusteringMode;
  OnPremUpdatedUtcDateTime: Date | string;
}

export enum MusteringMode {
  Auto = "auto",
  Manual = "manual",
}
