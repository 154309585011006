import { SortDir } from "@/compositions/useSort";
import { get } from "lodash";

// NOTE: keep the following function in sync with its twin in the ConnectFleet codebase
export function sortList<T = unknown>(
  list: T[],
  sortProp: string,
  sortDir: SortDir,
  nullValuesLast: boolean = true,
) {
  if (sortProp == "") {
    return list;
  }
  return list.sort((a, b) => {
    const aSort = get(a, sortProp);
    const bSort = get(b, sortProp);

    return normalSort(aSort, bSort, sortDir, nullValuesLast);
  });
}

// NOTE: keep the following function in sync with its twin in the ConnectFleet codebase
export function normalSort<T = unknown>(
  aSort: T,
  bSort: T,
  sortDir: SortDir = SortDir.ASC,
  nullValuesLast: boolean = true,
) {
  if (aSort === bSort) return 0; // Equal values

  // Null and undefined values last in list if nullValuesLast is true
  if (aSort === null || aSort === undefined) return nullValuesLast ? 1 : -1;
  if (bSort === null || bSort === undefined) return nullValuesLast ? -1 : 1;

  // Sort on string value
  if (typeof aSort === "string" && typeof bSort === "string") {
    const aSortLower = aSort.toLowerCase();
    const bSortLower = bSort.toLowerCase();
    if (aSortLower > bSortLower) return sortDir === SortDir.ASC ? 1 : -1;
    else if (aSortLower < bSortLower) return sortDir === SortDir.ASC ? -1 : 1;
    else return 0;
    // Sort on number (or Date) values
  } else if (
    (typeof aSort === "number" && typeof bSort === "number") ||
    (aSort instanceof Date && bSort instanceof Date) ||
    (aSort instanceof Date && (typeof bSort === "string" || typeof bSort === "number")) ||
    (bSort instanceof Date && (typeof aSort === "string" || typeof aSort === "number"))
  ) {
    const aSortDate = aSort instanceof Date ? aSort : new Date(aSort);
    const bSortDate = bSort instanceof Date ? bSort : new Date(bSort);
    return sortNumber(Number(aSortDate), Number(bSortDate), sortDir);
  } else {
    // No clue what to do at this point
    // Unsupported type to sort
    console.error(
      "Unsupported type to sort. Please, investigate this.",
      typeof aSort,
      typeof bSort,
      aSort,
      bSort,
    );
    return 0;
  }
}

// NOTE: keep the following function in sync with its twin in the ConnectFleet codebase
function sortNumber(aSort: number, bSort: number, sortDir: SortDir) {
  if (sortDir === SortDir.ASC) {
    return aSort - bSort;
  } else {
    return bSort - aSort;
  }
}
