import { Severity } from "scanreach-frontend-components/src/types/Issue.type";
import { mapGetters } from "vuex";
import SaveIcon from "scanreach-frontend-components/src/components/icons/SaveIcon.vue";
import WarningIcon from "scanreach-frontend-components/src/components/icons/ErrorIcon.vue";

export default {
  components: { SaveIcon, WarningIcon },
  data() {
    return {
      statuses: {
        reliable: {
          tooltipContent: `
              <center style='padding: 10px;'>
                Your system is running smoothly and data is trustworthy.<br />
                Make sure minor alerts are not overlooked, if any.
              </center>
            `,
          heading: "System status:",
          keyword: "OK",
          color: "var(--color-brand-02)",
          icon: SaveIcon,
        },
        endangered: {
          tooltipContent: `
              <center style='padding: 10px;'>
                The system is still working but there are issues<br />
                to be solved. Make sure you address these in time<br />
                as they may eventually lead the<br />
                system to malfunctioning.
              </center>
            `,
          heading: "System status:",
          keyword: "failure",
          color: "var(--color-status-warning)",
          icon: WarningIcon,
        },
        unreliable: {
          tooltipContent: `
              <center style='padding: 10px;'>
                Your system is in a critical situation<br />
                due to some severe conditions occurring.<br />
                Investigate and solve these to make<br />
                sure your system functions again.
              </center>
            `,
          heading: "System status:",
          keyword: "critical failure",
          color: "var(--color-status-severe)",
          icon: WarningIcon,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["systemStatus"]),
    systemStatusObj() {
      switch (this.systemStatus?.severity) {
        case Severity.SEVERE_SYSTEM_ALARM:
          return this.statuses.unreliable;
        case Severity.SYSTEM_ALARM:
          return this.statuses.endangered;
        case Severity.SYSTEM_ALERT:
        default:
          return this.statuses.reliable;
      }
    },
  },
};
