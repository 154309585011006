import { PersonActionName } from "@/typedef";
import { DashboardPerson } from "@/types/personTypes";
import { Ref, computed } from "vue";

export default function usePobByRoleCount(people: Ref<DashboardPerson[]>) {
  const roleNameToCount = computed(() => {
    const roleNameToCount = new Map();

    for (const person of people.value) {
      if (person.lastPersonAction && person.lastPersonAction.action === PersonActionName.embark) {
        const roleName = person.role ? person.role.name : "Undefined role";
        const currentCount = roleNameToCount.get(roleName) || 0; // Change this line
        roleNameToCount.set(roleName, currentCount + 1); // And this line
      }
    }
    return roleNameToCount;
  });

  const sortedRoles = computed(() => {
    const colors = ["#F3863D", "#20C394", "#0092E4", "#6A5EDF", "#F06D65", "#F2B25C", "#9E81B6"];
    const sortedRoles = Array.from(roleNameToCount.value.entries())
      .map(([roleName, count]) => ({ name: roleName, count }))
      .sort((a, b) => b.count - a.count);

    return sortedRoles.map((item, idx) => ({
      name: item.name,
      count: item.count,
      color: colors[idx] ?? "var(--color-brand-02)",
    }));
  });

  const additionalInfo = computed(() => {
    const obj = {
      droppedOff: 0,
      unassigned: 0,
      lowBattery: 0,
    };

    for (const item of people.value) {
      if (item.lastPersonAction && item.lastPersonAction.action === PersonActionName.dropoff) {
        obj.droppedOff += 1;
      }
      if (!item.lastPersonAction || item.lastPersonAction.action === PersonActionName.disembark) {
        obj.unassigned += 1;
      }
      if (
        item.wearable?.sensorData?.batteryPercentage !== null &&
        item.wearable?.sensorData?.batteryPercentage !== undefined &&
        item.wearable.sensorData.batteryPercentage <= 20
      ) {
        obj.lowBattery = obj.lowBattery + 1 || 1;
      }
    }

    return obj;
  });

  return { sortedRoles, additionalInfo };
}
