<template functional>
  <svg
    :width="props.size"
    :height="props.size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 14.5H18C17.7239 14.5 17.5 14.2761 17.5 14V2C17.5 1.72386 17.7239 1.5 18 1.5H30C30.2761 1.5 30.5 1.72386 30.5 2V14C30.5 14.2761 30.2761 14.5 30 14.5ZM14 30.5H2C1.72386 30.5 1.5 30.2761 1.5 30V18C1.5 17.7239 1.72386 17.5 2 17.5H14C14.2761 17.5 14.5 17.7239 14.5 18V30C14.5 30.2761 14.2761 30.5 14 30.5ZM14 14.5H2C1.72386 14.5 1.5 14.2761 1.5 14V2C1.5 1.72386 1.72386 1.5 2 1.5H14C14.2761 1.5 14.5 1.72386 14.5 2V14C14.5 14.2761 14.2761 14.5 14 14.5Z"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linejoin="round"
    />
    <path
      d="M24.5 18V31M18 24.5H31"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: String,
    iconColor: String,
  },
};
</script>

<style></style>
