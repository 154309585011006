<template functional>
  <svg
    :width="props.size"
    :height="props.size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.90476 6.40479H12.619C13.9472 6.40479 15.0238 7.48143 15.0238 8.80955V18.9762C15.0238 20.3043 13.9472 21.381 12.619 21.381H3.90476C2.57665 21.381 1.5 20.3043 1.5 18.9762V8.80955C1.5 7.48143 2.57665 6.40479 3.90476 6.40479Z"
      :stroke="props.iconColor"
      vector-effect="non-scaling-stroke"
    />
    <path
      d="M5.69077 3.5H10.833C11.6332 3.5 12.3221 4.06477 12.479 4.84938L12.7353 6.13095H3.78848L4.04479 4.84938C4.20171 4.06477 4.89062 3.5 5.69077 3.5Z"
      :stroke="props.iconColor"
      vector-effect="non-scaling-stroke"
    />
    <path
      d="M4.04479 22.9364L3.78848 21.6548L12.7353 21.6548L12.479 22.9364C12.3221 23.721 11.6332 24.2857 10.833 24.2857L5.69077 24.2857C4.89062 24.2857 4.20171 23.721 4.04479 22.9364Z"
      :stroke="props.iconColor"
      vector-effect="non-scaling-stroke"
    />
    <path
      d="M28.8035 13.4425C27.0075 11.6466 24.5263 10.5357 21.7857 10.5357C19.0451 10.5357 16.564 11.6466 14.768 13.4425L15.4751 14.1497C17.0901 12.5346 19.3212 11.5357 21.7857 11.5357C24.2502 11.5357 26.4813 12.5346 28.0963 14.1497L28.8035 13.4425Z"
      :fill="props.iconColor"
      vector-effect="non-scaling-stroke"
    />
    <path
      d="M25.7558 16.4902C24.65 15.6594 23.2753 15.1672 21.7857 15.1672C20.2961 15.1672 18.9215 15.6594 17.8156 16.4902L18.5311 17.2057C19.4493 16.5518 20.5726 16.1672 21.7857 16.1672C22.9988 16.1672 24.1221 16.5518 25.0403 17.2057L25.7558 16.4902Z"
      :fill="props.iconColor"
      vector-effect="non-scaling-stroke"
    />
    <path
      d="M15.831 20.9603H27.7405C29.6568 20.9603 31.2103 22.5138 31.2103 24.4301C31.2103 26.3464 29.6568 27.8999 27.7405 27.8999H15.831C13.9146 27.8999 12.3611 26.3464 12.3611 24.4301C12.3611 22.5138 13.9146 20.9603 15.831 20.9603Z"
      :stroke="props.iconColor"
      vector-effect="non-scaling-stroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: String,
    iconColor: String,
  },
};
</script>

<style></style>
