import { render, staticRenderFns } from "./Authentication.vue?vue&type=template&id=361a6843&scoped=true&"
import script from "./Authentication.vue?vue&type=script&lang=ts&"
export * from "./Authentication.vue?vue&type=script&lang=ts&"
import style0 from "./Authentication.vue?vue&type=style&index=0&id=361a6843&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "361a6843",
  null
  
)

export default component.exports