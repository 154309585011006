<template>
  <div class="trigger" :class="{ active: isActive }">
    <a id="trigger-button" @click="isActive = !isActive">{{ initials }}</a>
    <div class="arrow"></div>
    <div class="user-menu">
      <div class="profile">
        <div class="avatar">{{ initials }}</div>
        <div class="info">
          <p class="name">{{ fullName }}</p>
          <p class="role" v-if="showRole">{{ role ? role : "unknown role" }}</p>
        </div>
        <span @click="$emit('editUserClick')" v-if="showEditUser">change password</span>
      </div>
      <a @click="$emit('logoutClick')" class="logout">
        <p>log out</p>
        <span><LogoutIcon size="24" iconColor="var(--color-pop-01)" /></span>
      </a>
    </div>
  </div>
</template>

<script>
import LogoutIcon from "@/components/icons/Logout.vue";
import { onBeforeUnmount, ref, defineComponent } from "vue";

export default defineComponent({
  name: "UserMenu",
  components: { LogoutIcon },
  props: {
    fullName: {
      type: String,
      required: false,
    },
    initials: {
      type: String,
      required: false,
    },
    role: {
      type: String,
      required: false,
    },
    showRole: {
      type: Boolean,
      default: true,
    },
    showEditUser: {
      type: Boolean,
      default: true,
    },
  },
  emits: {
    logoutClick: () => true,
    editUserClick: () => true,
  },
  setup() {
    // Popover related logic:
    const isActive = ref(false);
    window.onclick = (event) => {
      if (!event.target.matches("#trigger-button")) {
        isActive.value = false;
      }
    };
    onBeforeUnmount(() => {
      window.onclick = undefined;
    });

    return { isActive };
  },
});
</script>

<style scoped>
.trigger {
  align-self: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 16px 0 16px 0;
}

.trigger > a {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-brand-03);
  width: 32px;
  height: 32px;
  color: var(--color-base-white);
  text-decoration: unset;
  cursor: pointer;
  user-select: none;
  transition: all var(--transition-speed) var(--cubic-pop);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}

.trigger > a:hover {
  background-color: var(--color-ui-01);
  color: var(--color-base-white);
}

.trigger.active > a {
  background-color: var(--color-pop-01);
  color: var(--color-ui-01);
}

.arrow {
  visibility: hidden;
  width: 32px;
  height: 32px;
  background-color: var(--color-ui-01);
  position: absolute;
  left: 45px;
  bottom: 0px;
  transform: rotate(45deg);
}

.user-menu {
  z-index: 1001;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background-color: var(--color-brand-04);
  width: 300px;
  height: 136px;
  left: 50px;
  bottom: -15px;
  border-radius: 3px;
  transition: all var(--transition-speed) var(--cubic-pop);
}

.trigger.active .user-menu {
  width: 363px;
}

.trigger.active .user-menu,
.trigger.active .arrow {
  visibility: visible;
  opacity: 1;
}

.profile,
.logout {
  background-color: var(--color-ui-01);
}

/** CONTENT RULES */

.info {
  display: flex;
  flex-flow: column nowrap;
  margin-left: 16px;
  text-align: start;
}

.info .name {
  color: var(--color-base-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}
.info .role {
  color: var(--color-brand-01);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.profile {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 86px;
  padding: 0 16px 0 16px;
  margin-bottom: 2px;
}

.profile > span,
.logout > span {
  margin-left: auto;
}

.logout {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 48px;
  padding: 0 16px 0 16px;
  text-transform: uppercase;
}

.logout,
.profile > span {
  color: var(--color-brand-01);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: end;
}

.logout:hover,
.profile > span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-brand-03);
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
}

/** RULES FOR SMALLER DEVICES */
@media only screen and (max-width: 900px) {
  #trigger-button,
  .arrow {
    display: none;
  }

  .trigger {
    align-self: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin: unset;
  }

  .user-menu {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    background-color: var(--color-ui-01);
    position: relative;
    visibility: visible;
    opacity: 1;
    bottom: unset;
    left: unset;
  }

  .profile,
  .logout {
    background-color: var(--color-ui-05);
    margin-bottom: 2px;
  }
}
</style>
