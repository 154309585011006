<template functional>
  <svg
    :width="props.size"
    :height="props.size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.93,28.08A5.52,5.52,0,0,1,1.5,23.51h0A5.5,5.5,0,0,1,7,18h7"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <path
      d="M18.91,18H25a5.5,5.5,0,0,1,5.5,5.5h0A5.5,5.5,0,0,1,25,29H7.91"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <path
      d="M5.75,7.26a14.49,14.49,0,0,1,19.71-.74"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <path
      d="M10,11.5a8.49,8.49,0,0,1,11.22-.7"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <line
      x1="1.97"
      y1="30"
      x2="29.97"
      y2="2"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: String,
    iconColor: String,
  },
};
</script>

<style></style>
