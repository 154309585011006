var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{staticClass:"flex-nav"},[_c('a',{staticClass:"logomark",on:{"click":function($event){return _vm.$router.push({ name: _vm.RouteName.Safety })}}},[_c('img',{attrs:{"src":require("@/assets/logomark.svg"),"width":"33","height":"33","alt":"Scanreach logo"}})]),_c('div',{staticClass:"header-bar"},[_c('a',{attrs:{"href":"#","role":"button"}},[_c('CancelIcon',{attrs:{"size":"24","iconColor":"var(--color-base-brand)"}})],1)]),_c('ul',{staticClass:"modules-list"},[_vm._l((_vm.modules),function(module,index){return _c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
        _vm.isMobile || _vm.isTablet
          ? undefined
          : {
              content: _vm.tooltipContent(module),
              placement: 'right-center',
              boundariesElement: '.modules-list',
            }
      ),expression:"\n        isMobile || isTablet\n          ? undefined\n          : {\n              content: tooltipContent(module),\n              placement: 'right-center',\n              boundariesElement: '.modules-list',\n            }\n      "}],key:index,staticClass:"module",class:{ 'module-active': _vm.currentModule === module.name },on:{"click":function($event){return _vm.navigateTo(module)}}},[_c(module.icon,{tag:"component",attrs:{"iconColor":module.name === _vm.currentModule ? 'var(--color-base-brand)' : 'var(--color-brand-03)',"size":"24"}}),(_vm.numberOfAlarms && module.name === 'safety')?_c('div',{staticClass:"distress-indicator indicator"},[_c('span',[_vm._v(_vm._s(_vm.numberOfAlarms))])]):_vm._e(),(module.name === 'status' && _vm.visibleIssues.length > 0)?_c('div',{staticClass:"indicator",style:({ 'background-color': _vm.severityToBackgroundColor(_vm.systemStatusSeverity) })},[_c('span',[_vm._v(_vm._s(_vm.visibleIssues.filter((i) => i.severity === _vm.systemStatusSeverity).length))])]):_vm._e(),_c('span',{staticClass:"module-label"},[_vm._v(_vm._s(module.title))])],1)}),_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
        _vm.isMobile || _vm.isTablet
          ? undefined
          : {
              content: 'Open Sensor Data & Alarms app',
              placement: 'right-center',
              boundariesElement: '.modules-list',
            }
      ),expression:"\n        isMobile || isTablet\n          ? undefined\n          : {\n              content: 'Open Sensor Data & Alarms app',\n              placement: 'right-center',\n              boundariesElement: '.modules-list',\n            }\n      "}],staticClass:"module",attrs:{"to":{ path: '/connect' },"target":"_blank"}},[_c('AppsIcon',{attrs:{"iconColor":"var(--color-brand-03)","size":"24"}}),_c('span',{staticClass:"module-label"},[_vm._v("Open Sensor Data & Alarms app")])],1)],2),_c('ul',{staticClass:"bottom-link-list"},[_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
        _vm.isMobile || _vm.isTablet
          ? undefined
          : {
              content:
                _vm.store.getters.appTheme === 'Nightview' ? 'Switch to day view' : 'Switch to night view',
              placement: 'right-center',
            }
      ),expression:"\n        isMobile || isTablet\n          ? undefined\n          : {\n              content:\n                store.getters.appTheme === 'Nightview' ? 'Switch to day view' : 'Switch to night view',\n              placement: 'right-center',\n            }\n      "}],staticClass:"module",on:{"click":_vm.toggleAppTheme}},[_c('div',{staticClass:"theme-button"},[(_vm.store.getters.appTheme === 'Nightview')?_c('div',{staticClass:"theme"},[_c('sun-icon',{attrs:{"size":"18","iconColor":"var(--color-brand-03)"}})],1):_vm._e(),(_vm.store.getters.appTheme === 'Dayview')?_c('div',{staticClass:"theme"},[_c('moon-icon',{attrs:{"size":"18","iconColor":"var(--color-brand-03)"}})],1):_vm._e()])]),_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
        _vm.isMobile || _vm.isTablet
          ? undefined
          : {
              content: _vm.isActive ? 'Exit full-screen mode' : 'Enter full-screen mode',
              placement: 'right-center',
            }
      ),expression:"\n        isMobile || isTablet\n          ? undefined\n          : {\n              content: isActive ? 'Exit full-screen mode' : 'Enter full-screen mode',\n              placement: 'right-center',\n            }\n      "}],staticClass:"module fullscreen-module",class:{ active: _vm.isActive },on:{"click":_vm.toggleFullScreen}},[_c('div',{staticClass:"fullscreen-button"},[_c('div',{staticClass:"fullscreen"},[_c('full-screen-icon',{attrs:{"size":"24","iconColor":"var(--color-brand-03)"}})],1),_c('div',{staticClass:"normalscreen"},[_c('normal-screen-icon',{attrs:{"size":"24","iconColor":"var(--color-brand-03)"}})],1)])]),_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.isMobile || _vm.isTablet ? undefined : { content: 'Help', placement: 'right-center' }),expression:"isMobile || isTablet ? undefined : { content: 'Help', placement: 'right-center' }"}],staticClass:"module",on:{"click":function($event){_vm.isSupportModalShowing = !_vm.isSupportModalShowing}}},[_c('QuestionMarkIcon',{attrs:{"size":"18","iconColor":"var(--color-brand-03)"}})],1)]),_c('footer',[_c('UserMenu',{attrs:{"fullName":_vm.getSession('user').name,"initials":_vm.getSession('user')
          .name.split(' ')
          .map((name) => name[0])
          .join('')
          .slice(0, 3)
          .toUpperCase(),"role":_vm.getSession('user').roles.join(', ').trim(),"showRole":true,"showEditUser":true},on:{"editUserClick":function($event){_vm.isChangePasswordMode = true},"logoutClick":_vm.handleLogout}}),_c('li',{staticClass:"clock"},[_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.hour)),_c('span',[_vm._v(":")]),_vm._v(_vm._s(_vm.minute))]),_c('div',{staticClass:"timezone"},[_vm._v("UTC")])])],1),(_vm.isChangePasswordMode)?_c('portal',{attrs:{"to":"modal"}},[_c('change-password-modal',{on:{"hideChangePasswordModal":function($event){_vm.isChangePasswordMode = false}}})],1):_vm._e(),(_vm.isSupportModalShowing)?_c('portal',{attrs:{"to":"modal"}},[_c('support-modal',{on:{"close":function($event){_vm.isSupportModalShowing = false}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }