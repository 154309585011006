import { createPopper, VirtualElement } from "@popperjs/core";
export default function withPopper(
  dropdownList: HTMLElement,
  component: { $refs: { toggle: Element | VirtualElement } },
  { width }: { width: number; top: number; left: number },
) {
  /**
   * We need to explicitly define the dropdown width since
   * it is usually inherited from the parent with CSS.
   */
  dropdownList.style.width = width.toString();
  dropdownList.style.zIndex = (500000).toString(); // Need to be larger than the z-index of the modal

  const popper = createPopper(component.$refs.toggle, dropdownList, {
    modifiers: [
      {
        name: "offset",
        options: {
          // We offset be window.height since it for some reason spawn outside the viewport
          // I suspect this is because we have an application where all views are supposed to be 100vh
          offset: [0, -window.innerHeight],
        },
      },
    ],
  });

  /**
   * To prevent memory leaks Popper needs to be destroyed.
   * If you return function, it will be called just before dropdown is removed from DOM.
   */
  return () => {
    dropdownList.remove();
    popper.destroy();

    // Some hack to ensure that the listbox html element is removed from DOM when dropdown is closed
    setTimeout(() => document.querySelector(`[id='${dropdownList.id}']`)?.remove(), 10);
  };
}
