var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{attrs:{"autocomplete":"off"}},[_c('SelectGangwayLocation',{attrs:{"modelValue":_vm.selectedGangwayLocation,"vesselLocation":_vm.type === 'events' ? _vm.commonEventsLocation : null,"label":_vm.type === 'events' ? 'Location' : 'New location'},on:{"update:modelValue":(l) => (_vm.selectedGangwayLocation = l)}}),_c('hr',{staticClass:"section-separator"}),_c('div',{staticClass:"entries-lists"},[(_vm.embarkedEntries && _vm.embarkedEntries.length > 0)?_c('BoardingEntriesList',{attrs:{"title":"Embarked","icon":_vm.EmbarkIcon,"entries":_vm.embarkedEntries}}):_vm._e(),(_vm.embarkedEntries && _vm.embarkedEntries.length > 0)?_c('hr',{staticClass:"section-separator"}):_vm._e(),(_vm.droppedoffEntries && _vm.droppedoffEntries.length > 0)?_c('BoardingEntriesList',{attrs:{"title":"Dropped-off","icon":_vm.DropoffIcon,"entries":_vm.droppedoffEntries}}):_vm._e(),(_vm.droppedoffEntries && _vm.droppedoffEntries.length > 0)?_c('hr',{staticClass:"section-separator"}):_vm._e(),(_vm.disembarkedEntries && _vm.disembarkedEntries.length > 0)?_c('BoardingEntriesList',{attrs:{"title":"Disembarked","icon":_vm.DisembarkIcon,"entries":_vm.disembarkedEntries}}):_vm._e(),(_vm.disembarkedEntries && _vm.disembarkedEntries.length > 0)?_c('hr',{staticClass:"section-separator"}):_vm._e()],1),_c('div',{staticClass:"buttons"},[_c('NormalButton',{attrs:{"id":"approve-button","icon":"","type":"button","disabled":!_vm.selectedGangwayLocation},nativeOn:{"click":function($event){_vm.$emit(
          'confirm',
          true,
          _vm.selectedGangwayLocation
            ? _vm.selectedGangwayLocation.combinedLabel
              ? _vm.selectedGangwayLocation.combinedLabel
              : _vm.selectedGangwayLocation.label
            : null,
        );
        _vm.$emit('close');}}},[_c('saveIcon',{attrs:{"size":"16","iconColor":!_vm.selectedGangwayLocation ? 'var(--color-brand-03)' : 'var(--color-pop-01)'}}),_vm._v(" "+_vm._s(_vm.type === "events" ? "APPROVE" : "AMEND")+" "+_vm._s(_vm.items ? _vm.items.length : "")+" ")],1),_c('NormalButton',{attrs:{"id":"reject-button","icon":"","secondary":"","formnovalidate":"","type":"button"},nativeOn:{"click":function($event){_vm.$emit(
          'confirm',
          false,
          _vm.selectedGangwayLocation
            ? _vm.selectedGangwayLocation.combinedLabel
              ? _vm.selectedGangwayLocation.combinedLabel
              : _vm.selectedGangwayLocation.label
            : null,
        );
        _vm.$emit('close');}}},[_c('cancelIcon',{attrs:{"size":"16","iconColor":"var(--color-brand-06)"}}),_vm._v(" "+_vm._s(_vm.type === "events" ? `REJECT ${_vm.items ? _vm.items.length : ""}` : "CANCEL")+" ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }