// Mostly copied from ConnectFleet.Frontend codebase
import { computed, Ref, ref } from "vue";

import useToaster, { ToastType } from "scanreach-frontend-components/src/compositions/useToaster";
import signalRSocketHandler from "@/helpers/signalRSocketHandler";
import { SensorConfiguration, SensorConfigurationWithLastSensorData } from "@/types/sensorConfigurationTypes";
import customFetch from "@/helpers/customFetch";
import { environment } from "../../environments/environment";

const initialized = ref(false);
const configuredSensors = ref<SensorConfigurationWithLastSensorData[] | null>();

const nodeMacToSensorConfigIdMap = computed<{
  [nodeMac: string]: SensorConfigurationWithLastSensorData[];
}>(() => {
  const map: { [nodeMac: string]: SensorConfigurationWithLastSensorData[] } = {};
  configuredSensors.value?.forEach((s) => {
    if (s.sensorId) {
      if (!map[s.sensorId]) {
        map[s.sensorId] = [];
      }
      map[s.sensorId].push(s);
    }
  });
  return map;
});

const isLoading = ref(true);
const apiAddress = environment.apiAddress;

/**
 * Composition for fetching all available SensorConfigurations, intended to be used onprem
 */
export default function () {
  const { pushToast } = useToaster();
  if (!initialized.value) {
    signalRSocketHandler.connect();
    signalRSocketHandler.on(
      "ReceiveSensorConfigurationChangeEvent",
      (sensorConfiguration: SensorConfiguration) => {
        updateSensorConfigurationLocally(sensorConfiguration);
      },
    );
    fetchAndUpdateSensorConfigurations();
    initialized.value = true;
  }

  /**
   * Fetch SensorConfigurations from api and store in ref variable
   */
  async function fetchAndUpdateSensorConfigurations() {
    try {
      isLoading.value = true;
      configuredSensors.value = await fetchSensorConfigurations<SensorConfiguration[]>();
    } catch (error) {
      console.error(error);
      pushToast({
        title: "Error fetching SensorConfigurations",
        body: "Please refresh page and try again. Contact ScanReach support if error persists.",
        type: ToastType.ERROR,
        duration: 10_000,
      });
    } finally {
      isLoading.value = false;
    }
  }

  /**
   * Fetch all available configured sensors
   */
  async function fetchSensorConfigurations<T>(): Promise<T> {
    try {
      const url = `${apiAddress}/sensorconfiguration`;
      const res = await customFetch(url, {
        method: "GET",
      });
      if (res.ok) {
        const a = await res.json();
        return a as T;
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
   * Update list of sensors locally, used by signalR client to update list dynamically based on changeEvents from api.
   * @param config The new config from api
   * @param configured Whether to update configuredSensorList or list of unconfiguredSensors
   */
  function updateSensorConfigurationLocally(sensorConfig: SensorConfiguration) {
    // Skip update if the initial fetch from API is not completed yet
    if (!configuredSensors.value) {
      return;
    }

    const existingSensorConfigurationIdx = configuredSensors.value.findIndex((s) => s.id == sensorConfig.id);
    if (!isNaN(existingSensorConfigurationIdx) && existingSensorConfigurationIdx >= 0) {
      // Sensor exists in list, remove or update it
      if (sensorConfig.deletedUtcDateTime) {
        configuredSensors.value?.splice(existingSensorConfigurationIdx, 1);
      } else if (configuredSensors.value) {
        // Update fields manually instead of replacing object in order to maintain reactivity of object. Instead of having to use computedProperty based on selectedSensorId
        const ex = configuredSensors.value[existingSensorConfigurationIdx] as SensorConfiguration;
        ex.description = sensorConfig.description;
        ex.tagName = sensorConfig.tagName;
        ex.sensorId = sensorConfig.sensorId;
        ex.x = sensorConfig.x;
        ex.y = sensorConfig.y;
        ex.measurementTypesToShowInMap = sensorConfig.measurementTypesToShowInMap;
      }
    } else if (!sensorConfig.deletedUtcDateTime) {
      // Sensor is new, add it if not deleted
      configuredSensors.value.push(sensorConfig as never);
    }
  }

  function getSensorConfigById(sensorConfigId?: Ref<string | undefined> | string) {
    return computed(() =>
      configuredSensors.value?.find(
        (s) => s.id === (typeof sensorConfigId == "string" ? sensorConfigId : sensorConfigId?.value),
      ),
    );
  }

  return {
    isLoading,
    configuredSensors,
    nodeMacToSensorConfigIdMap,
    fetchAndUpdateSensorConfigurations,
    getSensorConfigById,
  };
}

/**
 * Maybe introduce a map of sensorConfigurations by id to make this more efficient. Depends on how often we use this function.
 */
export function getSensorConfigurationById(id: string) {
  return configuredSensors.value?.find((s) => s.id == id);
}

export function getSensorConfigurationsByNodeMac(nodeMac: string) {
  return nodeMacToSensorConfigIdMap.value[nodeMac];
}
