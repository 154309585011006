import { ref, Ref, computed, onBeforeUnmount } from "vue";

const pressedKeys: Ref<string[]> = ref([]);

function handleKeyDown(event): void {
  pressedKeys.value.push(event.key);
}

function handleKeyUp(event): void {
  pressedKeys.value = pressedKeys.value.filter((key) => key != event.key);
}

export default function () {
  window.addEventListener("keydown", handleKeyDown);
  window.addEventListener("keyup", handleKeyUp);

  const getIsKeyPressedForKey = (key: string) =>
    computed(() => {
      return pressedKeys.value.some((item) => item === key);
    });

  onBeforeUnmount(() => {
    window.removeEventListener("keydown", handleKeyDown);
    window.removeEventListener("keyup", handleKeyUp);
  });

  return { getIsKeyPressedForKey };
}
