import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from "@/../environments/environment";

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: environment.appInsightsApiKey,
    disableFetchTracking: false,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAutoRouteTracking: true,
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
