import { SystemStatus } from "@/typedef";
import { fetchSystemStatus } from "@/helpers/apiService";

type SystemStatusState = {
  systemStatus: SystemStatus | null;
  fetchIntervalId: number | null;
};
const initialState: SystemStatusState = {
  systemStatus: null,
  fetchIntervalId: null,
};

export const systemStatusModule = {
  state: () => initialState,
  mutations: {
    SET_SYSTEMSTATUS(state: SystemStatusState, systemStatus: SystemStatus) {
      state.systemStatus = systemStatus;
    },
    SET_FETCH_INTERVAL_ID(state: SystemStatusState, fetchIntervalId: number) {
      state.fetchIntervalId = fetchIntervalId;
    },
  },
  getters: {
    systemStatus: (state: SystemStatusState) => {
      return state.systemStatus;
    },
  },
  actions: {
    /**
     * Update a person
     * @param {*} param0
     */
    async subscribeToSystemStatus({ commit, state }: { commit: any; state: SystemStatusState }) {
      if (state.fetchIntervalId) {
        // Already subscribing to systemStatus
        return;
      }
      try {
        commit("SET_SYSTEMSTATUS", await fetchSystemStatus());
      } catch (error) {
        console.error(error);
      }

      const fetchIntervalId = setInterval(async () => {
        try {
          commit("SET_SYSTEMSTATUS", await fetchSystemStatus());
        } catch (error) {
          console.error(error);
        }
      }, 5 * 1000);

      commit("SET_FETCH_INTERVAL_ID", fetchIntervalId);
    },
  },
};

export default systemStatusModule;
