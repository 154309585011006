var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"event-list-item",class:{
    'event-list-item-active': _vm.isSelected,
  },on:{"click":function($event){return _vm.$emit('setSelected', _vm.person.distressAlarm?.id ?? _vm.person.id)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.person.distressAlarm && _vm.person.distressAlarm?.acknowledgedTime === null),expression:"person.distressAlarm && person.distressAlarm?.acknowledgedTime === null"}],staticClass:"unacknowledged-distress-background"}),_c('div',{staticClass:"card"},[_c('ListItemStatusBar',{attrs:{"color":_vm.distressAlarm ? 'var(--color-status-alarm)' : _vm.statusObject.color}}),_c('div',{staticClass:"table-cols",class:{
        'not-embarked-personnel': _vm.userFriendlyBoardingStateName !== _vm.UserFriendlyBoardingState.Embarked,
      },style:({ gridTemplateColumns: _vm.gridTemplateColumns })},[(_vm.isMustering && !_vm.person.distressAlarm)?_c('div',{staticClass:"manual-register-button",style:(_vm.headers[0].style)},[_c('SToggle',{attrs:{"small-toggle":"","active-text":"YES","inactive-text":"NO","switchBackgroundColorWhite":true,"isActive":_vm.isPersonRegistered,"disabled":_vm.isRegisteringInProgress},on:{"toggle":function($event){_vm.searchText &&
            _vm.userFriendlyBoardingStateName !== _vm.UserFriendlyBoardingState.Embarked &&
            !_vm.isPersonRegistered
              ? (_vm.showModalForRegisteringNotEmbarkedPerson = true)
              : _vm.handleManuallyRegisterPerson(_vm.person, _vm.isPersonRegistered)}}})],1):_vm._e(),(_vm.person.distressAlarm)?_c('ReactiveTimeSince',{staticClass:"distress-time",style:(_vm.headers[0].style),attrs:{"date":_vm.person.distressAlarm.activatedTime,"useShortForm":true}}):_vm._e(),_c('div',{staticClass:"role",style:(_vm.isMustering ? _vm.headers[1].style : _vm.headers[0].style),domProps:{"innerHTML":_vm._s(_vm.getHighlightedText(_vm.person.role?.name ?? 'Unknown', _vm.searchText))}}),_c('div',{staticClass:"flex-column"},[_c('p',{attrs:{"title":_vm.person.fullName ?? ''},domProps:{"innerHTML":_vm._s(_vm.getHighlightedText(_vm.person.fullName, _vm.searchText))}}),(_vm.searchText && _vm.person.wearableMac)?_c('p',{staticClass:"wearable-mac",attrs:{"title":_vm.person.wearableMac},domProps:{"innerHTML":_vm._s(
            _vm.getHighlightedText(_vm.person.wearableMac ? _vm.person.wearableMac.slice(-5) : 'unassigned', _vm.searchText)
          )}}):_vm._e()]),_c('div',{staticClass:"location-status"},[_c(_vm.statusObject.icon,{tag:"component",attrs:{"iconColor":_vm.statusObject.color,"size":"24px"}}),_c('p',{staticClass:"nodeName",style:({ color: _vm.statusObject.color }),attrs:{"title":_vm.connectedNodeLabel}},[_vm._v(" "+_vm._s(_vm.connectedNodeLabel)+" "),(_vm.statusObject.isMissing && !_vm.searchText)?_c('span',[_vm._v(_vm._s(_vm.timeSinceLastObserved))]):_c('span',{staticClass:"boarding-status",class:{
              'boarding-status-italic':
                _vm.userFriendlyBoardingStateName !== _vm.UserFriendlyBoardingState.Embarked,
            },style:({ color: !_vm.searchText ? 'var(--color-base-white)' : undefined })},[_vm._v(" "+_vm._s(_vm.userFriendlyBoardingStateName)+" ")])])],1),(_vm.showViewInMapBtnInTable)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.isMissing ? `Button is disabled as this person's location is unknown.` : '',
          placement: 'bottom-center',
        }),expression:"{\n          content: isMissing ? `Button is disabled as this person's location is unknown.` : '',\n          placement: 'bottom-center',\n        }"}]},[_c('NormalButton',{attrs:{"disabled":_vm.isMissing},nativeOn:{"click":function($event){$event.stopPropagation();return (() => _vm.$emit('viewInMapClick')).apply(null, arguments)}}},[_vm._v("VIEW IN MAP")])],1):_vm._e()],1)],1),_c('div',{staticClass:"expanded"},[_c('div',{staticClass:"sensor-info",class:{
        'not-embarked-personnel': _vm.userFriendlyBoardingStateName !== _vm.UserFriendlyBoardingState.Embarked,
      }},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.formattedLastObserved),expression:"formattedLastObserved"}]},[_c('div',{staticClass:"label"},[_vm._v("CABIN #")]),_c('div',{staticClass:"sensor-value"},[_vm._v(_vm._s(_vm.person.cabinNr ? _vm.person.cabinNr : "-"))])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.formattedLastObserved),expression:"formattedLastObserved"}]},[_c('div',{staticClass:"label"},[_vm._v("BUNK #")]),_c('div',{staticClass:"sensor-value"},[_vm._v(_vm._s(_vm.person.bunkNr ? _vm.person.bunkNr : "-"))])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.formattedLastObserved),expression:"formattedLastObserved"}]},[_c('div',{staticClass:"label"},[_vm._v("BATTERY")]),_c('div',{staticClass:"sensor-value"},[_vm._v(_vm._s(_vm.battery))])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.formattedLastObserved),expression:"formattedLastObserved"}]},[_c('div',{staticClass:"label"},[_vm._v("SIGNAL")]),_c('div',{staticClass:"sensor-value"},[_c('antenna-icon',{attrs:{"size":"16","iconColor":"var(--color-pop-02)","signal":_vm.signalstrength}})],1)]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.formattedLastObserved),expression:"formattedLastObserved"}]},[_c('div',{staticClass:"label"},[_vm._v("STATUS")]),_c('div',{staticClass:"sensor-value"},[_vm._v(" "+_vm._s(_vm.userFriendlyBoardingStateName)+" ")])])]),_c('div',{staticClass:"details"},_vm._l((_vm.unresolvedIssues),function(event){return _c('EventPill',{key:event.id,attrs:{"event":event,"pillTextColor":"var(--color-ui-01)"}})}),1),(_vm.showResolveDistressButtons && _vm.person.distressAlarm)?_c('div',{staticClass:"expanded-distress-buttons",class:{ tablet: _vm.isTablet }},[(_vm.isTablet)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.isMissing ? `Button is disabled as this person's location is unknown.` : '',
          placement: 'bottom-center',
        }),expression:"{\n          content: isMissing ? `Button is disabled as this person's location is unknown.` : '',\n          placement: 'bottom-center',\n        }"}],staticClass:"view-in-map-button"},[(_vm.showViewInMapBtnInTable)?_c('NormalButton',{attrs:{"disabled":_vm.isMissing},nativeOn:{"click":function($event){$event.stopPropagation();return (() => _vm.$emit('viewInMapClick')).apply(null, arguments)}}},[_vm._v("VIEW IN MAP")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"handle-distress-buttons-container"},[_c('NormalButton',{nativeOn:{"click":function($event){$event.stopPropagation();return (() => _vm.$emit('acknowledgeDistress', _vm.person.distressAlarm.id)).apply(null, arguments)}}},[_vm._v("ACKNOWLEDGE")]),_c('NormalButton',{nativeOn:{"click":function($event){$event.stopPropagation();return (() => _vm.$emit('resolveDistress', _vm.person.distressAlarm.id)).apply(null, arguments)}}},[_vm._v(" RESOLVE DISTRESS ")])],1)]):_vm._e(),_vm._m(0)]),(_vm.showModalForRegisteringNotEmbarkedPerson)?_c('portal',{attrs:{"to":"modal"}},[_c('SConfirmModal',{attrs:{"width":"560px","title":`Manually register ${
        _vm.getPersonActionStateObject(_vm.person.lastPersonAction?.action)?.userFriendlyStateName.toLowerCase() ??
        'disembarked'
      } personnel`,"confirmText":"Yes","cancelText":"No","icon":_vm.ErrorIcon,"iconColor":"var(--color-status-warning)"},on:{"hideModal":function($event){_vm.showModalForRegisteringNotEmbarkedPerson = false},"confirm":_vm.handleConfirmModalManualRegistration,"cancel":function($event){_vm.showModalForRegisteringNotEmbarkedPerson = false}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(
            _vm.person.wearable && !_vm.person.externalPersonReference?.isExternalSystemResponsibleForDisEmbarking
          )?_c('p',[_vm._v(" By manually registering "+_vm._s(_vm.person.fullName)+", this person will automatically get the status "),_c('strong',[_vm._v("Embarked")]),_vm._v(". Are you sure you want to manually register "+_vm._s(_vm.person.fullName)+"? ")]):_c('p',[_vm._v(" By manually registering "+_vm._s(_vm.person.fullName)+", you will have a "+_vm._s(_vm.getPersonActionStateObject(_vm.person.lastPersonAction?.action)?.userFriendlyStateName ?? "Disembarked")+" person in the list of accounted for. Are you sure you want to manually register "+_vm._s(_vm.person.fullName)+"? ")])]},proxy:true}],null,false,2917357396)})],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"close-wrapper"},[_c('div',{staticClass:"close"})])
}]

export { render, staticRenderFns }