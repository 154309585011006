import { computed, ref } from "vue";
import { DashboardPerson } from "@/types/personTypes";
import { useStore } from "../store/desktopStore";
import useSnapshot from "./useSnapshot";
import signalRSocketHandler from "@/helpers/signalRSocketHandler";
import {
  MusteringConfiguration,
  fetchMusteringConfiguration,
  manuallyRegisterPerson,
  setMusteringMode,
  startMustering,
  stopMustering,
} from "@/helpers/musteringService";
import useToaster, { ToastType } from "scanreach-frontend-components/src/compositions/useToaster";

const musteringConfiguration = ref<MusteringConfiguration>();

const isInitialized = ref(false);

export default function () {
  if (!isInitialized.value) {
    isInitialized.value = true;
    initializeData();
    signalRSocketHandler.subscribe("reconnect", async () => {
      await initializeData();
    });
  }

  const store = useStore();
  const { snapshot } = useSnapshot();
  const musteringEvent = computed(() => store.state.musteringEvent);
  const people = computed(() => (store.state as any).person.people as DashboardPerson[]);

  const musteredPeople = computed<DashboardPerson[]>(() => {
    const out: DashboardPerson[] = [];
    snapshot.value?.peopleCount.musteredPeopleIds?.forEach((id) => {
      const person = people.value[id];
      if (person) {
        out.push(person);
      }
    });

    return out;
  });

  const notMusteredPeople = computed<DashboardPerson[]>(() => {
    const out: DashboardPerson[] = [];
    snapshot.value?.peopleCount.notMusteredPeopleIds?.forEach((id) => {
      const person = people.value[id];
      if (person) {
        out.push(person);
      }
    });

    return out;
  });

  const manuallyRegisteredPersonIds = computed(() => {
    return new Set(snapshot.value?.peopleCount.registeredPeopleIds);
  });

  async function initializeData() {
    const musteringConfigurationFromApi = await fetchMusteringConfiguration();
    musteringConfiguration.value = musteringConfigurationFromApi;

    subscribeToSignalREvents();
  }

  function subscribeToSignalREvents() {
    signalRSocketHandler.connect();

    signalRSocketHandler.on("ReceiveMusteringConfigurationChangeEvent", (msg: MusteringConfiguration) => {
      musteringConfiguration.value = msg;
    });
  }
  const { pushToast } = useToaster();
  const isRegisteringInProgress = ref(false);

  async function handleManuallyRegisterPerson(person: DashboardPerson, release: boolean) {
    let succeded = false;
    try {
      isRegisteringInProgress.value = true;
      succeded = await manuallyRegisterPerson(person.id, release);
      if (succeded) {
        // Delay for 3000 milliseconds as backend sends snapshot containing registered people id<s every 3 seconds
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }
    } catch (error) {
      console.error(error);
      succeded = false;
    } finally {
      isRegisteringInProgress.value = false;
      if (!succeded) {
        pushToast({
          title: "Registration failed",
          body: `An error occurred while attempting to ${release ? "unregister" : "register"} personnel ${
            person.fullName
          }`,
          type: ToastType.ERROR,
          duration: 7_000,
        });
      }
    }
  }
  return {
    musteredPeople,
    notMusteredPeople,
    musteringConfiguration,
    musteringEvent,
    manuallyRegisteredPersonIds,
    startMustering,
    stopMustering,
    setMusteringMode,
    handleManuallyRegisterPerson,
    isRegisteringInProgress,
  };
}
