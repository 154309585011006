import { ref } from "vue";

export default function useSort(defaultSortProp?: string, defaultSortDir?: SortDir) {
  const selectedSortProp = ref(defaultSortProp ? defaultSortProp : "");
  const currentSortDir = ref(defaultSortDir ? defaultSortDir : SortDir.ASC);

  function handleSortPropSelected(sortProp: string) {
    if (selectedSortProp.value === sortProp) {
      currentSortDir.value = sortDirConversionMap[currentSortDir.value];
    } else {
      selectedSortProp.value = sortProp;
      currentSortDir.value = SortDir.ASC;
    }
  }

  return { selectedSortProp, currentSortDir, handleSortPropSelected };
}

export enum SortDir {
  ASC = "ASC",
  DESC = "DESC",
}

export const sortDirConversionMap = {
  [SortDir.ASC]: SortDir.DESC,
  [SortDir.DESC]: SortDir.ASC,
};
