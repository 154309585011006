import { environment } from "../../environments/environment";
import customFetch from "@/helpers/customFetch";
import { CustomFetchOptions, ApiWearable, SensorDataObject } from "@/typedef";

const apiAddress = environment.apiAddress;

let fetchWearableSensorDataLock = false;

/**
 * Get specific wearable by mac
 * Might implement querying in the future.
 * @returns {Promise<ApiWearable>}
 */
export function fetchWearableByMac(macAddress: string[]): Promise<ApiWearable> {
  return customFetch(`${apiAddress}/tags/${macAddress}`, {
    method: "GET",
    credentials: "include",
  }).then((response) => {
    return response.json();
  });
}

/**
 * Get all wearables
 * Might implement querying in the future.
 * @returns {Promise<ApiWearable[]>}
 */
export function fetchWearables({ showWarning = false }: CustomFetchOptions = {}): Promise<ApiWearable[]> {
  return customFetch(
    `${apiAddress}/tags`,
    {
      method: "GET",
      credentials: "include",
    },
    showWarning,
  ).then((response) => {
    return response.json();
  });
}

/**
 * Get sensorData for list of wearableMacs
 */
export async function fetchWearableSensorData(wearableMacs?: string[]): Promise<SensorDataObject> {
  if (fetchWearableSensorDataLock) {
    throw new Error("Already waiting for this snapshot.");
  }

  fetchWearableSensorDataLock = true;

  try {
    const response = await customFetch(
      `${apiAddress}/sensordata/wearable`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: wearableMacs ? JSON.stringify(wearableMacs) : "[]",
      },
      false,
      true,
    );

    return await response.json();
  } finally {
    fetchWearableSensorDataLock = false;
  }
}
