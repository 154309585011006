import { DashboardNodeWithMarkerPos } from "@/typedef";
import useMeshState, { NodeNeighbour } from "@/compositions/useMeshState";

const { getNodeMeshStateForNodeMac } = useMeshState();

export default function () {
  /**
   * It takes a list of nodes, and for each node that is unplaced but active, it assigns a marker
   * position estimated by calculating the centroid of the marker positions of its neighbors;
   * in case this should not be possible, a default position will be given to the node in order to
   * make debugging possible
   *
   * @param {DashboardNode[]} nodes - DashboardNode[]
   * @returns An array of DashboardNodes where each of them has a position
   */
  function assignEstimatedMarkerPosToUnlocatedButActiveNodes(
    nodes: DashboardNodeWithMarkerPos[],
  ): DashboardNodeWithMarkerPos[] {
    return nodes.map((node) => {
      if (isThisNodeUnlocatedButActive(node) && !hasThisNodeValidMarkerPos(node)) {
        // get node's list of neighbors' mac adresses
        const neighborsMacs =
          getNodeMeshStateForNodeMac(node.mac).nodeNeighbours?.neighbours.map(
            (neighbor: NodeNeighbour) => neighbor.nodeMac,
          ) ?? [];
        if (neighborsMacs.length > 0) {
          // map neighbors to latlng pairs
          const latLngPairs: {
            lat: number;
            lng: number;
          }[] = [];
          neighborsMacs.forEach((mac: string) => {
            const node = nodes.find((n) => n.mac === mac);
            if (node && hasThisNodeValidMarkerPos(node)) {
              // hasThisNodeValidMarkerPos is checking that node has markerPos, therefore suppress error:
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              latLngPairs.push(node.markerPos);
            }
          });
          // if neighbors coordinates are found...
          if (latLngPairs.length > 0) {
            // calculate centroid & populate node's markerPos:
            node.markerPos = getCentroidOfLatLngPairs(latLngPairs);
          } else {
            console.warn("No valid coordinates found for neighbors of unplaced but active node: " + node.mac);
            // assign default position, mostly for debug purposes
            // this should be avioded though
            node.markerPos = {
              lat: 1,
              lng: 1,
            };
          }
        } else {
          console.warn("No neighbor found for unplaced but active node: " + node.mac);
          // assign default position, mostly for debug purposes
          // this should be avioded though
          node.markerPos = {
            lat: 1,
            lng: 1,
          };
        }
      }
      return node;
    });
  }

  return { assignEstimatedMarkerPosToUnlocatedButActiveNodes };
}

function getCentroidOfLatLngPairs(
  latLngCollection: {
    lat: number;
    lng: number;
  }[],
): { lat: number; lng: number } {
  if (latLngCollection.length == 0) throw new Error("Not possible to calculate centroid of zero coordinates");

  if (latLngCollection.length == 1) {
    return {
      lat: latLngCollection[0].lat - 10,
      lng: latLngCollection[0].lng - 10,
    };
  }

  return {
    lat:
      latLngCollection
        .map((pair) => pair.lat)
        .reduce((acc, current) => {
          return acc + current;
        }, 0) / latLngCollection.length,
    lng:
      latLngCollection
        .map((pair) => pair.lng)
        .reduce((acc, current) => {
          return acc + current;
        }, 0) / latLngCollection.length,
  };
}

export function isThisNodeUnlocatedButActive(node: DashboardNodeWithMarkerPos): boolean {
  return !!node.unlocated && !!node.sensorData;
}

export function hasThisNodeValidMarkerPos(node: DashboardNodeWithMarkerPos): boolean {
  return !!node.markerPos && (!!node.markerPos.lat || !!node.markerPos.lng);
}
