<template>
  <div class="wearable-details">
    <div class="details-header">
      <add-person-icon v-if="addingNewUser" size="24" iconColor="var(--color-pop-01)" />
      <person-icon v-else size="24" iconColor="var(--color-pop-01)" />
      <div class="title">{{ addingNewUser ? "Create new user" : item.userName }}</div>
      <span v-if="isMobile || isTablet || !addingNewUser" @click="$emit('close')" class="close-icon">
        <close-icon size="24" iconColor="var(--color-brand-01)" />
      </span>
    </div>
    <div class="wearable-details-form">
      <form action="post" @submit.prevent="submitForm">
        <div>
          <SLabel for="userName">USERNAME<sup>*</sup></SLabel>
          <SInput
            id="userName"
            type="text"
            v-model="userName"
            required
            maxlength="30"
            placeholder="Username"
            pattern="^[a-zA-Z0-9\\-\\_\\+\/]{1,30}$"
            title="Has to be between 1 and 30 characters. Only alphanumeric characters, underscores (_), hyphens (-), dots (.), plus signs (+) and slashes (/) are allowed"
          />
        </div>
        <div v-if="addingNewUser">
          <SLabel for="password">PASSWORD<sup>*</sup></SLabel>
          <SInput
            id="password"
            type="password"
            v-model="password"
            required
            minlength="4"
            placeholder="Password"
            autocomplete="new-password"
          />
        </div>
        <div>
          <SLabel for="name">FULL NAME<sup>*</sup></SLabel>
          <SInput id="name" type="text" v-model="name" required maxlength="30" placeholder="Full name" />
        </div>
        <div>
          <SLabel for="email">EMAIL</SLabel>
          <SInput id="email" type="email" v-model="email" placeholder="Email" />
        </div>
        <div>
          <SLabel for="phoneNumber">PHONE</SLabel>
          <SInput
            id="phoneNumber"
            type="tel"
            v-model="phoneNumber"
            placeholder="Phone number"
            pattern="^\+*[0-9\\ ]*$"
          />
        </div>
        <div id="role">
          <SLabel for="roles">ROLES<sup>*</sup></SLabel>
          <s-checkbox
            name="roleCB"
            v-for="role in userRoles"
            :tooltip="rolesDescription[role]"
            :key="role"
            :label="role"
            :style="{ marginTop: '16px' }"
            :checked="roles.includes(role)"
            @input="roles.includes(role) ? roles.splice(roles.indexOf(role), 1) : roles.push(role)"
          />
        </div>
        <div class="form-helper-text"><sup>*</sup>required information</div>
        <div class="buttons">
          <span @click="addingNewUser ? resetNewUserFields() : $emit('close')">
            <normal-button type="button" icon tertiary>
              <cancelIcon size="16" iconColor="var(--color-brand-01)" />
              Cancel
            </normal-button>
          </span>
          <normal-button
            @click.native="beforeFormSubmission"
            type="submit"
            icon
            primary
            :disabled="!isChanged"
          >
            <saveIcon size="16" :iconColor="isChanged ? 'var(--color-pop-01)' : 'var(--color-brand-03)'" />
            {{ addingNewUser ? "Create" : "Save" }}
          </normal-button>
        </div>
      </form>
    </div>
    <section class="button-section">
      <normal-button @click.native.stop="showResetPasswordModal = true" v-if="!addingNewUser">
        Reset password
      </normal-button>
      <normal-button
        v-if="!addingNewUser && !deletingLoggedInUser"
        @click.native.stop="
          $emit('showConfirmModal', {
            title: 'Delete user',
            message: `Are you sure you want to delete user with username: ${item.userName}?`,
            confirmFunction: deleteUser,
          })
        "
      >
        Delete user
      </normal-button>
    </section>
    <div class="wearable-details-info" v-if="newPassword">
      <div class="wearable-info">
        <div class="wearable-info-label">New Password</div>
        <div class="wearable-info-value" v-if="newPassword">
          {{ newPassword }}
        </div>
      </div>
    </div>
    <portal to="modal" v-if="showResetPasswordModal">
      <reset-password-modal @hideModal="showResetPasswordModal = false" :user="item" />
    </portal>
  </div>
</template>

<script>
import PersonIcon from "scanreach-frontend-components/src/components/icons/PersonIcon.vue";
import CloseIcon from "@/components/icons/Close";
import CancelIcon from "scanreach-frontend-components/src/components/icons/CancelIcon.vue";
import SaveIcon from "scanreach-frontend-components/src/components/icons/SaveIcon.vue";
import SInput from "scanreach-frontend-components/src/components/SInput.vue";
import NormalButton from "scanreach-frontend-components/src/components/NormalButton.vue";
import SCheckbox from "scanreach-frontend-components/src/components/SCheckbox.vue";
import ResetPasswordModal from "@/components/desktop/UserAdmin/ResetPasswordModal";
import * as userService from "@/helpers/userService";
/**@typedef {import("@/typedef").User} User */
import AddPersonIcon from "scanreach-frontend-components/src/components/icons/AddPersonIcon.vue";
import useScreenSize from "@/compositions/useScreenSize";
import SLabel from "scanreach-frontend-components/src/components/SLabel.vue";

export default {
  name: "UserDetails",
  components: {
    CloseIcon,
    PersonIcon,
    AddPersonIcon,
    CancelIcon,
    SaveIcon,
    SInput,
    NormalButton,
    ResetPasswordModal,
    SCheckbox,
    SLabel,
  },

  props: {
    /**@type {ApiWearable} */
    item: Object,
  },

  data() {
    const { isMobile, isTablet } = useScreenSize();

    return {
      userName: "",
      name: "",
      email: "",
      phoneNumber: "",
      roles: [],
      userRoles: [],
      newPassword: "",
      password: "",
      showResetPasswordModal: false,
      rolesDescription: {
        Admin: `<center>Has access to everything, </br>including adding </br>and editing other users.</center>`,
        Configurator: `<center>Has only access to the installation </br>and maintainance features, </br>like placing/editing nodes.</center>`,
        Operator: `<center>Has access to the location of </br>the wearables and the tools to handle </br>warning and distress situations.</center>`,
      },
      isMobile,
      isTablet,
    };
  },

  async created() {
    try {
      this.userRoles = await userService.getUserRoles();
    } catch (error) {
      console.error(error);
    }
    this.resetNewUserFields();
  },

  computed: {
    isChanged() {
      if (
        this.userName != this.item?.userName ||
        this.name != this.item?.name ||
        this.email != this.item?.email ||
        this.phoneNumber != this.item?.phoneNumber ||
        this.roles.toString() != this.item?.roles.toString()
      ) {
        return true;
      }
      return false;
    },

    addingNewUser() {
      return !this.item;
    },
    deletingLoggedInUser() {
      return this.userName === this.$session.get("user").userName;
    },
  },

  methods: {
    async submitForm() {
      if (!this.isAtLeastOneCheckboxChecked()) {
        this.setCheckboxesValidity(false);
        return;
      }
      try {
        /**@type {User} */
        const modifiedUser = {
          ...this.item,
          userName: this.userName,
          name: this.name,
          email: this.email,
          phoneNumber: this.phoneNumber,
          roles: this.roles,
        };

        if (this.addingNewUser) {
          // User is new, post it instead of put it
          modifiedUser.password = this.password;
          modifiedUser.id = undefined;
          const newUser = await userService.addUser(modifiedUser);
          this.$emit("newUser", newUser);
          this.$toaster.show({ message: `User ${modifiedUser.userName} successfully created` });
        } else {
          const returnedUser = await userService.updateUserById(modifiedUser);
          this.$emit("updateUser", this.item.id, returnedUser);
          this.$toaster.show({ message: `User successfully updated` });
        }
      } catch (error) {
        const res = JSON.parse(error.message)[0];
        switch (res.code) {
          case userService.UserServiceAPIError.DuplicateUserName:
            res.description = `The username ${this.userName} is already in use. Please, choose a different one.`;
            break;
          case userService.UserServiceAPIError.DuplicateEmail:
            res.description = `The email ${this.email} is already in use. Please, choose a different one.`;
            break;
          case userService.UserServiceAPIError.PasswordTooShort:
            res.description = `The password is too short. Minimum length is 4.`;
            break;
          default:
        }
        this.$toaster.show({ message: res.description, type: "error" });
      }
    },
    beforeFormSubmission() {
      this.setCheckboxesValidity(true); // allows checkboxes to be re-evaluated each time the confirm button is pressed
    },
    async deleteUser() {
      try {
        await userService.deleteUser(this.item.id);
        this.$toaster.show({ message: "User successfully deleted" });
        this.$emit("updateUser", this.item.id, null);
      } catch (error) {
        this.$toaster.show({ message: "Error: " + error, type: "error" });
      }
    },
    resetNewUserFields() {
      this.userName = this.item?.userName;
      this.name = this.item?.name;
      this.password = this.item?.password;
      this.email = this.item?.email;
      this.phoneNumber = this.item?.phoneNumber;
      this.roles = this.item ? Array.from(this.item?.roles) : [];
      this.setCheckboxesValidity(true);
    },
    isAtLeastOneCheckboxChecked() {
      return this.roles.length >= 1;
    },
    setCheckboxesValidity(boolean) {
      if (boolean) {
        const cbs = document.querySelectorAll("label[name='roleCB'] input[type='checkbox']"); // This is successfully referencing SCheckbox rendered template
        cbs.forEach((cb) => {
          cb.setCustomValidity("");
        });
      } else {
        const cbs = document.querySelectorAll("label[name='roleCB'] input[type='checkbox']"); // This is successfully referencing SCheckbox rendered template
        cbs.forEach((cb) => {
          cb.setCustomValidity("Please, select at least one checkbox");
        });
      }
    },
  },

  watch: {
    roles(val) {
      if (val.length >= 1) {
        this.setCheckboxesValidity(true);
      }
    },
    item(val) {
      this.setCheckboxesValidity(true);
      this.userName = val?.userName;
      this.name = val?.name;
      this.password = val?.password;
      this.email = val?.email;
      this.phoneNumber = val?.phoneNumber;
      this.roles = val ? Array.from(val.roles) : [];
    },
  },
};
</script>

<style scoped>
.close-icon {
  cursor: pointer;
}
.close-icon:hover svg * {
  stroke: var(--color-base-brand);
}
.wearable-details {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  font-size: 16px;
  color: var(--color-brand-07);
  font-weight: 700;
  background: var(--color-base-ui);
  line-height: 1;
  overflow-x: hidden;
  overflow-y: auto;
}
.wearable-details-info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  padding: 19px 16px 16px;
}
.wearable-info-label {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: var(--color-pop-03);
  text-transform: uppercase;
}
.wearable-info-value {
  margin-top: 2px;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  color: var(--color-base-white);
}

.button-section {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
  padding: 16px 24px 32px 24px;
}

.wearable-details-form {
  flex: 0 0 0;
  border-bottom: 2px solid var(--color-ui-01);
}

.wearable-details-buttons {
  flex: 0 0 130px;
  margin: auto 0 0 0;
}

.details-header {
  display: flex;
  padding: 19px 16px;
  border-bottom: 2px solid var(--color-ui-01);
}
.title {
  margin-left: 16px;
  margin-right: auto;
  line-height: 24px;
}
.details-header > span {
  width: 24px;
  height: 24px;
}

form {
  padding: 24px 24px 32px 24px;
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
}

form > div > label {
  margin-bottom: 8px;
}

input,
select {
  height: 40px;
  margin-top: 8px;
  margin-bottom: 24px;
  background: #192b31;
  border: 1px solid var(--color-brand-04);
  color: var(--color-base-white);
  padding: 0 8px;
  font-size: 14px;
  letter-spacing: 0.5px;
  outline: none;
  line-height: 1.2;
}

select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%238BA6A6' vector-effect='non-scaling-stroke'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 8px center;
  background-size: 24px 24px;
}
select:hover {
  border-color: var(--color-pop-03);
  cursor: pointer;
}
select:focus {
  border-color: var(--color-pop-01);
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}
.buttons > span {
  display: block;
  margin-right: 16px;
}

input[type="text"],
select#role {
  width: 350px;
}

.manage-buttons {
  height: 132px;
  display: flex;
  border-top: 2px solid var(--color-ui-01);
}

.button-manage {
  width: 100%;
  position: relative;
  background: #1c3138;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px 20px 20px;
  border: none;
  border-right: 2px solid var(--color-ui-01);
  color: var(--color-base-white);
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.button-manage svg {
  position: absolute;
  top: 28px;
  left: 50%;
  display: block;
  transform: translateX(-50%);
}

.button-manage:disabled {
  background-color: var(--color-brand-04);
  cursor: not-allowed;
  color: var(--color-brand-03);
}

.button-manage:disabled svg * {
  stroke: var(--color-brand-03);
}

.button-manage:last-of-type {
  border-right: none;
}

.form-helper-text {
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  color: var(--color-brand-01);
  letter-spacing: 1.5px;
  text-align: end;
  opacity: 0.7;
}
</style>
