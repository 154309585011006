<template>
  <s-modal width="390px" @close="$emit('hideModalAndResetSearch')" :icon="PersonIcon">
    <div slot="heading" :style="{ fontSize: '16px', fontWeight: '700' }">Register a new person</div>
    <div slot="content">
      <form action="post" @submit.prevent="handleSubmit">
        <div>
          <SLabel for="fullName">FULL NAME<sup>*</sup></SLabel>
          <SInput
            id="fullName"
            type="text"
            v-model="fullName"
            required
            :maxlength="fullNameMaxLength"
            placeholder="Full name"
            autofocus
          />
        </div>
        <div>
          <SLabel for="role">ROLE<sup>*</sup></SLabel>
          <VSelect
            id="role"
            :options="personRoles"
            v-model="role"
            placeholder="Select a role"
            label="name"
            :clearable="false"
            append-to-body
            :calculatePosition="VSelectPopperPositioning"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!role" v-bind="attributes" v-on="events" />
            </template>
          </VSelect>
        </div>
        <div>
          <SInput
            id="cabinNr"
            type="text"
            v-model="cabinNr"
            placeholder="Cabin number"
            label="CABIN NUMBER"
          />
        </div>
        <div>
          <SInput id="bunkNr" type="text" v-model="bunkNr" placeholder="Bunk number" label="BUNK NUMBER" />
        </div>
        <div class="form-helper-text"><sup>*</sup>required information</div>
        <div slot="buttons" class="buttons">
          <span @click="$emit('hideModalAndResetSearch')">
            <normal-button type="button" icon tertiary>
              <cancelIcon size="16" iconColor="var(--color-brand-01)" />
              Cancel
            </normal-button>
          </span>
          <normal-button @click.native="handleBeforeSubmission" type="submit" icon primary>
            <saveIcon size="16" iconColor="var(--color-pop-01)" />
            Register
          </normal-button>
        </div>
      </form>
    </div>
  </s-modal>
</template>

<script>
import SModal from "scanreach-frontend-components/src/components/SModal.vue";
import CancelIcon from "scanreach-frontend-components/src/components/icons/CancelIcon.vue";
import SaveIcon from "scanreach-frontend-components/src/components/icons/SaveIcon.vue";
import SInput from "scanreach-frontend-components/src/components/SInput.vue";
import NormalButton from "scanreach-frontend-components/src/components/NormalButton.vue";
import { mapState } from "vuex";
import { postPerson } from "@/helpers/personService";
import VSelect from "vue-select";
import VSelectPopperPositioning from "@/helpers/VSelectPopperPositioning";
import PersonIcon from "scanreach-frontend-components/src/components/icons/PersonIcon.vue";
/**@typedef {import("@/types/personTypes").ApiPerson} ApiPerson*/

export default {
  name: "RegisterNewPersonModal",
  components: { SModal, SInput, NormalButton, SaveIcon, CancelIcon, VSelect },
  props: {
    searchText: String,
  },

  data() {
    return {
      fullNameMaxLength: 30,
      fullName: "",
      role: null,
      cabinNr: "",
      bunkNr: "",
      VSelectPopperPositioning,
      PersonIcon,
    };
  },

  computed: {
    ...mapState({ personRoles: (state) => state.person.personRoles }),
  },
  mounted() {
    this.fullName = this.searchText.slice(0, this.fullNameMaxLength);
    document.getElementById("fullName").focus(); // draws user attention to autosuggested value
  },
  methods: {
    async handleSubmit() {
      try {
        /**@type {ApiPerson} */
        const person = {
          fullName: this.fullName,
          role: this.role,
          cabinNr: this.cabinNr,
          bunkNr: this.bunkNr,
        };
        const newPerson = await postPerson(person);

        this.$store.dispatch("updatePerson", { person: newPerson, persistent: false });
        this.$emit("hideModalAndResetSearch");
        this.$toaster.show({ message: `${newPerson.fullName} was successfully added.` });
        this.$emit("setSelectedItem", newPerson);
      } catch (error) {
        console.error(error);
        this.$toaster.show({
          message: "There was an error adding a new person.",
          type: "warning",
        });
      }
    },
    handleBeforeSubmission() {
      // console.debug("handleBeforeSubmission");
    },
  },
};
</script>

<style scoped>
.select-option {
  display: flex;
  font-size: 14px;
}

form {
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  margin-left: auto;
}

.form-helper-text {
  font-size: 10px;
  font-weight: normal;
  text-transform: uppercase;
  color: var(--color-brand-01);
  letter-spacing: 1.5px;
  text-align: end;
  opacity: 0.7;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}
.buttons > span {
  display: block;
  margin-right: 8px;
}
</style>
