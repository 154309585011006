import { environment } from "../../environments/environment";
import { SignalRSocketHandler } from "@/helpers/signalRSocketHandler";
import customFetch from "@/helpers/customFetch";
import { ref, Ref } from "vue";
import { NodeRelationVisualisations } from "./useNodeRelationshipsVisualization";
import useToaster, { ToastType } from "scanreach-frontend-components/src/compositions/useToaster";

let signalRClient: SignalRSocketHandler;

const initialLoaded = ref(false);
const meshStateSignalR = ref<{ subscribe: () => void; unsubscribe: () => void }>();

const meshState = ref<MeshStateDictionary>({});
/**
 * A reverse tree of meshState where every mac points to its direct children.
 */
const meshChildrenGraph = ref<MeshChildrenGraph>({});
const isRefreshing = ref(false);
/**
 * When we last clicked "Request new OWC state from mesh"
 */
const lastRefreshed: Ref<string | undefined> = ref();

const { pushToast } = useToaster();

export default function () {
  if (!initialLoaded.value) {
    initialLoaded.value = true;
    signalRClient = new SignalRSocketHandler(environment.meshStateSignalRSocket);
    signalRClient.subscribe("statuschange", (status) => {
      if (status.connected) {
        initializeSignalRConnection();
      }
    });

    if (process.env.NODE_ENV != "test") {
      meshStateSignalR.value?.subscribe();
    }
  }

  function updateNodeState1Information(msg: NodeState1Information) {
    const nodeMeshState = getNodeMeshStateForNodeMac(msg.nodeMac);
    nodeMeshState.nodeState1Information = msg;
  }
  function updateNodeState2Information(msg: NodeState2Information) {
    const nodeMeshState = getNodeMeshStateForNodeMac(msg.nodeMac);
    nodeMeshState.nodeState2Information = msg;
  }
  function updateNodeNeighbours(msg: NodeNeighboursMessage) {
    const nodeMeshState = getNodeMeshStateForNodeMac(msg.nodeMac);
    nodeMeshState.nodeNeighbours = msg;
  }
  function updateResetReason(msg: ResetReasonMessage) {
    const nodeMeshState = getNodeMeshStateForNodeMac(msg.nodeMac);
    nodeMeshState.lastResetReason = msg;
  }
  function updateParentAddress(msg: ParentAddressMessage) {
    const nodeMeshState = getNodeMeshStateForNodeMac(msg.nodeMac);
    updateMeshChildrenGraph(
      meshChildrenGraph.value,
      msg.nodeMac,
      msg.parentNodeMac,
      nodeMeshState.parentAddress?.parentNodeMac,
    );
    nodeMeshState.parentAddress = msg;
  }
  function updateAllMeshState(msg: MeshStateDictionary) {
    meshChildrenGraph.value = createMeshChildrenGraph(msg);
    meshState.value = msg;
  }

  function getNodeMeshStateForNodeMac(nodeMac: string): NodeMeshState {
    if (!meshState.value[nodeMac]) {
      meshState.value[nodeMac] = {};
    }
    return meshState.value[nodeMac];
  }

  function initializeSignalRConnection() {
    console.info("Initializing signalR subscriptions for MeshStateSignalR");
    signalRClient.on("ReceiveNodeState1", (msg) => updateNodeState1Information(msg));
    signalRClient.on("ReceiveNodeState2", (msg) => updateNodeState2Information(msg));
    signalRClient.on("ReceiveNodeNeighbours", (msg) => updateNodeNeighbours(msg));
    signalRClient.on("ReceiveResetReason", (msg) => updateResetReason(msg));
    signalRClient.on("ReceiveParentAddress", (msg) => updateParentAddress(msg));
    signalRClient.on("ReceiveAllMeshState", (msg) => updateAllMeshState(msg));

    signalRClient.subscribe("reconnect", () => {
      console.info(
        "SignalR connection to MestStateHub has been lost and restored. Restarting sensor subscriptions",
      );
      subscribe();
    });

    subscribe();
  }

  function subscribe() {
    if (signalRClient.status.connected) {
      signalRClient.invoke("SubscribeToMeshState");
    }
  }

  function unsubscribe() {
    if (signalRClient.status.connected) {
      signalRClient.invoke("UnsubscribeToAllGroups");
    }
  }

  /** Used to force all nodes to send nodeMeshState up to API instead of waiting for intervals to trigger */
  async function requestMeshStateFromAllNodes() {
    isRefreshing.value = true;
    pushToast({
      title: "Requesting mesh state from all nodes",
      type: ToastType.INFO,
      duration: 10_000,
    });
    const resp = await customFetch(`${environment.apiAddress}/NodeNeighbours/SendNodeNeighboursRequest`, {
      method: "GET",
    });
    if (resp.ok) {
      lastRefreshed.value = await resp.json();
    }

    setTimeout(() => {
      isRefreshing.value = false;
      generateUpdateRequestReport();
    }, 60_000);

    if (!resp.ok) {
      throw new Error(await resp.text());
    }
  }

  // FIXME: this report is based exclusively on received neighbors-messages and need to be updated when requesting the "whole state"
  function generateUpdateRequestReport() {
    let totalNodes = 0;
    let updatedNodes = 0;

    Object.values(meshState.value).forEach((nodeMeshState) => {
      totalNodes++;
      if (
        nodeMeshState.nodeNeighbours?.observedTime &&
        lastRefreshed.value &&
        nodeMeshState.nodeNeighbours?.observedTime > lastRefreshed.value
      ) {
        updatedNodes++;
      }
    });

    pushToast({
      title: `${updatedNodes}/${totalNodes} nodes have reported [${(
        (updatedNodes / totalNodes) *
        100
      ).toFixed(0)}%]`,
      type: ToastType.INFO,
      duration: 10_000,
    });
  }

  return {
    getNodeMeshStateForNodeMac,
    requestMeshStateFromAllNodes,
    meshState,
    meshChildrenGraph,
    lastRefreshed,
    isRefreshing,
    subscribe,
    unsubscribe,
  };
}

/**
 * @returns A full reverse graph based on parentAddress from all nodes in MeshState
 */
export function createMeshChildrenGraph(meshState: MeshStateDictionary) {
  const meshChildren: MeshChildrenGraph = {};

  Object.entries(meshState).forEach(([nodeMac, nodeMeshState]) => {
    if (nodeMeshState.parentAddress) {
      updateMeshChildrenGraph(meshChildren, nodeMac, nodeMeshState.parentAddress.parentNodeMac, undefined);
    }
  });
  return meshChildren;
}

/**
 * Updates graph of children in place based on properties from ParentAddressMessage.
 * Needed to more efficiently count number of children of each node
 */
export function updateMeshChildrenGraph(
  childGraph: MeshChildrenGraph,
  nodeMac: string,
  newParentMac: string,
  oldParentMac?: string,
) {
  if (oldParentMac) {
    childGraph[oldParentMac]?.delete(nodeMac);
  }

  const newParentChildren = childGraph[newParentMac] ?? new Set<string>();
  newParentChildren.add(nodeMac);
  childGraph[newParentMac] = newParentChildren;
}

export function generateNodeParentGraph(
  nodesWithMarkerPos: {
    [mac: string]: {
      markerPos?: {
        lat: number;
        lng: number;
      } | null;
      mac: string;
    };
  },
  meshState: MeshStateDictionary,
  selectedNodeMac?: string,
  visualisationMethod?: NodeRelationVisualisations,
): NodeGraphOutput {
  const out: NodeGraphOutput = {
    highlightedNodes: new Set(),
    graph: [],
  };

  for (const nodeMac in meshState) {
    if (Object.prototype.hasOwnProperty.call(meshState, nodeMac)) {
      const parentMac = meshState[nodeMac]?.parentAddress?.parentNodeMac;
      if (parentMac && nodeMac !== parentMac) {
        const thisNode = nodesWithMarkerPos[nodeMac];
        const thisNodeMarkerPos = thisNode?.markerPos;
        const parentNode = nodesWithMarkerPos[parentMac];
        const parentNodeMarkerPos = parentNode?.markerPos;

        if (thisNodeMarkerPos?.lat !== undefined && parentNodeMarkerPos?.lat !== undefined) {
          const isHighlighted = isRelationHighlighted(
            meshState,
            nodeMac,
            parentMac,
            selectedNodeMac,
            visualisationMethod,
          );

          if (isHighlighted) {
            out.highlightedNodes.add(thisNode);
            out.highlightedNodes.add(parentNode);
          }

          out.graph.push({
            color: !selectedNodeMac
              ? "var(--color-pop-02)"
              : isHighlighted
                ? "var(--color-pop-01)"
                : "var(--color-pop-03)",
            opacity: isHighlighted || !selectedNodeMac ? undefined : 0.5,
            dashedArray: isHighlighted ? undefined : "5 5",
            latLngs: [
              [thisNodeMarkerPos.lat, thisNodeMarkerPos.lng],
              [parentNodeMarkerPos.lat, parentNodeMarkerPos.lng],
            ],
          });
        }
      }
    }
  }

  return out;
}

export function isRelationHighlighted(
  meshState: MeshStateDictionary,
  nodeMac: string,
  parentMac?: string,
  selectedNodeMac?: string,
  visualisationMethod?: NodeRelationVisualisations,
): boolean {
  if (!selectedNodeMac || !visualisationMethod) {
    return false;
  }

  if (visualisationMethod == NodeRelationVisualisations.Parents) {
    // Required when testing relation A -> B when A is selectedMac
    const isNodeMacSelectedMac = nodeMac === selectedNodeMac;
    return (
      (isNodeMacSelectedMac || isNodeMacParentOf(meshState, nodeMac, selectedNodeMac)) &&
      (!parentMac || isNodeMacParentOf(meshState, parentMac, selectedNodeMac))
    );
  } else if (visualisationMethod == NodeRelationVisualisations.Children) {
    // Required when testing relation A -> B when A is selectedMac
    const isParentMacSelectedMac = parentMac === selectedNodeMac;
    return (
      isNodeMacParentOf(meshState, selectedNodeMac, nodeMac) &&
      (isParentMacSelectedMac || isNodeMacParentOf(meshState, selectedNodeMac, parentMac))
    );
  }

  return false;
}

export function generateNodeNeighboursGraph(
  nodesWithMarkerPos: {
    [mac: string]: {
      markerPos?: {
        lat: number;
        lng: number;
      } | null;
      mac: string;
    };
  },
  meshState: MeshStateDictionary,
  selectedNodeMac?: string,
): NodeGraphOutput {
  const out: NodeGraphOutput = {
    highlightedNodes: new Set(),
    graph: [],
  };

  if (!selectedNodeMac) {
    return out;
  }

  const thisNode = meshState[selectedNodeMac];

  for (const neighbour of thisNode?.nodeNeighbours?.neighbours ?? []) {
    const thisNode = nodesWithMarkerPos[selectedNodeMac];
    const thisNodeMarkerPos = thisNode?.markerPos;
    const neighbourNode = nodesWithMarkerPos[neighbour.nodeMac];
    const neighbourNodeMarkerPos = neighbourNode?.markerPos;

    if (thisNodeMarkerPos && neighbourNodeMarkerPos) {
      out.graph.push({
        color: "var(--color-pop-01)",
        latLngs: [
          [thisNodeMarkerPos.lat, thisNodeMarkerPos.lng],
          [neighbourNodeMarkerPos.lat, neighbourNodeMarkerPos.lng],
        ],
      });
      out.highlightedNodes.add(thisNode);
      out.highlightedNodes.add(neighbourNode);
    }
  }

  return out;
}

/**
 * @returns true if macA and macB are in the same family upwards or downwards (excluding siblings) or are the same node
 */
export function areNodesRelated(mesh: MeshStateDictionary, macA?: string, macB?: string) {
  if (!macA || !macB) {
    return false;
  }
  if (macA === macB) {
    return true;
  }

  // Then check if A is any of B's parents
  // First check if B is any of A's parents
  return isNodeMacParentOf(mesh, macA, macB) || isNodeMacParentOf(mesh, macB, macA);
}

/**
 * Recursively checks whether 'targetNodeMac' node is any of 'startNodeMac's parents
 * @param mesh
 * @param targetNodeMac node to look for up in the tree
 * @param startNodeMac node where we start looking for up the tree
 * @param visited keeps track of visited nodes to avoid infinite cycle
 */
export function isNodeMacParentOf(
  mesh: MeshStateDictionary,
  targetNodeMac: string,
  startNodeMac?: string,
  visited = new Set(),
): boolean {
  if (startNodeMac === targetNodeMac) {
    return false; // are the same node
  }
  const nextNodeMac: string | undefined =
    startNodeMac && mesh[startNodeMac]?.nodeState1Information?.preferredParentRssi !== 0
      ? mesh[startNodeMac]?.parentAddress?.parentNodeMac
      : undefined;
  if (!nextNodeMac) {
    // No parent info found; maybe root node? exit recursion
    return false;
  }
  if (visited.has(nextNodeMac)) {
    // We have reached a cycle.
    // These nodes are parents to each other and should return true
    // A cycle can occur due to receiving parentAddress asynchronously from nodes.
    return true;
  }
  if (nextNodeMac === targetNodeMac) {
    // parent of startNode corresponds to targeted node; Reached goal
    return true;
  }

  visited.add(nextNodeMac);

  return isNodeMacParentOf(
    mesh,
    targetNodeMac, // always same target to look for up in the tree
    nextNodeMac, // new "start node" (the next up in the tree)
    visited,
  );
}

/**
 * Recursively count number of hops from startNodeMac up to root
 */
export function countHops(
  mesh: MeshStateDictionary,
  startNodeMac: string,
  visited = new Set<string>(),
): number | null {
  const thisNodeMeshState = mesh[startNodeMac];
  // Check if at root:
  if (thisNodeMeshState?.nodeState1Information?.preferredParentRssi === 0) {
    return visited.size;
  }
  const parentNodeMac = thisNodeMeshState?.parentAddress?.parentNodeMac;
  if (!parentNodeMac) {
    // We have most probably not received parentAddress for this node yet.
    return null;
  }

  visited.add(startNodeMac); // Prevents infinite cycle
  if (visited.has(parentNodeMac)) {
    // Cycle detected, break out
    return visited.size;
  }
  return countHops(mesh, parentNodeMac, visited);
}
/**
 * Recursively count number of children to startNodeMac
 * @param startNodeMac The node you would like to count from
 * @param visited Used in recursion to avoid infinite loop. Will be set automatically.
 */
export function countChildren(
  childrenGraph: MeshChildrenGraph,
  startNodeMac: string,
  visited = new Set<string>(),
) {
  let childrenCount = 0;
  visited.add(startNodeMac); // Prevents infinite cycle
  childrenGraph[startNodeMac]?.forEach((childMac) => {
    if (!visited.has(childMac)) {
      childrenCount += 1 + countChildren(childrenGraph, childMac, visited);
    }
  });

  return childrenCount;
}

/**
 * Returns the last time any debug data were updated on a given node mesh state, regardless of the type of data
 *
 * @param {NodeMeshState} [nodeMeshState] The mesh state of the node
 * @returns {string} The latest observed time from the nodeMeshState.
 */
export function getLatestObservedTimeFromMeshState(nodeMeshState?: NodeMeshState) {
  if (!nodeMeshState) {
    return null;
  }

  const times = [
    nodeMeshState.lastResetReason?.observedTime ?? "",
    nodeMeshState.nodeNeighbours?.observedTime ?? "",
    nodeMeshState.nodeState1Information?.observedTime ?? "",
    nodeMeshState.nodeState2Information?.observedTime ?? "",
    nodeMeshState.parentAddress?.observedTime ?? "",
  ];
  return times.sort()[times.length - 1];
}

/**
 * Returns a custom color representing the quality of the RSSI level
 * If the default color is not provided, undefined will be returned.
 * @param {number} rssi - The RSSI value
 * @param {string | undefined} defaultColor - The color representing optimal RSSI
 * @returns A string or undefined to be used as a styling value
 */
export function getColorByRssi(
  rssi: number,
  defaultColor: string | undefined = undefined,
): string | undefined {
  // FIXME: thresholds need to be tested and validated
  if (rssi <= -80) return "var(--color-status-alarm)";
  if (rssi <= -70) return "var(--color-status-warning)";

  return defaultColor;
}

export type MeshChildrenGraph = { [mac: string]: Set<string> };

export type NodeGraphOutput = {
  highlightedNodes: Set<{
    markerPos?: {
      lat: number;
      lng: number;
    } | null;
    mac: string;
  }>;
  graph: NodeGraphLine[];
};

export type NodeGraphLine = {
  color: string;
  latLngs: [number, number][];
  /**
   * If defined: draw a dashed line with length and spacing ex: `3 3`
   */
  dashedArray?: string;
  opacity?: number;
};

export type MeshStateDictionary = { [nodeMac: string]: NodeMeshState };

export type NodeMeshState = {
  nodeState1Information?: NodeState1Information | null;
  nodeState2Information?: NodeState2Information | null;
  nodeNeighbours?: NodeNeighboursMessage | null;
  lastResetReason?: ResetReasonMessage | null;
  parentAddress?: ParentAddressMessage | null;
};

export interface NodeState1Information extends NodeBaseMessage {
  uSBConnected: boolean;
  batteryCharging: boolean;
  temperature: number;
  voltage: number;
  uptime: number;
  preferredParentRssi: number;
  nodeVersion: number;
  bleVersion: number;
  nordicOutOfSeq: number;
  buffersFullCount: number;
  queueFullCount: number;
  lowestNoOfbuffersAvailable: number;
  nordicCrcErrCount: number;
}

export interface NodeState2Information extends NodeBaseMessage {
  udpseqno: number;
  uptime: number;
  nodeversion: number;
  bleversion: number;
  resetattempt: number;
  summarycount: number;
  secsincesummary: number;
  bsBuildVersion: number;
  otaBuildVersion: number;
  rplBuildVersion: number;
}

export interface NodeNeighboursMessage extends NodeBaseMessage {
  neighbours: NodeNeighbour[];
}

export interface NodeNeighbour {
  nodeMac: string;
  rssi: number;
}

export interface ResetReasonMessage extends NodeBaseMessage {
  reason: ResetReasonEnum;
}

export enum ResetReasonEnum {
  BatteryData,
  FwVersionData,
}

export interface ParentAddressMessage extends NodeBaseMessage {
  parentNodeMac: string;
}

export interface NodeBaseMessage {
  nodeMac: string;
  observedTime: string;
}
