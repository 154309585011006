// ============ production settings ==========
const environment = {
  get systemVersion() {
    return window.env && window.env.APP_VERSION ? window.env.APP_VERSION : "next";
  }, // three-number-string indicating the version number
  isSystemAlarmSoundActive: false, // FIXME: please, remove this, and logic related to it when obsolete
  statussocket: window.location.host, // socket.io whereby the mesh-network data comes from
  apiAddress: window.location.origin + "/api",
  genericApiAddress: window.location.origin + "/api/v1",
  signalRSocket: window.location.origin + "/eventhub",
  meshStateSignalRSocket: window.location.origin + "/meshStateHub",
  snapshotSignalRSocket: window.location.origin + "/snapshotHub",
  get appInsightsApiKey() {
    return window.env && window.env.APP_APP_INSIGHTS_KEY
      ? window.env.APP_APP_INSIGHTS_KEY
      : "ecb00f07-1456-43c9-b08a-c1c0d4ba017a";
  },
};

// ========= Development settings ===============
if (process.env.NODE_ENV == "development") {
  const baseAddress = "https://inhouse.remote.staging.scanreach.com"; // Staging environment
  // const baseAddress = "https://inhouse.remote.dev.scanreach.com"; // Dev environment
  // const baseAddress = "https://inhouse.remote.scanreach.com"; // Prod environment

  environment.apiAddress = baseAddress + "/api";
  environment.genericApiAddress = baseAddress + "/api/v1";
  environment.signalRSocket = baseAddress + "/eventhub";
  environment.meshStateSignalRSocket = baseAddress + "/meshStateHub";
  environment.snapshotSignalRSocket = baseAddress + "/snapshotHub";
}
export { environment };

declare global {
  interface Window {
    env:
      | {
          APP_BUILD?: string;
          APP_BUILD_DATE?: string;
          APP_VERSION?: string;
          APP_APP_INSIGHTS_KEY?: string;
        }
      | undefined;
  }
}
