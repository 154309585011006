import { point, LatLngBounds, LatLng, Map } from "leaflet";

/**
 * Calculates the actual tight bounds for the GA image displayed
 */
export function getImageBounds(mapObject: Map, imageDim: { width: number; height: number }) {
  const southWestImage = mapObject.unproject(point([0, imageDim.height]), mapObject.getMaxZoom());
  const northEastImage = mapObject.unproject(point([imageDim.width, 0]), mapObject.getMaxZoom());

  return new LatLngBounds(southWestImage, northEastImage);
}

/**
 * Calculates maxBounds based on imageBounds plus a padding on each side
 * @param isTablet - if the device is a tablet - will place center on bottom half of screen
 */
export function getMaxBounds(imageBounds: LatLngBounds, isTablet = false) {
  const imgSW = imageBounds.getSouthWest();
  const imgNE = imageBounds.getNorthEast();

  let southWest: LatLng;
  let northEast: LatLng;
  if (isTablet) {
    southWest = new LatLng(-imgSW.lat * 0.7, -imgNE.lng * 0.3);
    northEast = new LatLng(imgSW.lat * 1, imgNE.lng * 1);
  } else {
    southWest = new LatLng(-imgSW.lat * 0.1, -imgNE.lng * 0.1);
    // Doubling the padding on right side as we overlay stuff on right side
    northEast = new LatLng(imgSW.lat * 1.1, imgNE.lng * 1.5);
  }

  return new LatLngBounds(southWest, northEast);
}
