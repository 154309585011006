var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('s-modal',{attrs:{"width":"390px","icon":_vm.WearableIcon},on:{"close":function($event){return _vm.$emit('hideModal')}},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v(" "+_vm._s(_vm.modalTitle)+" ")]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"modal-content"},[(
          _vm.selectedItems &&
          _vm.selectedItems.length === 1 &&
          _vm.selectedPeople &&
          _vm.selectedPeople.length > 0 &&
          _vm.selectedPeople[0] &&
          _vm.selectedPeople[0].wearable
        )?_c('div',{staticClass:"mac-container"},[_c('wearable-icon',{attrs:{"size":"24","iconColor":"var(--color-pop-03)"}}),_c('div',{staticClass:"mac-label"},[_vm._v(_vm._s(_vm.selectedPeople[0].wearable.mac))]),_c('battery-with-percentage',{attrs:{"batteryPercentage":_vm.potentialBatteryPercentage}})],1):_vm._e(),_c('div',{staticClass:"list-header"},[_c('div',{staticClass:"header-name"},[_vm._v("Full name")]),_c('div',[_vm._v("Role")]),(_vm.modalType === _vm.ManageWearableModalTypes.UNASSIGN_WEARABLE)?_c('div',[_vm._v("Wearable")]):_vm._e()]),_c('ul',{staticClass:"disembark-list"},_vm._l((_vm.selectedPeople),function(person){return _c('li',{key:person.id,staticClass:"disembark-item"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(person.fullName))]),_c('p',{class:_vm.modalType === _vm.ManageWearableModalTypes.UNASSIGN_WEARABLE ? null : 'accented'},[_vm._v(" "+_vm._s(person.role ? person.role.name : "")+" ")]),(_vm.modalType === _vm.ManageWearableModalTypes.UNASSIGN_WEARABLE)?_c('p',{staticClass:"accented"},[_vm._v(" "+_vm._s(_vm.getShortMac(person.wearable.mac))+" ")]):_vm._e()])}),0),(
          (_vm.modalType === _vm.ManageWearableModalTypes.ASSIGN_WEARABLE && !_vm.selectedPeople[0].wearable) ||
          _vm.modalType === _vm.ManageWearableModalTypes.REPLACE_WEARABLE
        )?_c('section',[_c('SLabel',{staticStyle:{"margin-bottom":"8px"},attrs:{"for":""}},[_vm._v("Choose a wearable")]),_c('v-select',{attrs:{"placeholder":"Type to search for a wearable","options":_vm.availableWearables,"label":"mac","append-to-body":"","calculatePosition":_vm.VSelectPopperPositioning},on:{"input":(w) => (_vm.newWearable = w),"search":(s) => (_vm.searchText = s)},scopedSlots:_vm._u([{key:"no-options",fn:function({ search }){return [(search)?_c('p',[_vm._v("No matching wearables found")]):_c('p',[_vm._v("No wearables to choose from")])]}},{key:"option",fn:function(wearable){return [_c('div',{staticClass:"select-option"},[_c('span',{staticClass:"select-option-left-section"},[_c('p',{staticClass:"mac",domProps:{"innerHTML":_vm._s(_vm.getHighlightedText(_vm.getShortMac(wearable.mac), _vm.searchText))}}),(wearable.unresolvedIssues.length > 0)?_c('IssueCircle',{attrs:{"issues":wearable.unresolvedIssues,"size":"12px"}}):_vm._e(),(wearable.isAssignedToPersonText)?_c('PersonIcon',{attrs:{"size":"12px","iconColor":"var(--color-pop-01)"}}):_vm._e()],1),(wearable.sensorData && wearable.sensorData.batteryPercentage != null)?_c('battery-with-percentage',{attrs:{"batteryPercentage":wearable.sensorData.batteryPercentage}}):_vm._e()],1)]}}],null,false,2968206025)}),(_vm.newWearable && _vm.newWearable.isAssignedToPersonText)?_c('InfoBox',{attrs:{"showWarning":true},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',{domProps:{"innerHTML":_vm._s(_vm.newWearable.isAssignedToPersonText)}})]},proxy:true}],null,false,3772704274)}):_vm._e()],1):_vm._e(),(
          _vm.modalType === _vm.ManageWearableModalTypes.ASSIGN_WEARABLE &&
          !_vm.selectedPeople[0].wearable &&
          !_vm.isExternalSystemResponsibleForEmbarking
        )?_c('toggle-setting',{attrs:{"text":"Embark this person as the wearable is assigned","isActive":_vm.autoEmbark},on:{"update:isActive":(val) => (_vm.autoEmbark = val)}}):_vm._e()],1)]},proxy:true},{key:"buttons",fn:function(){return [_c('NormalButton',{attrs:{"tertiary":"","icon":"","formnovalidate":"","type":"button"},nativeOn:{"click":function($event){return _vm.$emit('hideModal')}}},[_c('cancelIcon',{attrs:{"size":"16","iconColor":"var(--color-brand-01"}}),_vm._v(" CANCEL ")],1),_c('NormalButton',{attrs:{"disabled":_vm.isConfirmButtonDisabled,"icon":""},nativeOn:{"click":function($event){return _vm.handleConfirm.apply(null, arguments)}}},[_c('saveIcon',{attrs:{"size":"16","iconColor":_vm.isConfirmButtonDisabled ? 'var(--color-brand-03)' : 'var(--color-pop-01)'}}),_vm._v(" CONFIRM ")],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }