<template>
  <div class="search-container">
    <input
      type="text"
      name="wearable-search"
      id="wearable-search"
      :placeholder="searchTextPlaceholder"
      autocomplete="off"
      :value="searchText"
      @input="setSearchText"
      @focus="$emit('searchFocus', true)"
      @keyup.esc="clearSearch"
    />
    <search-icon v-if="searchText.length === 0" size="16" iconColor="var(--color-pop-01)" />
    <div v-else class="clear" @click="clearSearch">
      <close-icon size="16" iconColor="var(--color-pop-01)" />
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import SearchIcon from "scanreach-frontend-components/src/components/icons/SearchIcon.vue";
import CloseIcon from "scanreach-frontend-components/src/components/icons/CloseIcon.vue";

/* FIXME: merge with SSearch.vue */
export default {
  components: {
    SearchIcon,
    CloseIcon,
  },
  emits: {
    setSearchText: (val) => true,
  },
  props: {
    searchText: String,
    searchTextPlaceholder: {
      type: String,
      default: "Search",
    },
  },

  methods: {
    clearSearch() {
      this.$emit("setSearchText", "");
    },

    setSearchText: debounce(function (val) {
      this.$emit("setSearchText", val.target.value);
    }, 100),
  },
};
</script>

<style scoped>
.search-container {
  width: 100%;
}
.search-container > input {
  width: 100%;
  height: 32px;
  background: #192b31;
  border-radius: 20px;
  border: 1px solid #2291a0;
  color: var(--color-base-white);
  padding: 0 40px 0 16px;
  font-size: 12px;
}

.search-container > input::placeholder {
  color: var(--color-brand-01);
  font-style: italic;
  font-size: 12px;
  letter-spacing: 0.5px;
  opacity: 1;
}

.search-container svg {
  position: absolute;
  top: 8px;
  right: 12px;
  display: block;
}

.search-container .clear svg {
  cursor: pointer;
  transition: all 0.2 ease-in-out;
}
.search-container .clear:hover svg {
  transform: scale(1.1);
}
</style>
