import { isLoggedIn } from "@/helpers/authService";

export default {
  data() {
    return {
      connected: false,
    };
  },
  async created() {
    window.triggerShowNotAuthorizedToaster = () => {
      this.$toaster.show({ message: "Error: You are not authorized to perform this action.", type: "error" });
    };
    window.destroySessionAndGoToLogin = this.destroySessionAndRedirectToLogin;
    this.checkIfLoggedInOrGoToLogin();
    setInterval(
      () => {
        this.checkIfLoggedInOrGoToLogin();
      },
      1000 * 60 * 30,
    );
  },
  methods: {
    async checkIfLoggedInOrGoToLogin() {
      try {
        await isLoggedIn();
        this.connected = true;
      } catch (error) {
        if (error.message == "403") {
          this.destroySessionAndRedirectToLogin();
        } else {
          // there is some other error
          this.connected = false;
        }
      }
    },
    destroySessionAndRedirectToLogin() {
      this.$session.destroy();
      if (this.$route.name != "authentication") {
        if (this.$toaster) {
          this.$toaster.show({
            message: "Your authentication has expired. Redirecting to login.",
            type: "error",
          });
        }
      }

      this.$router.push({ name: "authentication", params: { message: "You have been logged out" } });
    },
  },
};
