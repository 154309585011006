<template>
  <s-modal width="560px" @close="$emit('hideChangePasswordModal')" :icon="PersonIcon">
    <template #heading>Change password</template>
    <template #content>
      <div class="modal-content">
        <form ref="form" action="post" @submit.prevent="submitForm">
          <SInput
            id="current-pwd"
            type="password"
            label="CURRENT PASSWORD"
            v-model="currentPassword"
            required
          />

          <SInput
            id="new-pwd"
            type="password"
            minlength="4"
            label="NEW PASSWORD"
            v-model="newPassword"
            required
          />

          <SInput
            id="confirm-new-pwd"
            type="password"
            minlength="4"
            label="CONFIRM NEW PASSWORD"
            v-model="confirmNewPassword"
            required
          />

          <div class="validation-feedback">{{ validationFeedback }}</div>
          <div class="buttons">
            <NormalButton
              tertiary
              icon
              formnovalidate
              @click.native="$emit('hideChangePasswordModal')"
              type="button"
            >
              <cancelIcon size="16" iconColor="var(--color-brand-01)" />
              CANCEL
            </NormalButton>
            <NormalButton type="submit" icon value="submit">
              <saveIcon size="16" iconColor="var(--color-pop-01)" />
              CONFIRM
            </NormalButton>
          </div>
        </form>
      </div>
    </template>
  </s-modal>
</template>

<script>
import CancelIcon from "scanreach-frontend-components/src/components/icons/CancelIcon.vue";
import SModal from "scanreach-frontend-components/src/components/SModal.vue";
import NormalButton from "scanreach-frontend-components/src/components/NormalButton.vue";
import SaveIcon from "scanreach-frontend-components/src/components/icons/SaveIcon.vue";
import SInput from "scanreach-frontend-components/src/components/SInput.vue";
import { changePassword } from "@/helpers/authService";
import PersonIcon from "scanreach-frontend-components/src/components/icons/PersonIcon.vue";

export default {
  components: {
    CancelIcon,
    SModal,
    SaveIcon,
    NormalButton,
    SInput,
  },

  data() {
    return {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      validationFeedback: "",
      PersonIcon,
    };
  },

  methods: {
    async submitForm() {
      if (this.newPassword === this.currentPassword) {
        this.validationFeedback = "The new password cannot be the same as the current password";
      } else if (this.newPassword === this.confirmNewPassword) {
        try {
          await changePassword({
            oldPassword: this.currentPassword,
            newPassword: this.newPassword,
          });
          this.cleanUpForm();
          this.$emit("hideChangePasswordModal");
          this.$toaster.show({ message: `Password successfully modified` });
        } catch (error) {
          this.validationFeedback = error.message;
        }
      } else {
        this.validationFeedback = "The new password is not correctly confirmed";
      }
    },
    cleanUpForm() {
      this.validationFeedback = "";
      this.currentPassword = "";
      this.newPassword = "";
      this.confirmNewPassword = "";
    },
  },
};
</script>

<style scoped>
.sinput {
  margin-bottom: 24px;
}

input {
  height: 40px;
  margin-top: 8px;
  margin-bottom: 24px;
  font-size: 14px;
  letter-spacing: 0.5px;
  background: #192b31;
  border: 1px solid #29373c;
  color: var(--color-base-white);
  padding: 0 8px;
}

.buttons {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  gap: 8px;
  padding: 0;
}

input[type="password"]:hover {
  border-color: var(--color-pop-03);
}

input[type="password"]:focus {
  border-color: var(--color-pop-01);
}

input[type="password"] {
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; /* added for backwards compability since dvh is not supported everywhere */
  height: 100dvh;
  background: var(--color-ui-01);
  opacity: 0.7;
  z-index: 400;
  pointer-events: none;
}

.validation-feedback {
  margin-top: 10px;
  font-size: 14px;
  color: indianred;
  font-family: "DM Sans";
  font-style: normal;
}
</style>
