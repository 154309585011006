<template>
  <svg
    :width="$props.size"
    :height="$props.size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5,13.31V5.81A.81.81,0,0,0,23.69,5H3.31a.81.81,0,0,0-.81.81V26.19a.81.81,0,0,0,.81.81H23.69a.81.81,0,0,0,.81-.81V25"
      :stroke="$props.iconColor"
      vector-effect="non-scaling-stroke"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <line
      x1="24.5"
      y1="10.3"
      x2="2.5"
      y2="10.3"
      :stroke="$props.iconColor"
      vector-effect="non-scaling-stroke"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <path
      d="M27.15,16.31l2.74,2.74a.13.13,0,0,1,0,.18L27.15,22"
      :stroke="$props.iconColor"
      vector-effect="non-scaling-stroke"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <line
      x1="29.44"
      y1="19.14"
      x2="20.5"
      y2="19.14"
      :stroke="$props.iconColor"
      vector-effect="non-scaling-stroke"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "32",
    },
    iconColor: { type: String, default: "var(--color-base-white)" },
  },
};
</script>
