<template functional>
  <svg
    :width="props.size"
    :height="props.size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.91,29H7a5.5,5.5,0,0,1-5.5-5.5h0A5.5,5.5,0,0,1,7,18h3.91"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <path
      d="M21.09,18H25a5.5,5.5,0,0,1,5.5,5.5h0A5.5,5.5,0,0,1,25,29H21.09"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <path
      d="M5.75,7.25a14.49,14.49,0,0,1,20.5,0"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <path
      d="M10,11.49a8.5,8.5,0,0,1,12,0"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <circle
      cx="16"
      cy="23.5"
      r="4"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <circle
      cx="16"
      cy="23.5"
      r="0.5"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <line
      x1="16"
      y1="18.5"
      x2="16"
      y2="19.5"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <line
      x1="12"
      y1="23.5"
      x2="11"
      y2="23.5"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <line
      x1="12.46"
      y1="19.96"
      x2="13"
      y2="20.5"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <line
      x1="19.54"
      y1="19.96"
      x2="19"
      y2="20.5"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <line
      x1="16"
      y1="27.5"
      x2="16"
      y2="28.5"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <line
      x1="21"
      y1="23.5"
      x2="20"
      y2="23.5"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <line
      x1="19"
      y1="26.5"
      x2="19.54"
      y2="27.04"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
    <line
      x1="13"
      y1="26.5"
      x2="12.46"
      y2="27.04"
      fill="none"
      vector-effect="non-scaling-stroke"
      :stroke="props.iconColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: String,
    iconColor: String,
  },
};
</script>

<style></style>
