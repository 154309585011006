<template>
  <div
    class="system-status-badge"
    :style="{ 'background-color': systemStatusObj.color }"
    @click="$router.push('/status')"
  >
    <component :is="systemStatusObj.icon" size="16" iconColor="var(--color-ui-01)" />
    <p>
      <span class="only-desktop">{{ systemStatusObj.heading }}</span> {{ systemStatusObj.keyword }}
    </p>
  </div>
</template>

<script>
import SystemStatusMixin from "@/mixins/systemStatus";

export default {
  name: "StatusBadge",
  mixins: [SystemStatusMixin],
};
</script>

<style scoped>
.system-status-badge {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 32px;
  border-radius: 16px;
  background-color: var(--color-pop-01);
  cursor: pointer;
  transition: all 0.4s var(--cubic-pop);
  user-select: none;
}

.system-status-badge > svg {
  margin-left: 12px;
}

.system-status-badge > p {
  margin-left: 12px;
  margin-right: 12px;
  font-size: 10px;
  line-height: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--color-ui-01);
  white-space: nowrap;
  text-transform: uppercase;
}

.system-status-badge.active {
  background-color: var(--color-status-alarm);
}
.system-status-badge.active > p {
  color: var(--color-base-white);
}
.system-status-badge.active > svg * {
  stroke: var(--color-base-white);
}

@media (max-width: 900px) {
  .only-desktop {
    display: none;
  }
}
</style>
