var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('l-marker',{ref:"markerRef",attrs:{"lat-lng":_vm.node.markerPos,"draggable":_vm.isNodeBeingPlaced,"options":{
    zIndexOffset: _vm.isSelectedNode ? 450 : !_vm.isDimmed ? 442 : 400,
  }},on:{"click":function($event){return _vm.$emit('nodeClick', _vm.node)},"update:lat-lng":(latLng) => _vm.$emit('updateNodeXY', latLng),"ready":function($event){_vm.isMarkerReady = true}}},[_c('l-icon',{attrs:{"icon-anchor":_vm.iconAnchor}},[_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          (!_vm.unlocated || (_vm.isThisNodeUnlocatedButActive(_vm.node) && _vm.hasThisNodeValidMarkerPos(_vm.node))) &&
          !_vm.isNodeBeingPlaced
        ),expression:"\n          (!unlocated || (isThisNodeUnlocatedButActive(node) && hasThisNodeValidMarkerPos(node))) &&\n          !isNodeBeingPlaced\n        "}],staticClass:"node-circle",class:{
          'node-large': _vm.nodeIcon,
          'node-selected': _vm.isSelectedNode,
          dimmed: _vm.isDimmed && !_vm.isSelectedNode,
          scaled: !_vm.nodeIcon,
          updating: _vm.isNodeUpdating,
        },style:({
          'background-color': _vm.backgroundColor,
        })},[(_vm.nodeIcon)?_c(_vm.nodeIcon,{tag:"component",attrs:{"size":"16","iconColor":"var(--color-base-ui)"}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isNodeBeingPlaced),expression:"isNodeBeingPlaced"}],staticClass:"map-node-is-placing"},[_c('span',{staticClass:"center-point"}),_c('div',{staticClass:"handlebar"})]),(_vm.isRelationshipsVisualizationEnabled && _vm.rssiAsNeighbour)?_c('RssiPill',{style:({
          position: 'absolute',
          top: _vm.nodeIcon ? '32px' : '16px',
          left: _vm.nodeIcon ? '32px' : '16px',
        }),attrs:{"rssi":_vm.rssiAsNeighbour}}):_vm._e()],1)]),_c('LTooltip',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNodeBeingPlaced && _vm.isCtrlKeyPressed),expression:"!isNodeBeingPlaced && isCtrlKeyPressed"}],staticClass:"hidden-on-touch-screen",attrs:{"options":{
      direction: 'auto',
      opacity: 1,
      className: 'mesh-data-tooltip',
      offset: [16, 0],
    }}},[_c('MapNodeTooltip',{attrs:{"node":_vm.node}})],1),_c('LPopup',{attrs:{"options":{
      className: 'node-relationship-selector',
      closeButton: false,
      autoClose: false,
      closeOnClick: false,
    }}},[_c('SRadio',{attrs:{"small":"","options":_vm.relationshipOptions,"modelValue":_vm.selectedRelationship},on:{"update:modelValue":(opt) => (_vm.selectedRelationship = opt)}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }