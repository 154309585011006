import { render, staticRenderFns } from "./Warning.vue?vue&type=template&id=77c2dcd7&functional=true&"
import script from "./Warning.vue?vue&type=script&lang=js&"
export * from "./Warning.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports