import { isIssueOnActivePersonOrNode } from "@/helpers/hardwareHelpers";
import * as issueService from "@/helpers/issueService";
import Vue from "vue";
import signalRSocketHandler from "@/helpers/signalRSocketHandler";
import { DashboardPerson, ExternalSystemVendor } from "@/types/personTypes";
import { sortIssuesByProp } from "@/helpers/utils";
import { SortDir } from "@/compositions/useSort";
import { Issue, IssueType, Severity } from "scanreach-frontend-components/src/types/Issue.type";
import { DashboardWearable, DashboardNode } from "./desktopStore";
import { DashboardIssue } from "@/typedef";

type IssueState = {
  issues: { [id: string]: DashboardIssue };
};
const initialState: IssueState = {
  issues: {},
};

interface Getters {
  issues: Issue[];
  distressAlarms: Issue[];
  visibleIssues: Issue[];
  getIssueById: (issueId: string) => Issue;
  getIssuesByType: (issueTypes: IssueType[]) => Issue[];
  getUnresolvedIssuesOnWearable: (wearableMac: string) => Issue[];
  getUnresolvedIssuesOnNode: (nodeMac: string, filteredIssueTypes: Set<IssueType>) => Issue[];
  getMostSeriousCrewIssue: (externalVendor?: ExternalSystemVendor) => Issue | undefined;
  getCrewSyncIssues: (externalVendor?: ExternalSystemVendor) => Issue[];
}

interface IssueMutations {
  SET_ISSUES: (state: IssueState, issues: Issue[]) => void;
  UPDATE_ISSUE: (state: IssueState, issue: Issue) => void;
}

interface IssueActions {
  acknowledgeIssueById: ({ dispatch }: { dispatch: any }, issueId: string) => void;
  fetchIssues: ({ commit, rootGetters }: { commit: any; rootGetters: any }) => void;
  subscribeToIssues: ({ commit, rootGetters }: { commit: any; rootGetters: any }) => void;
}

export interface IIssueModule extends IssueState {
  mutations: IssueMutations;
  getters: Getters;
  actions: IssueActions;
}

export const IssueModule = {
  state: () => initialState,
  mutations: {
    SET_ISSUES(state: IssueState, issues: { [id: string]: Issue }) {
      state.issues = issues;
    },
    UPDATE_ISSUE(state: IssueState, issue: Issue) {
      if (issue.resolvedTime) {
        // Issue is resolved, delete it
        Vue.delete(state.issues, issue.id);
      } else {
        Vue.set(state.issues, issue.id, issue);
      }
    },
  },
  getters: {
    issues: (state: IssueState): Issue[] => {
      return Object.values(state.issues);
    },
    distressAlarms: (state: IssueState, getters: Getters): Issue[] => {
      return getters.issues.filter((i) => i.type === IssueType.DISTRESS_ALARM);
    },
    visibleIssues: (state: IssueState): Issue[] => {
      return Object.values(state.issues).filter(
        (i) =>
          (i.isConnectedNodeOrPersonActive != false ||
            i.severity != Severity.SYSTEM_ALERT ||
            // ReportingNodeNotPlacedInMap is currently the only issue with severity SystemAlert
            // where we would like to show it even though node is not placed in map (does not have coordinates)
            i.type == IssueType.REPORTING_NODE_NOT_PLACED_IN_MAP ||
            i.type == IssueType.NODE_WEAK_LINK) &&
          i.type !== IssueType.DISTRESS_ALARM,
      );
    },
    getIssueById:
      (state: IssueState) =>
      (issueId: string): Issue => {
        return state.issues[issueId];
      },
    getIssuesByType:
      (state: IssueState, getters: Getters) =>
      (issueTypes: IssueType[]): Issue[] => {
        const issueTypeSet = new Set(issueTypes);
        const issues = getters.visibleIssues.filter((i) => issueTypeSet.has(i.type));
        return issues;
      },
    /**
     * Get all unresolvedIssues on wearable
     */
    getUnresolvedIssuesOnWearable:
      (state: IssueState, getters: Getters, rootState: any, rootGetters: any) =>
      (wearableMac: string): Issue[] => {
        const unresolvedIssues: Issue[] = [];
        const wearable: DashboardWearable = rootGetters.getWearableByMac(wearableMac);
        if (wearable && wearable.unresolvedIssueIds) {
          wearable.unresolvedIssueIds.forEach((issueId: string) => {
            const event = getters.getIssueById(issueId);
            if (event) {
              unresolvedIssues.push(event);
            }
          });
        }
        return unresolvedIssues;
      },
    /**
     * Get all unresolvedIssues on node
     * @param nodeMac
     * @param filteredIssueTypes - set of issue types to filter out. Defaults to hide OWC issues.
     */
    getUnresolvedIssuesOnNode:
      (state: IssueState, getters: Getters, rootState: any, rootGetters: any) =>
      (
        nodeMac: string,
        filteredIssueTypes = new Set([IssueType.NODE_WEAK_LINK, IssueType.NODE_WEAK_RSSI]),
      ): Issue[] => {
        const unresolvedIssues: Issue[] = [];
        const node: DashboardNode = rootGetters.getNodeByMac(nodeMac);
        if (node && node.unresolvedIssueIds) {
          node.unresolvedIssueIds.forEach((issueId: string) => {
            const issue = getters.getIssueById(issueId);
            if (issue && !filteredIssueTypes.has(issue.type)) {
              unresolvedIssues.push(issue);
            }
          });
        }
        return unresolvedIssues;
      },

    getUnresolvedIssuesOnPerson:
      (state: IssueState, getters: any, rootState: any, rootGetters: any) => (personId: string) => {
        const person: DashboardPerson | null = rootGetters.getPersonById(personId);
        const wearableMac = person?.wearableMac;
        const issues: Issue[] = [];

        // First get all personIssues
        if (person?.unresolvedIssueIds) {
          person.unresolvedIssueIds.forEach((issueId) => {
            const personIssue = getters.getIssueById(issueId);
            if (personIssue) {
              issues.push(personIssue);
            }
          });
        }
        // Then get all issues connected to this person's wearable
        issues.push(...getters.getUnresolvedIssuesOnWearable(wearableMac));

        return issues;
      },

    getCrewSyncIssues: (state: IssueState, getters: Getters) => (externalVendor?: ExternalSystemVendor) => {
      if (externalVendor == ExternalSystemVendor.OCS) {
        return getters.getIssuesByType([IssueType.OCS_SYNC_FAILING, IssueType.OCS_SYNC_FAILING_ALERT]);
      }

      if (externalVendor == ExternalSystemVendor.UNISEA) {
        return getters.getIssuesByType([IssueType.UNISEA_SYNC_FAILING, IssueType.UNISEA_SYNC_FAILING_ALERT]);
      }

      return getters.getIssuesByType([
        IssueType.UNISEA_SYNC_FAILING,
        IssueType.UNISEA_SYNC_FAILING_ALERT,
        IssueType.OCS_SYNC_FAILING,
        IssueType.OCS_SYNC_FAILING_ALERT,
      ]);
    },

    getMostSeriousCrewIssue:
      (state: IssueState, getters: Getters) => (externalVendor?: ExternalSystemVendor) => {
        const crewSyncIssues = getters.getCrewSyncIssues(externalVendor);
        return crewSyncIssues.sort(sortIssuesByProp("severity", SortDir.ASC))[0];
      },
  },
  actions: {
    acknowledgeIssueById: ({ dispatch }: { dispatch: any }, issueId: string) => {
      issueService.acknowledgeIssueById(issueId);
      dispatch("silenceAlarms", null, { root: true });
    },
    fetchIssues: ({ commit, rootGetters }: { commit: any; rootGetters: any }) => {
      issueService
        .fetchIssues()
        .then((issues) => {
          const isseusById: { [id: string]: Issue } = {};
          issues.forEach((issue) => {
            if (issue.type === IssueType.MUSTERING) {
              commit("SET_MUSTERING_ISSUE", issue.resolvedTime === null ? issue : null);
            } else {
              const person = rootGetters.getPersonByWearableMac(issue.wearable?.mac);
              const node = rootGetters.getNodeByMac(issue.node?.mac);

              Vue.set(
                issue,
                "isConnectedNodeOrPersonActive",
                isIssueOnActivePersonOrNode(issue, person, node),
              );
              isseusById[issue.id] = issue;
            }
          });
          commit("SET_ISSUES", isseusById);
          commit("ADD_HARDWARE_UNRESOLVED_ISSUE", isseusById);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    subscribeToIssues: ({ commit, rootGetters }: { commit: any; rootGetters: any }) => {
      signalRSocketHandler.connect();
      signalRSocketHandler.on("ReceiveIssue", (issue: DashboardIssue) => {
        if (issue.type == IssueType.MUSTERING) {
          commit("SET_MUSTERING_ISSUE", issue.resolvedTime === null ? issue : null);
        } else {
          issue.isConnectedNodeOrPersonActive = isIssueOnActivePersonOrNode(
            issue,
            rootGetters.getPersonByWearableMac(issue.wearable?.mac),
            rootGetters.getNodeByMac(issue.node?.mac),
          );
          // Add/update issue in relevant wearable/node
          commit("ADD_HARDWARE_UNRESOLVED_ISSUE", { [issue.id]: issue });

          // Add/Update issue to state
          commit("UPDATE_ISSUE", issue);
        }
      });
    },
  },
};

export default IssueModule;
