import { render, staticRenderFns } from "./ExportLogColumns.vue?vue&type=template&id=380ca606&scoped=true&"
import script from "./ExportLogColumns.vue?vue&type=script&lang=ts&"
export * from "./ExportLogColumns.vue?vue&type=script&lang=ts&"
import style0 from "./ExportLogColumns.vue?vue&type=style&index=0&id=380ca606&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "380ca606",
  null
  
)

export default component.exports