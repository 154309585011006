<template>
  <div class="chart-donut">
    <div class="donut-wrapper">
      <svg height="160" width="160" viewBox="0 0 160 160" class="donut-chart">
        <g v-for="(value, index) in sortedValues" :key="index">
          <circle
            :cx="cx"
            :cy="cy"
            :r="radius"
            :stroke="value.color"
            :stroke-width="strokeWidth"
            :stroke-dasharray="adjustedCircumference"
            :stroke-dashoffset="calculateStrokeDashOffset(value.count, circumference)"
            fill="transparent"
            :transform="returnCircleTransformValue(index)"
          />
        </g>
        <text
          class="donut-text"
          dominant-baseline="middle"
          text-anchor="middle"
          fill="#EEF6F7"
          x="50%"
          y="50%"
          dy="3%"
          font-size="56px"
          font-weight="bold"
          text-align="center"
        >
          {{ dataTotal }}
        </text>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialValues: [Object, Array],
  },
  data() {
    return {
      cx: 80,
      cy: 80,
      radius: 60,
      strokeWidth: 8,
    };
  },
  computed: {
    // adjust the circumference to add small white gaps
    adjustedCircumference() {
      return this.circumference - 0;
    },

    circumference() {
      return 2 * Math.PI * this.radius;
    },

    dataTotal() {
      if (this.sortedValues.length > 0) {
        return this.sortedValues.map((el) => el.count).reduce((acc, val) => acc + val, 0);
      } else return 0;
    },

    chartData() {
      const arr = [];
      let angleOffset = -90;

      this.sortedValues.forEach((dataVal) => {
        const { x, y } = this.calculateTextCoords(dataVal.count, angleOffset);
        // start at -90deg so that the largest segment is perpendicular to top
        const data = {
          degrees: angleOffset,
          textX: x,
          textY: y,
          color: dataVal.color,
        };

        arr.push(data);

        angleOffset = this.dataPercentage(dataVal.count) * 360 + angleOffset;
      });
      return arr;
    },

    sortedValues() {
      const arr = Object.values(this.initialValues);
      return arr.sort((a, b) => b.count - a.count);
    },
  },
  methods: {
    calculateStrokeDashOffset(dataVal, circumference) {
      const strokeDiff = this.dataPercentage(dataVal) * circumference;
      return circumference - strokeDiff;
    },

    calculateTextCoords(dataVal, angleOffset) {
      // t must be radians
      // x(t) = r cos(t) + j
      // y(t) = r sin(t) + j

      const angle = (this.dataPercentage(dataVal) * 360) / 2 + angleOffset;
      const radians = this.degreesToRadians(angle);

      const textCoords = {
        x: this.radius * Math.cos(radians) + this.cx,
        y: this.radius * Math.sin(radians) + this.cy,
      };
      return textCoords;
    },

    degreesToRadians(angle) {
      return angle * (Math.PI / 180);
    },

    dataPercentage(dataVal) {
      if (dataVal === 0 && this.dataTotal === 0) return 0;
      else return dataVal / this.dataTotal;
    },

    returnCircleTransformValue(index) {
      return `rotate(${this.chartData[index].degrees}, ${this.cx}, ${this.cy})`;
    },
  },
};
</script>

<style scoped>
.donut-wrapper {
  position: relative;
  width: 160px;
  height: 160px;
}
.donut-wrapper::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 7px;
  z-index: 1;
  width: 146px;
  height: 146px;
  background: var(--gradient-skeuomorph);
  mix-blend-mode: overlay;
  border-radius: 50%;
}
.donut-wrapper::after {
  content: "";
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 2;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background: var(--gradient-skeuomorph);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 1);
  mix-blend-mode: overlay;
  opacity: 0.2;
}
.donut-chart {
  position: relative;
  z-index: 4;
}
.donut-text {
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.6);
  line-height: 40px;
}
</style>
