<template>
  <button :class="classes" :style="{ width: width || '' }">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    primary: Boolean,
    width: String,
  },

  computed: {
    classes() {
      return {
        primary: this.primary,
      };
    },
  },
};
</script>

<style scoped>
button {
  background: var(--color-ui-02);
  color: var(--color-base-white);
  font-size: 12px;
  font-weight: 700;
  font-family: "Dm Sans", serif;
  height: 40px;
  border: none;
  width: 100%;
}

button:hover {
  cursor: pointer;
}

.primary {
  background: var(--color-brand-05);
}
</style>
