<template>
  <div></div>
</template>

<script>
export default {
  name: "KeyboardEvents",
  props: {
    handlers: {
      type: Object,
      required: true,
    },
  },
  methods: {
    callback(e) {
      const handler = this.handlers[e.which];
      if (typeof handler == "function") handler();
    },
  },
  created() {
    window.addEventListener("keyup", this.callback);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.callback);
  },
};
</script>

<style scoped>
div {
  display: none;
}
</style>
